/* eslint-disable func-names */

import type { ScaleTime } from 'd3-scale';
import { select } from 'd3-selection';

import './timeline.scss';

import {
  BaseItemBaseSelection,
  DivSelection,
  EnterItemSelection,
  ItemDueState,
  SVGGItemBaseSelection,
  SVGGItemSelection,
  SVGSVGSelection,
  TimelineItemGroup,
  type TimelineOptions,
} from './timeline.types';
import { updateGridlines, updateTodayLine } from './timelineDecorations';
import { updateTooltips } from './timelineTooltips';
import { ITEMS_HEIGHT } from './utils';

const ITEMS_COLLAPSED_HEIGHT = 30;
const ITEMS_HEIGHT_LESS = ITEMS_HEIGHT - 36;
const BAR_GAP = 4;
const BAR_MIN_HEIGHT = 5;

export const getUpcomingHeight = (d: TimelineItemGroup, maxLength: number) =>
  ((d.data.length - d.counter[ItemDueState.Decided] - d.counter[ItemDueState.PastDue]) /
    maxLength) *
  ITEMS_HEIGHT_LESS;

export const getDecidedHeight = (d: TimelineItemGroup, maxLength: number) =>
  ((d.data.length - d.counter[ItemDueState.Upcoming] - d.counter[ItemDueState.PastDue]) /
    maxLength) *
  ITEMS_HEIGHT_LESS;

export const getPastDueHeight = (d: TimelineItemGroup, maxLength: number) =>
  ((d.data.length - d.counter[ItemDueState.Decided] - d.counter[ItemDueState.Upcoming]) /
    maxLength) *
  ITEMS_HEIGHT_LESS;

export const getDueStateHeights = (d: TimelineItemGroup, maxLength: number) => {
  let pastDueHeight = getPastDueHeight(d, maxLength);
  let decidedHeight = getDecidedHeight(d, maxLength);
  let upcomingHeight = getUpcomingHeight(d, maxLength);
  const totalHeight = pastDueHeight + decidedHeight + upcomingHeight;
  if (totalHeight < BAR_MIN_HEIGHT) {
    const fraction = BAR_MIN_HEIGHT / totalHeight;
    pastDueHeight *= fraction;
    decidedHeight *= fraction;
    upcomingHeight *= fraction;
  }
  return { decidedHeight, pastDueHeight, upcomingHeight };
};

function renderItems(
  enter: EnterItemSelection,
  _chart: DivSelection,
  timelineXScale: ScaleTime<number, number>,
  _dragAndZoomXScale: ScaleTime<number, number>,
  _brushXScale: ScaleTime<number, number>,
  options: TimelineOptions
) {
  const { itemGroups } = options;
  const itemsHeight = options.items.length ? ITEMS_HEIGHT : 0;

  const maxLength =
    options.maxItemsBar ||
    itemGroups.reduce((a, { data }) => (data.length > a ? data.length : a), 0);
  return enter
    .append('g')
    .attr('class', () => 'itempoint')
    .each(function (d) {
      const startX = timelineXScale(new Date(d.start).getTime());
      const { decidedHeight, pastDueHeight, upcomingHeight } = getDueStateHeights(d, maxLength);
      const node: SVGGItemSelection = select(this);
      node.attr('y', itemsHeight).attr('transform', `translate(${startX}, ${0})`);
      node
        .append('rect')
        .attr('class', 'rect-svg fill-entities-item-pastdue stroke-entities-item-pastdue')
        .attr('y', 0)
        .attr('height', pastDueHeight);
      node
        .append('rect')
        .attr('class', 'rect-svg fill-entities-item-upcoming stroke-entities-item-upcoming')
        .attr('y', pastDueHeight)
        .attr('height', upcomingHeight);
      node
        .append('rect')
        .attr('class', 'rect-svg fill-entities-item-decided stroke-entities-item-decided')
        .attr('y', pastDueHeight + upcomingHeight)
        .attr('height', decidedHeight);
    });
}

export function addItemsContainer(chart: DivSelection, options: TimelineOptions) {
  if (!options.isItems) {
    addItemsCollapsedContainer(chart);
    return;
  }

  const itemsContainerCollapsed: DivSelection = chart.select('#item-container-collapsed');
  if (!itemsContainerCollapsed.empty()) {
    itemsContainerCollapsed.remove();
  }

  let itemsContainer: DivSelection = chart.select('#item-container');
  const itemsHeight = options.height.itemsHeight;

  // Create items container
  if (itemsContainer.empty()) {
    itemsContainer = chart
      .insert('div', '#timeline-x-axis-bottom')
      .attr('id', 'item-container')
      .style('width', '100%')
      .style('height', `${itemsHeight}px`);
  }

  let itemsSvg: SVGSVGSelection = itemsContainer.select('#items-svg');

  if (itemsSvg.empty()) {
    // Create items svg
    itemsSvg = itemsContainer
      .append('svg')
      .attr('id', 'items-svg')
      .attr('width', '100%')
      .attr('height', `${itemsHeight}px`)
      .attr('transform', 'scale(1,-1)');
  }
}

export function updateItems(
  chart: DivSelection,
  timelineXScale: ScaleTime<number, number>,
  dragAndZoomXScale: ScaleTime<number, number>,
  brushXScale: ScaleTime<number, number>,
  options: TimelineOptions
): void {
  const itemsContainer: DivSelection = chart.select('#item-container');
  const itemsSvg: SVGSVGSelection = itemsContainer.select('#items-svg');

  const gridlines = itemsSvg.select('.gridlines');
  if (gridlines.empty()) {
    // Add gridlines group
    itemsSvg.append('g').attr('class', 'gridlines');
  }

  const { itemGroups } = options;

  // Add svg group for each data point in dataset group to svg
  const datapoints: BaseItemBaseSelection = itemsSvg.selectAll('g.itempoint');

  datapoints
    .data(itemGroups, (d) => d.start.toString())
    .join(
      function enter(enter) {
        return renderItems(enter, chart, timelineXScale, dragAndZoomXScale, brushXScale, options);
      },
      function update(update) {
        updateItemsInternal(chart, timelineXScale, options);
        return update;
      },
      function exit(exit) {
        return exit.remove();
      }
    );

  updateGridlines(chart, timelineXScale, options, '#items-svg', options.height.itemsHeight);
  updateTodayLine(chart, timelineXScale, options, '#items-svg', options.height.itemsHeight);
}

export function updateItemsInternal(
  chart: DivSelection,
  timelineXScale: ScaleTime<number, number>,
  options: TimelineOptions
): void {
  const groupId = '#item-container';

  const svgGroups: SVGGItemBaseSelection = chart.select(groupId).selectAll('g.itempoint');

  // Set x,y for each bar
  svgGroups.attr(
    'transform',
    (d) => `translate(${timelineXScale(new Date(d.start).getTime())}, ${0})`
  );

  // Set width for each interval data point
  const intervalNodes: BaseItemBaseSelection = svgGroups.selectAll('rect');
  intervalNodes.attr(
    'width',
    (d: TimelineItemGroup) =>
      timelineXScale(new Date(d.end)) - timelineXScale(new Date(d.start)) - BAR_GAP
  );

  updateGridlines(chart, timelineXScale, options, '#items-svg', options.height.itemsHeight);
  updateTodayLine(chart, timelineXScale, options, '#items-svg', options.height.itemsHeight);
  updateTooltips(svgGroups, options);
}

export function removeItems(chart: DivSelection): void {
  const groupId = '#item-container';
  // Remove svg group for each data point in dataset group to svg
  chart.select(groupId).selectAll('g.itempoint').remove();
}

export function addItemsCollapsedContainer(chart: DivSelection) {
  const itemsContainer: DivSelection = chart.select('#item-container');
  if (!itemsContainer.empty()) {
    itemsContainer.remove();
  }
  const itemsContainerCollapsed: DivSelection = chart.select('#item-container-collapsed');
  if (itemsContainerCollapsed.empty()) {
    chart
      .insert('div', '#timeline-x-axis-bottom')
      .attr('id', 'item-container-collapsed')
      .style('width', '100%')
      .style('height', `${ITEMS_COLLAPSED_HEIGHT}px`);
  }
}
