import { useQuery } from '@apollo/client';

import {
  ProgramEligibleProjectsQuery,
  ProgramEligibleProjectsQueryVariables,
} from '../../../../generated/graphql';
import { programEligibleProjectsQuery } from '../../queries';

export const useProgramEligibleProjectsQuery = () =>
  useQuery<ProgramEligibleProjectsQuery, ProgramEligibleProjectsQueryVariables>(
    programEligibleProjectsQuery
  );
