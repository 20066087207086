import { BusinessOutlined, Flag, LocationOnOutlined, Restore } from '@material-ui/icons';

import { UNTITLED_PROJECT } from '../../../constants';
import { ImageDimension } from '../../../generated/graphql';
import { fromNow, parseDate } from '../../../utilities/dates';
import ActiveMilestoneCosts from '../../HomeTab/Search/projects/cells/ActiveMilestoneCosts';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import { Chip } from '../../scales';
import SearchText from '../../Search/SearchText/SearchText';
import Thumbnail from '../../shared-widgets/Thumbnail/Thumbnail';

type Props = {
  project: SearchProject;
  searchTerm: string;
};

export default function ProjectSelectionListEntry({ project, searchTerm }: Props) {
  return (
    <div className="flex items-center p-1 text-type-primary">
      <Thumbnail dimension={ImageDimension._92} thumbnail={project.thumbnail} />
      <div className="flex flex-grow flex-col gap-1 p-1">
        <div className="type-heading2" data-cy="ProjectsListItem-Name" id={project.id}>
          <SearchText searchTerm={searchTerm} text={project.name ?? UNTITLED_PROJECT} />
        </div>
        <div className="flex flex-wrap gap-1">
          <Chip
            icon={<LocationOnOutlined />}
            text={<SearchText searchTerm={searchTerm} text={project.location} />}
          />
          <Chip
            icon={<BusinessOutlined />}
            text={<SearchText searchTerm={searchTerm} text={project.type} />}
          />
          <Chip text={<SearchText searchTerm={searchTerm} text={project.status} />} />
          <Chip icon={<Flag />} text={project.milestoneName} />
        </div>
        {project.updatedAt && (
          <NormalTooltip title={parseDate(project.updatedAt)}>
            <Chip
              icon={<Restore />}
              text={`Last updated ${fromNow(project.updatedAt)}${
                project.updatedBy ? ` by ${project.updatedBy}` : ''
              }`}
            />
          </NormalTooltip>
        )}
      </div>
      <ActiveMilestoneCosts project={project} />
    </div>
  );
}
