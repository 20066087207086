import React from 'react';

import { formatCost } from '../../../utilities/currency';

interface Contingency {
  label: string;
  remainingValue: number;
  totalValue: number;
  contingencyName: string;
}

type Props = {
  type: 'Contingency' | 'Allowance';
};

const ContingenciesAllowanceBarToolTip = ({ type }: Props) => {
  const contingenciesFillerData: Contingency[] = [
    {
      label: 'Contingencies',
      remainingValue: 44,
      totalValue: 70,
      contingencyName: 'Design',
    },
    {
      label: 'Contingencies',
      remainingValue: 8,
      totalValue: 10,
      contingencyName: 'Administration',
    },
    {
      label: 'Contingencies',
      remainingValue: 50,
      totalValue: 50,
      contingencyName: 'Construction',
    },
  ];

  const allowanceFillerData: Contingency[] = [
    {
      label: 'Allowance',
      remainingValue: 8,
      totalValue: 10,
      contingencyName: 'Permits',
    },
    {
      label: 'Allowance',
      remainingValue: 1,
      totalValue: 3,
      contingencyName: 'Administration',
    },
  ];

  const dataToDisplay = type === 'Contingency' ? contingenciesFillerData : allowanceFillerData;

  return (
    <div className="flex flex-col">
      {/* Header */}
      <div className="mb-2 flex justify-between">
        <div className="type-heading3">
          {dataToDisplay.length} {type === 'Contingency' ? 'Contingencies' : 'Allowances'}
        </div>
      </div>
      {/* Table section */}
      <div className="grid grid-cols-[120px_80px_40px] gap-2 text-left" role="rowgroup">
        {dataToDisplay.map((item) => (
          <ContingencyRow
            key={`${item.contingencyName}`}
            contingencyName={item.contingencyName}
            remainingValue={item.remainingValue}
            totalValue={item.totalValue}
          />
        ))}
      </div>
    </div>
  );
};

export default ContingenciesAllowanceBarToolTip;

const calculatePercentage = (remainingValue: number, totalValue: number): number => {
  return Math.round((remainingValue / totalValue) * 100);
};

interface ContingencyRowProps {
  contingencyName: string;
  remainingValue: number;
  totalValue: number;
}

// Component to display a single contingency/allowance row
const ContingencyRow: React.FC<ContingencyRowProps> = ({
  contingencyName,
  remainingValue,
  totalValue,
}) => (
  <>
    <div className="flex items-center type-body2">{contingencyName}</div>
    <div className="text-right type-body2">
      {formatCost(remainingValue * 100000, { short: true })}/
      {formatCost(totalValue * 100000, { short: true })}
    </div>
    <div className="text-right type-body2">{calculatePercentage(remainingValue, totalValue)}%</div>
  </>
);
