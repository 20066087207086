import { ProgramFieldsFragment } from '../../generated/graphql';
import { formatCost } from '../../utilities/currency';
import BuildingCommercial from '../Icons/BuildingCommercial';
import Location from '../Icons/Location';
import Program from '../Icons/Program';
import { Chip } from '../scales';

type Props = {
  program?: ProgramFieldsFragment;
};

export default function ProgramDashboardHeader(props: Props) {
  if (!props.program) return null;

  const { estimate, budget, runningTotal, name, projects, locationDetails, type } = props.program;
  return (
    <div className="flex w-full justify-between gap-2">
      <div className="flex flex-col gap-2">
        <div className="type-heading2">{name}</div>
        <div className="flex gap-2">
          <Chip icon={<Program />} text={`${projects.length} Projects`} />
          <Chip icon={<Location />} text={locationDetails.name} />
          <Chip icon={<BuildingCommercial />} text={type} />
        </div>
      </div>
      <div className="flex flex-col justify-end gap-2">
        <div className="flex justify-between gap-6 type-body2">
          <div>Combined Estimate</div>
          <div className="type-table-number">{formatCost(estimate)}</div>
        </div>
        <div className="flex justify-between gap-6 type-body2">
          <div>Combined Running Total</div>
          <div className="type-table-number">{formatCost(runningTotal)}</div>
        </div>
        <div className="flex justify-between gap-6 text-entities-milestone type-body2">
          <div>Combined Budget</div>
          <div className="type-table-number">{formatCost(budget)}</div>
        </div>
      </div>
    </div>
  );
}
