import { useParams } from 'react-router-dom';
import { isUUID } from 'validator';

export default function IdeasIndexRoute() {
  const { projectId } = useParams();
  if (!projectId || !isUUID(projectId)) {
    throw new Error('Failed to get projectId param');
  }

  return <div className="h-full w-full bg-type-warning" />;
}
