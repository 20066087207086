import { ScaleLinear, ScalePoint, ScaleTime } from 'd3-scale';

type Data = { date: Date; value: number };

export default function TimelineLineRounded(props: {
  data: Data[];
  x: ScaleTime<number, number> | ScalePoint<Date>;
  y: ScaleLinear<number, number>;
  color: string;
  strokeWidth?: number;
  horizontalPadding?: [number, number];
}) {
  if (!props.data?.length) return null;
  const size = props.strokeWidth ?? 1;
  const half = size / 2;
  const x1 = (props.x(props.data[0].date) ?? 0) - (props.horizontalPadding?.[0] ?? 0);
  const x2 = (props.x(props.data[1].date) ?? 0) + (props.horizontalPadding?.[1] ?? 0);
  const y1 = props.y(props.data[0].value);
  const y2 = props.y(props.data[1].value);
  return (
    <>
      <line
        strokeWidth={size}
        style={{ stroke: props.color }}
        x1={x1 + half}
        x2={x2 - half}
        y1={y1}
        y2={y2}
      />
      <circle cx={x1 + half} cy={y1} r={half} style={{ fill: props.color }} />
      <circle cx={x2 - half} cy={y2} r={half} style={{ fill: props.color }} />
    </>
  );
}
