export default function ContingencyLegend() {
  const legendEntry = (icon: JSX.Element, text: string) => (
    <div className="flex items-center pl-3">
      <div className="p-1">{icon}</div>
      <div className="p-1">
        <div className="type-label">{text}</div>
      </div>
    </div>
  );

  const contingenciesImg = <div className="h-1 w-3 self-center bg-entities-contingencies" />;
  const allowancesImg = <div className="h-1 w-3 self-center bg-entities-allowances" />;
  const pImg1 = (
    <svg fill="none" height="13" viewBox="0 0 12 13" width="12" xmlns="http://www.w3.org/2000/svg">
      <rect fill="url(#pattern0_5473_107392)" height="12" width="12" y="0.369141" />
      <defs>
        <pattern
          height="0.233333"
          id="pattern0_5473_107392"
          patternContentUnits="objectBoundingBox"
          width="0.233333"
        >
          <use transform="scale(0.0583333)" xlinkHref="#image0_5473_107392" />
        </pattern>
        <image
          height="4"
          id="image0_5473_107392"
          width="4"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAABKADAAQAAAABAAAABAAAAADFbP4CAAAAEklEQVQIHWNg0Oj/z4ABSBIEAOIsBtldqGFqAAAAAElFTkSuQmCC"
        />
      </defs>
    </svg>
  );
  const pImg2 = (
    <svg fill="none" height="12" viewBox="0 0 12 12" width="12" xmlns="http://www.w3.org/2000/svg">
      <rect fill="url(#pattern0_5473_107395)" height="12" width="12" />
      <defs>
        <pattern
          height="0.233333"
          id="pattern0_5473_107395"
          patternContentUnits="objectBoundingBox"
          width="0.233333"
        >
          <use transform="scale(0.0583333)" xlinkHref="#image0_5473_107395" />
        </pattern>
        <image
          height="4"
          id="image0_5473_107395"
          width="4"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAABKADAAQAAAABAAAABAAAAADFbP4CAAAAEklEQVQIHWNQaznxnwEdkCYIAEa9CcUDD3YFAAAAAElFTkSuQmCC"
        />
      </defs>
    </svg>
  );
  const pendingImg = (
    <div className="flex gap-1">
      {pImg1}
      {pImg2}
    </div>
  );

  return (
    <div className="flex h-7 w-full items-center pt-1">
      {legendEntry(contingenciesImg, `Contingencies`)}
      {legendEntry(allowancesImg, `Allowances`)}
      {legendEntry(pendingImg, `Pending`)}
    </div>
  );
}
