import { FC, ReactNode } from 'react';

import { BusinessOutlined, LocationOnOutlined } from '@material-ui/icons';

import { ItemType, SearchCategory } from '../../../../../generated/graphql';
import { RouteKeys } from '../../../../../routes/paths';
import { HOME_ITEM_NAME } from '../../../../../tagConstants';
import { withStyles } from '../../../../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../../../../utilities/routes/links';
import { categoryLabel } from '../../../../../utilities/string';
import ProjectLink from '../../../../ForecastingRoute/common/ProjectLink';
import { Chip, Tooltip } from '../../../../scales';
import SearchText from '../../../../Search/SearchText/SearchText';
import { SearchItem } from '../../types';

import styles from './ItemCellStyles';

type Props = {
  classes: Classes<typeof styles>;
  item: SearchItem;
  searchText: string;
};

const ItemCell: FC<Props> = ({ classes, item, searchText }) => {
  // the following two functions ensures backwards compatible support for an older representation of search.ItemCategory
  // we can later ignore the case where we are given a string with a '::' separator
  const getCategorization = (category: SearchCategory) =>
    category.name.includes('::') ? category.name.split('::')[0] : category.categorization.name;
  const getCategoryLabel = (category: SearchCategory) =>
    category.name.includes('::')
      ? category.name.split('::')[1]
      : categoryLabel(category.name, category.number);

  return (
    <div className={classes.container}>
      <div className={classes.info}>
        <ItemTypeChip item={item} />
        <ProjectLink
          className={item.project.hasAccess ? classes.link : ''}
          hasAccess={item.project.hasAccess}
          projectName={item.project.name}
          to={generateSharedPath(RouteKeys.PROJECT_ITEMS_ITEM, {
            projectId: item.project.id,
            itemId: item.id,
          })}
        >
          <div className={classes.title} data-item-id={item.id}>
            <span data-cy={HOME_ITEM_NAME}>
              <SearchText searchTerm={searchText} text={item.name} />
            </span>
          </div>
        </ProjectLink>
        <Chips>
          <Chip
            data-cy="project-location"
            icon={<LocationOnOutlined />}
            text={item.project.location}
          />
          {item.project.type && (
            <Chip data-cy="project-type" icon={<BusinessOutlined />} text={item.project.type} />
          )}
          {item.project.projectLeadName && (
            <div>
              <Chip
                icon={
                  <div>
                    <img alt="project lead icon" src="/img/GroupsOutlined.svg" width={16} />
                  </div>
                }
                text={item.project.projectLeadName}
              />
            </div>
          )}
          {item.project.orgNodes.map((node) => (
            <div key={node.id}>
              <Chip
                icon={
                  <div>
                    <img alt="organization icon" src="/img/OrganizationTree.svg" width={12} />
                  </div>
                }
                text={node.name}
              />
            </div>
          ))}
          {item.categories.map((category) => (
            <Tooltip key={category.id} content={getCategorization(category)}>
              <Chip data-cy="item-category" text={getCategoryLabel(category)} />
            </Tooltip>
          ))}
        </Chips>
      </div>
    </div>
  );
};

const ItemTypeChip = withStyles(styles)(
  ({ item, classes }: { item: SearchItem; classes: Classes<typeof styles> }) => {
    return (
      <Chips>
        <Chip
          backgroundColor={item.type !== ItemType.OPTION ? '#B3DAC6' : '#F3E7C9'}
          data-cy="item-type-chip"
          text={item.type !== ItemType.OPTION ? 'ITEM' : 'OPTION'}
        />
        {item.numOptions > 0 && (
          <div className={classes.optionCount}>{item.numOptions} Options</div>
        )}
        {item.parentID && item.parentName ? (
          <ProjectLink
            className={classes.parentItem}
            hasAccess={item.project.hasAccess}
            projectName={item.project.name}
            to={generateSharedPath(RouteKeys.PROJECT_ITEMS_ITEM, {
              projectId: item.project.id,
              itemId: item.parentID,
            })}
          >
            {item.parentName}
          </ProjectLink>
        ) : null}
      </Chips>
    );
  }
);

export default withStyles(styles)(ItemCell);

function Chips(props: { children: ReactNode }) {
  return <div className="mt-auto flex flex-wrap items-center gap-1">{props.children}</div>;
}
