import * as d3 from 'd3';

import { DEFAULT_MARGIN } from '../../../../constants';
import SVGWithDimensions from '../../../Charts/ChartsD3/SVGWithDimensions';
import { useChartDimensions } from '../../../Charts/ChartsD3/useChartDimensions';
import TimelineContainer from '../TimelineContainer';
import TimelineLineRounded from '../TimelineLineRounded';
import TimelinePath from '../TimelinePath';
import TimelineSvg from '../TimelineSvg';

import { getMilestoneIcon } from './icons';
import InsightsMilestoneTip from './InsightsMilestoneTip';
import { InsightsMilestone } from './types';
import { useGmps } from './utils';

export const FormatTime = d3.timeFormat('%m/%y');
const LINE_HEIGHT = 10;

type Props = {
  baseRange: [Date, Date];
  data: InsightsMilestone[];
  height: number;
  hoverDate?: Date | undefined;
  today: Date;
  totalRange: [Date, Date];
};

export default function InsightsBaseLine(props: Props) {
  const { data, height, hoverDate, today, totalRange } = props;
  const { ref, dimensions } = useChartDimensions({
    height,
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
  });
  const { width } = dimensions;
  const margin = DEFAULT_MARGIN;

  const { activeGmp, activeMilestone, comingGmp } = useGmps(data, today);

  // x domain
  const xMin = totalRange[0];
  const xMax = totalRange[1];
  const xDomain = [xMin, xMax];
  const xRange: [number, number] = [margin.left, width - margin.right];
  // create x scale
  const x = d3.scaleTime().domain(xDomain).range(xRange);

  // y domain
  const [yMin, yMax] = [0 - (height / 10) * 100, (height / 90) * 100];
  const yDomain = [yMin, yMax];
  const yRange: [number, number] = [height - margin.bottom, margin.top];
  // create y scale
  const y = d3.scaleLinear().domain(yDomain).range(yRange);

  const baseData = [
    { date: props.baseRange[0], value: 0 },
    { date: props.baseRange[1], value: 0 },
  ];

  const todayData = [
    { date: today, value: yMin },
    { date: today, value: yMax },
  ];

  const bounds = {
    right: x(xMax),
    left: x(xMin),
  };

  return (
    <SVGWithDimensions ref={ref} data-cy="line-chart" dimensions={dimensions}>
      {/* Hover Line */}
      {hoverDate && (
        <TimelinePath<{ value: number }>
          // current hover date
          data={[
            { date: hoverDate, value: 0 },
            { date: hoverDate, value: yMax },
          ]}
          stroke="stroke-chart-axis"
          strokeWidth={1.5}
          x={x}
          y={y}
        />
      )}
      {/* Base */}
      <TimelineLineRounded
        color="var(--colors-entities-phase)"
        data={baseData}
        horizontalPadding={[0, 4]}
        strokeWidth={LINE_HEIGHT}
        x={x}
        y={y}
      />
      {/* Tip icons */}
      {data.map((data) => (
        <TimelineSvg
          key={`${data.date}-${totalRange[0]}-${totalRange[1]}`}
          data={{ date: data.date, value: 0 }}
          icon={getMilestoneIcon(data)}
          iconHeight={8}
          iconWidth={8}
          x={x}
          y={y}
        />
      ))}
      {/* Today */}
      <TimelinePath<{ value: number }>
        data={todayData}
        stroke="stroke-selection-focus-fill"
        strokeWidth={1.5}
        x={x}
        y={y}
      />
      {/* Base start date */}
      <TimelineContainer
        bounds={bounds}
        content={
          <text className="fill-type-muted type-body3">{FormatTime(props.baseRange[0])}</text>
        }
        data={{ date: props.baseRange[0], value: 10 }}
        height={20}
        placement="right"
        width={36}
        x={x}
        y={y}
      />
      {/* Base end date */}
      <TimelineContainer
        bounds={bounds}
        content={
          <text className="fill-type-muted type-body3">{FormatTime(props.baseRange[1])}</text>
        }
        data={{ date: props.baseRange[1], value: 10 }}
        height={20}
        placement="left"
        width={36}
        x={x}
        y={y}
      />
      {/* Tips info */}
      {data
        .filter(({ id }) => activeGmp === id || comingGmp === id || activeMilestone === id)
        .map((data) => (
          <InsightsMilestoneTip
            key={`tip-${data.id}`}
            bounds={bounds}
            data={data}
            today={today}
            x={x}
            y={y}
          />
        ))}
    </SVGWithDimensions>
  );
}
