import { ReactNode } from 'react';

import { Add, Info } from '@material-ui/icons';

import {
  InsightsAlert,
  InsightsTriggerInput,
  TriggerMetricType,
  TriggerOperatorType,
} from '../../../generated/graphql';
import { useDesignPhaseTypes } from '../../Milestone/hooks/useDesignPhaseTypesQuery';
import { BabyButton, Button, Tooltip } from '../../scales';
import {
  useCreateAlert,
  useDeprecateAlert,
  useGetAlerts,
  useToggleAlert,
  useUpdateTrigger,
} from '../CompanyHooks';

import ContingenciesAlert from './Sections/ContingenciesAlert';
import GapAlert from './Sections/GapAlert';
import ItemsAlert from './Sections/ItemsAlert';
import PathToBudgetAlert from './Sections/PathToBudgetAlert';

type Props = {
  companyID: UUID;
};

const filterAlerts = (alerts: InsightsAlert[]) => {
  const gapAlerts: InsightsAlert[] = [];
  const pathToBudgetAlerts: InsightsAlert[] = [];
  const itemAlerts: InsightsAlert[] = [];
  const contingencyAlerts: InsightsAlert[] = [];
  alerts.forEach((alert) => {
    const { triggers } = alert;
    if (triggers.length !== 2) return;
    const phaseTrigger = triggers.find(
      (t) => t.metricType === TriggerMetricType.MILESTONEDESIGNPHASE
    );
    if (phaseTrigger) {
      const gapTrigger = triggers.find((t) => t.metricType === TriggerMetricType.GAP);
      if (gapTrigger) {
        gapAlerts.push({ ...alert, triggers: [gapTrigger, phaseTrigger] });
        return;
      }
      const bestCaseGapTrigger = triggers.find(
        (t) => t.metricType === TriggerMetricType.GAPBESTCASE
      );
      if (bestCaseGapTrigger) {
        pathToBudgetAlerts.push({ ...alert, triggers: [bestCaseGapTrigger, phaseTrigger] });
        return;
      }
      const contingencyTrigger = triggers.find(
        (t) => t.metricType === TriggerMetricType.PROJECTEDCONTINGENCYALLOWANCE
      );
      if (contingencyTrigger) {
        contingencyAlerts.push({ ...alert, triggers: [contingencyTrigger, phaseTrigger] });
      }
      return;
    }
    const itemsTrigger = triggers.find((t) => t.metricType === TriggerMetricType.ITEMSPASTDUE);
    const daysTrigger = triggers.find((t) => t.metricType === TriggerMetricType.DAYSPASTDUE);
    if (itemsTrigger && daysTrigger) {
      itemAlerts.push({ ...alert, triggers: [itemsTrigger, daysTrigger] });
    }
  });
  return { gapAlerts, pathToBudgetAlerts, itemAlerts, contingencyAlerts };
};

const defaultTriggers = {
  gap: [
    // Gap greater than 15% during Programming
    {
      metricType: TriggerMetricType.GAP,
      operator: TriggerOperatorType.GT,
      threshold: 15,
    },
    {
      metricType: TriggerMetricType.MILESTONEDESIGNPHASE,
      operator: TriggerOperatorType.EQ,
      threshold: 0,
    },
  ],
  pathToBudget: [
    // Best-case gap greater than 10% during Programming
    {
      metricType: TriggerMetricType.GAPBESTCASE,
      operator: TriggerOperatorType.GT,
      threshold: 10,
    },
    {
      metricType: TriggerMetricType.MILESTONEDESIGNPHASE,
      operator: TriggerOperatorType.EQ,
      threshold: 0,
    },
  ],
  item: [
    // Greater than 10% of all pending items more than 7 days past due
    {
      metricType: TriggerMetricType.ITEMSPASTDUE,
      operator: TriggerOperatorType.GT,
      threshold: 10,
    },
    {
      metricType: TriggerMetricType.DAYSPASTDUE,
      operator: TriggerOperatorType.GT,
      threshold: 7,
    },
  ],
  contingency: [
    // Any contingency/allowance project below 25% remaining with pending items during Any Milestone Design Phase
    {
      metricType: TriggerMetricType.PROJECTEDCONTINGENCYALLOWANCE,
      operator: TriggerOperatorType.LT,
      threshold: 25,
    },
    {
      metricType: TriggerMetricType.MILESTONEDESIGNPHASE,
      operator: TriggerOperatorType.GTE,
      threshold: 0,
    },
  ],
};

export default function InsightAlertsBody(props: Props) {
  const designPhaseTypes = useDesignPhaseTypes();

  const { data: getAlertsData, updateQuery } = useGetAlerts({
    variables: { companyID: props.companyID },
  });
  const [createAlert] = useCreateAlert();
  const [toggleAlert] = useToggleAlert();
  const [deprecateAlert] = useDeprecateAlert();
  const [updateTrigger] = useUpdateTrigger();

  const { gapAlerts, pathToBudgetAlerts, itemAlerts, contingencyAlerts } = filterAlerts(
    getAlertsData?.getAlerts ?? []
  );

  const addAlert = (triggers: InsightsTriggerInput[]) => {
    createAlert({
      variables: {
        input: {
          triggers,
        },
      },
      update(_, { data }) {
        updateQuery(({ getAlerts: existingAlerts }) => ({
          getAlerts: data?.createAlert.alert
            ? [...existingAlerts, data.createAlert.alert]
            : existingAlerts,
        }));
      },
    });
  };

  const handleDelete = (alertID: UUID) => {
    deprecateAlert({
      variables: { input: { alertID } },
      update(_, { data }) {
        updateQuery(({ getAlerts: existingAlerts }) => ({
          getAlerts: data?.deprecateAlert.alert
            ? existingAlerts.filter((it) => it.id !== data?.deprecateAlert?.alert?.id)
            : existingAlerts,
        }));
      },
    });
  };

  const gapAlertDescription =
    "Gap alerts monitor the difference between a project's Running Total and Budget, and trigger when the Gap exceeds the specified threshold.";
  const pathToBudgetTooltip =
    "Path to Budget alerts monitor the Gap for a project's best case scenario by taking into account deductive pending items.";

  const alertSection = (
    header: string,
    alerts: ReactNode,
    action: ReactNode,
    headerInfo?: ReactNode
  ) => (
    <div className="flex flex-col gap-1">
      <div className="flex items-center">
        <div className="type-body2">{header}</div>
        {headerInfo}
      </div>
      <div className="flex flex-col gap-2">{alerts}</div>
      <div className="mb-2">{action}</div>
    </div>
  );

  const handleToggle = (alertID: UUID) => toggleAlert({ variables: { input: { alertID } } });
  const handleUpdate = (triggerID: UUID, trigger: InsightsTriggerInput) => {
    updateTrigger({ variables: { input: { triggerID, trigger } } });
  };

  return (
    <div className="flex flex-col gap-10 px-6 pb-6">
      {/* Costs */}
      <div className="flex flex-col gap-2">
        <div className="mb-2 flex flex-col gap-1">
          <div className="type-heading3">Costs</div>
          <div className="type-body1">
            Costs alerts monitors the costs on your projects and trigger when the specified
            threshold is exceeded.
          </div>
        </div>

        {/* Gap */}
        {alertSection(
          'Gap',
          gapAlerts.map((alert) => (
            <GapAlert
              key={alert.id}
              alert={alert}
              designPhaseTypes={designPhaseTypes}
              onDelete={handleDelete}
              onToggle={handleToggle}
              onUpdate={handleUpdate}
            />
          )),
          <Button
            label="Add Alert"
            onClick={() => addAlert(defaultTriggers.gap)}
            startIcon={<Add />}
            type="secondary"
          />,
          <Tooltip content={gapAlertDescription}>
            <BabyButton aria-label="Info" icon={<Info color="disabled" />} />
          </Tooltip>
        )}

        {/* Path to Budget */}
        {alertSection(
          'Path to Budget',
          pathToBudgetAlerts.map((alert) => (
            <PathToBudgetAlert
              key={alert.id}
              alert={alert}
              designPhaseTypes={designPhaseTypes}
              onDelete={handleDelete}
              onToggle={handleToggle}
              onUpdate={handleUpdate}
            />
          )),
          <Button
            label="Add Alert"
            onClick={() => addAlert(defaultTriggers.pathToBudget)}
            startIcon={<Add />}
            type="secondary"
          />,
          <Tooltip content={pathToBudgetTooltip}>
            <BabyButton aria-label="Info" icon={<Info color="disabled" />} />
          </Tooltip>
        )}
      </div>

      {/* Items */}
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-1">
          <div className="type-heading3">Items</div>
          <div className="type-body1">
            Items alerts monitor past due items and trigger when the specified threshold of past due
            by more than a specified time is exceeded.
          </div>
        </div>
        {alertSection(
          '',
          itemAlerts.map((alert) => (
            <ItemsAlert
              key={alert.id}
              alert={alert}
              onDelete={handleDelete}
              onToggle={handleToggle}
              onUpdate={handleUpdate}
            />
          )),
          <Button
            label="Add Alert"
            onClick={() => addAlert(defaultTriggers.item)}
            startIcon={<Add />}
            type="secondary"
          />
        )}
      </div>

      {/* Contingencies */}
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-1">
          <div className="type-heading3">Contingencies</div>
          <div className="type-body1">
            Contingencies & Allowances alerts monitor potential draw down and trigger when the
            specified threshold is exceeded.
          </div>
        </div>
        {alertSection(
          '',
          contingencyAlerts.map((alert) => (
            <ContingenciesAlert
              key={alert.id}
              alert={alert}
              designPhaseTypes={designPhaseTypes}
              onDelete={handleDelete}
              onToggle={handleToggle}
              onUpdate={handleUpdate}
            />
          )),
          <Button
            label="Add Alert"
            onClick={() => addAlert(defaultTriggers.contingency)}
            startIcon={<Add />}
            type="secondary"
          />
        )}
      </div>
    </div>
  );
}
