export const IS_ZOOM = false;

export const getProjectsRange = (date: Date, monthCount = 6): [Date, Date] => {
  // Date 6 months before
  const monthsBefore = new Date(date);
  monthsBefore.setMonth(monthsBefore.getMonth() - monthCount);
  // Date 6 months after
  const monthsAfter = new Date(date);
  monthsAfter.setMonth(monthsAfter.getMonth() + monthCount);
  return [monthsBefore, monthsAfter];
};
