import { COMPANY_DASHBOARD } from '../../constants';
import { useUserProfile } from '../../utilities/userProfile';

import InsightsProjectsList from './InsightsProjectsList';

const ExecutiveDashboardV2 = () => {
  const profile = useUserProfile();
  document.title = `${COMPANY_DASHBOARD} ${profile ? ` - ${profile.nickname}` : ''}`;

  return <InsightsProjectsList />;
};

export default ExecutiveDashboardV2;
