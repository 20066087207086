import { FC, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Close, GetApp } from '@material-ui/icons';

import {
  CategorizationEvent,
  categorizationEvent,
} from '../../../analytics/analyticsEventProperties';
import joinAPI from '../../../api/joinAPI';
import { CreateCategorizationMutation } from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { useCreateCompanyCategorization } from '../../CompanyTab/Standards/CompanyCategorizations/hooks';
import { createCategories } from '../../JoinGrid/hooks/categorizationMutation';
import { GridController } from '../../JoinGrid/types';
import ShadedHelpTip from '../../shared-widgets/ShadedHelpTip/ShadedHelpTip';
import { useReplaceCategories, useUpsertCategorizationImportMetadata } from '../hooks/hooks';
import { useCreateCategorization } from '../tableUtils/CreateCategorizationHook';
import useUpdateCategorization from '../tableUtils/UpdateCategorizationHook';

import AddCategoriesDialogStyles from './AddCategoriesDialogStyles';
import { processError } from './ErrorMessage';
// eslint-disable-next-line import/no-cycle
import JoinGridCategoryPanel from './JoinGridCategoryPanel';
import ReplaceCategorizationConfirm from './ReplaceCategorizationConfirm';
import { CategoryMapInput, CreateCategorizationInput } from './types';

const isRegularCell = (value: GridCellValue): value is RegularCell => 'string' in value;

type Props = {
  buttonText: string;
  classes: Classes<typeof AddCategoriesDialogStyles>;
  projectId?: UUID;
  categorization?: CategorizationMetadata;
  categorizationInput?: CreateCategorizationInput;
  onClose: () => void;
  onClickReplace?: () => void;
  onClickBack?: () => void;
  onCreateSuccess?: (c: CreateCategorizationMutation['createCategorization']) => void;
  setCategorizationInput?: (c: CreateCategorizationInput) => void;
  title: string;
  viewOnly?: boolean;
  contentOnly?: boolean;
  onBackButton?: () => void;
  onBackButtonMultilevel?: () => void;
};
// When using the grid, we are performing any updates directly
// on the categorizations themselves, so we don't need intermediary
// form state. One thing that is potentially critical is that right now
// we don't update any of the other state in the application in response
// to changes within this dialog.

// ----------- new changes ------------
// We are moving away from performing updates directly. Instead, categories are now created within the dialog when you press create
// unless you are in the edit mode, in which case the categories are still updated on dialog close.
const JoinGridAddCategoriesDialog: FC<Props> = ({
  buttonText,
  classes,
  categorization,
  categorizationInput,
  onClose,
  onClickReplace,
  onClickBack,
  onCreateSuccess,
  projectId,
  setCategorizationInput,
  title,
  viewOnly,
  contentOnly,
  onBackButton,
  onBackButtonMultilevel,
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const updateCategorization = useUpdateCategorization(
    categorization ? categorization.id : '',
    setErrorMessage,
    (e: ApolloError) => setErrorMessage(processError(e)),
    projectId
  );
  const [viewReplaceConfirmation, setViewReplaceConfirmation] = useState(false);
  const sendAnalytics = useSendAnalytics();

  const permissions = viewOnly
    ? { canView: true, canEdit: false }
    : { canView: true, canEdit: true };

  const [gridController, setGridController] = useState<GridController | undefined>(undefined);
  const [hasSetImportedCats, setHasSetImportedCats] = useState(false);

  const categorizationName = categorization?.name ?? categorizationInput?.name;
  const categorizationDescription = categorization?.description ?? categorizationInput?.description;

  // Retrieves the category data from the grid
  const getCategoryInput = <T,>(constructor: (code: string, description: string) => T) => {
    const categories: T[] = [];
    if (!gridController) return categories;
    gridController.data.lines.forEach((line) => {
      // The types are really out of whack here
      const numberCell = line.cells[0].value;
      const nameCell = line.cells[1].value;
      const code = numberCell && isRegularCell(numberCell) ? numberCell.string : '';
      if (!code) return;
      const description = nameCell && isRegularCell(nameCell) ? nameCell.string : '';
      const category = constructor(code, description);
      categories.push(category);
    });
    return categories;
  };

  const [upsertCategorizationImportMetadata] = useUpsertCategorizationImportMetadata();

  // Create categorization mutations
  const createCompanyCategorization = useCreateCompanyCategorization();
  const [createProjectCategorization] = useCreateCategorization(
    projectId ?? '',
    setErrorMessage,
    (catz) => {
      if (categorizationInput?.metadata) {
        upsertCategorizationImportMetadata({
          categorizationID: catz.id,
          metadata: categorizationInput.metadata,
        });
      }
      createCategories(
        catz.id,
        getCategoryInput<CategoryContentInput>((number, name) => ({ number, name })),
        undefined,
        projectId
      );
      onCreateSuccess?.(catz);
      onClose();
    }
  );

  const [replaceCategories] = useReplaceCategories();

  const onSubmit = () => {
    // For single level categorization and categories creation
    if (!categorization && categorizationName) {
      if (projectId) {
        createProjectCategorization(categorizationName, categorizationDescription);
      } else if (categorizationInput?.companyID && gridController?.data) {
        createCompanyCategorization(
          categorizationInput.companyID,
          categorizationName,
          categorizationDescription,
          (categorization) => {
            if (categorizationInput?.metadata) {
              upsertCategorizationImportMetadata({
                categorizationID: categorization.id,
                metadata: categorizationInput.metadata,
              });
            }
            createCategories(
              categorization.id,
              getCategoryInput<CategoryContentInput>((number, name) => ({
                number,
                name,
              }))
            );
            onClose();
          },
          setErrorMessage
        );
      }
      sendAnalytics(categorizationEvent(CategorizationEvent.CREATE_NEW_SL_CREATE));
      // For single level categories editing when a categorization is already created
    } else if (categorization && gridController?.data) {
      if (categorizationInput && categorizationInput.isReplacing) {
        // Update name/description
        const { name, description } = categorizationInput;
        if (name !== categorization.name || description !== categorization.description) {
          updateCategorization({ name, description });
        }
        // Replace categories
        const categories = getCategoryInput<CategoryMapInput>((code, description) => ({
          code,
          description,
          level: 1,
          subcategories: [],
        }));
        replaceCategories(
          { categorizationID: categorization.id, categories, projectID: projectId },
          onClose
        );
      } else {
        onClose();
      }
    }
  };

  // Lifts the categorization name and description into the form state
  const handleCategorizationInputChange = (val: string, inputType: 'name' | 'description') => {
    if (inputType === 'name') {
      setCategorizationInput?.({ ...categorizationInput, name: val });
    } else if (inputType === 'description') {
      setCategorizationInput?.({ ...categorizationInput, description: val });
    }
  };

  // handles carrying over the imported categories into the grid
  useEffect(() => {
    if (gridController && !hasSetImportedCats && categorizationInput) {
      setHasSetImportedCats(true);
      const inputs = categorizationInput.categoryMaps?.map((catMap, i) => {
        if (i > 0) {
          gridController.addLine('EnterPress');
        }
        return [catMap.code, catMap.description];
      });

      if (inputs) {
        for (let i = gridController.data.lines.length - 1; i >= inputs.length; i -= 1) {
          gridController.deleteLine(i);
        }
        gridController.data.lines.forEach((line, index) => {
          if (index < inputs.length) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
            const numberCell: any = line.cells[0].value;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
            const nameCell: any = line.cells[1].value;
            [numberCell.string, nameCell.string] = inputs[index];
          }
        });
      }
    }
  }, [gridController, categorizationInput, hasSetImportedCats]);

  const isMultilevel = categorization && categorization.levels && categorization.levels > 1;

  return (
    <>
      <div className={classes.innerPaper}>
        {!contentOnly && (
          <>
            <div className={classes.titleContainer}>
              <div>
                <Typography variant="title">{title}</Typography>
              </div>
              <IconButton className={classes.close} title="Close dialog">
                <Close
                  onClick={() => {
                    onClose();
                    let eventType;
                    if (categorization) {
                      eventType = viewOnly
                        ? CategorizationEvent.VIEW_SL_CLOSE
                        : CategorizationEvent.EDIT_SL_CLOSE;
                    } else {
                      eventType = CategorizationEvent.CREATE_NEW_CLOSE;
                    }
                    sendAnalytics(categorizationEvent(eventType));
                  }}
                />
              </IconButton>
            </div>
            <Divider />
          </>
        )}
        <DialogContent
          className={
            viewReplaceConfirmation
              ? classes.content
              : `${classes.content} ${classes.contentHeight}`
          }
        >
          <JoinGridCategoryPanel
            categorization={
              !viewOnly && categorizationInput?.isReplacing ? undefined : categorization
            }
            categorizationInput={categorizationInput}
            classes={classes}
            disableOnReplace={viewReplaceConfirmation}
            errorMessage={errorMessage}
            onClickReplace={onClickReplace}
            permissions={permissions}
            projectId={projectId}
            setCategorizationInput={handleCategorizationInputChange}
            setErrorMessage={setErrorMessage}
            setGridController={setGridController}
            updateCategorization={updateCategorization}
            viewOnly={viewOnly}
          />
          {viewReplaceConfirmation && (
            <ReplaceCategorizationConfirm
              onBack={isMultilevel ? onBackButtonMultilevel : onBackButton}
              onSubmit={onSubmit}
            />
          )}
        </DialogContent>

        {!contentOnly && !viewReplaceConfirmation && (
          <DialogActions className={classes.action}>
            {categorization && (
              <Typography className={classes.left} variant="body2">
                <GetApp className={`${classes.icon} ${classes.leftIconPosition}`} />
                <button
                  className={classes.linkButton}
                  onClick={() => {
                    joinAPI.exportCategories(categorization.id, undefined, [categorization.name]);
                    sendAnalytics(
                      categorizationEvent(
                        viewOnly
                          ? CategorizationEvent.VIEW_SL_DOWNLOAD
                          : CategorizationEvent.EDIT_SL_DOWNLOAD
                      )
                    );
                  }}
                  type="button"
                >
                  Download categorization details
                </button>
              </Typography>
            )}
            {onClickBack && !categorization && (
              <ShadedHelpTip
                tip={
                  <Typography className={classes.shadedTipText}>
                    <b>Tip:&nbsp;</b>
                    Multi-level categorizations can only be imported.{' '}
                    <Typography
                      className={`${classes.link} ${classes.shadedTipText}`}
                      onClick={() => {
                        onClickBack();
                        sendAnalytics(
                          categorizationEvent(CategorizationEvent.CREATE_NEW_IMPORT_BACK_CTA)
                        );
                      }}
                    >
                      Go back to import.
                    </Typography>
                  </Typography>
                }
              />
            )}

            {onBackButton && (
              <Button
                color="primary"
                data-cy="CreateCategorizationDialog-backButton"
                onClick={isMultilevel ? onBackButtonMultilevel : onBackButton}
                variant="outlined"
              >
                Back
              </Button>
            )}

            <Button
              color="primary"
              data-cy="JoinGridAddCategoriesDialog-doneButton"
              disabled={(!categorization && !categorizationName) || errorMessage !== ''}
              onClick={
                categorization && !viewOnly && categorizationInput?.isReplacing
                  ? () => setViewReplaceConfirmation(true)
                  : onSubmit
              }
              variant="contained"
            >
              {buttonText}
            </Button>
          </DialogActions>
        )}
      </div>
    </>
  );
};

export default withStyles(AddCategoriesDialogStyles)(JoinGridAddCategoriesDialog);
