import { FC, Fragment } from 'react';

import { ITEM_WITH_OPTIONS } from '../../../constants';
import { PermissionResource } from '../../../generated/graphql';
import { parseDate } from '../../../utilities/dates';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { useShouldDisplayCosts } from '../../../utilities/permissions/useShouldDisplayCosts';
import { pluralizeString, removeYear } from '../../../utilities/string';
import InputsTextAreaStyled from '../../Inputs/InputsTextAreaStyled/InputsTextAreaStyled';
import { isScheduleSettingsDisabled } from '../../ProjectProperties/ProjectScheduleImpact/ProjectScheduleImpactSettings';
import PrintGroupHeader from '../PrintSharedComponents/PrintGroupHeader';
import PrintItemSimple from '../PrintSharedComponents/PrintItemSimple';

type PrintItemDetailsBodyProps = {
  itemLike: ItemDataQueryItem;
  permissions: ReturnType<typeof usePermissions>;
};

export const PrintItemDetailsBody: FC<PrintItemDetailsBodyProps> = ({ itemLike, permissions }) => {
  const isScheduleImpactEnabled = !isScheduleSettingsDisabled();
  const { shouldDisplayCosts } = useShouldDisplayCosts();
  const canViewItemAttachments = permissions.canView(PermissionResource.ITEM_ATTACHMENTS, {
    trades: itemLike?.categories || [],
  });
  const groupedOptions = (
    <>
      {itemLike.itemType === ITEM_WITH_OPTIONS && (
        <>
          <PrintGroupHeader
            hasHeader
            hasTwoColumns={!isScheduleImpactEnabled}
            headerText={`${pluralizeString('Option', (itemLike as PrintItem).options.length)} (${
              (itemLike as PrintItem).options.length
            })`}
            headerTextRight={
              <>
                {shouldDisplayCosts ? (
                  <div className="print-group-header-text text-right">Cost Impact</div>
                ) : (
                  <div />
                )}
                {isScheduleImpactEnabled && (
                  <div className="print-group-header-text text-right">Schedule Impact</div>
                )}
              </>
            }
          />
          {(itemLike as PrintItem).options.map((option: PrintOption) => (
            <div key={option.id} className="print-avoid-page-break">
              <PrintItemSimple
                isExact
                itemLike={option}
                parentItem={itemLike}
                shouldDisplayCosts={shouldDisplayCosts}
                unfiltered
              />
              <hr className="print-sub-divider" />
            </div>
          ))}
        </>
      )}
    </>
  );
  return (
    <>
      <div className="print-item-detail-title">{itemLike.name}</div>
      {itemLike.description && (
        <div className="print-item-detail-description">
          <InputsTextAreaStyled
            isEditable={false}
            placeholder=""
            value={itemLike.descriptionStyled}
          />
        </div>
      )}
      {groupedOptions}
      {itemLike.assignee && (
        <div className="print-item-detail-attribute print-flex">
          <div className="print-bold-text">&nbsp;Assignee:&nbsp;</div>
          {`${itemLike.assignee.name} (${itemLike.assignee.email})`}
        </div>
      )}
      {itemLike.dueDate && (
        <div className="print-item-detail-attribute print-flex">
          <div className="print-bold-text">&nbsp;Due:&nbsp;</div>
          {parseDate(itemLike.dueDate)}
        </div>
      )}
      {itemLike.categories &&
        itemLike.categories.map((category) => {
          const categorizationText: string =
            (category.categorization && category.categorization.name) || '';
          let categoryText: string = category.number;
          categoryText += `${category.number && category.name ? ` - ` : ''} ${category.name}`;
          return (
            <div key={category.id} className="print-item-detail-attribute print-flex">
              <div className="print-bold-text">&nbsp;{removeYear(categorizationText)}:&nbsp;</div>
              {categoryText}
            </div>
          );
        })}
      {itemLike.assetCount > 0 && canViewItemAttachments && (
        <div className="print-item-detail-attribute">
          <div>
            <span className="print-bold-text">&nbsp;Attachments:&nbsp;</span>
            {itemLike.assets.map((asset, index) => {
              const { id, name } = asset;
              const lastAsset: boolean = index === itemLike.assetCount - 1;
              return (
                <Fragment key={id}>
                  <span className="print-asset-text">
                    <u>{name}</u>
                  </span>
                  {!lastAsset ? ', ' : ''}
                </Fragment>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};
