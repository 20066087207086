import * as icons from './icons';

export const JoshIcons = {
  add: icons.AddIcon,
  alerts: icons.AlertsIcon,
  all_projects: icons.AllProjectsIcon,
  calendar: icons.CalendarIcon,
  chevron: icons.ChevronIcon,
  collapse_sidebar: icons.CollapseSidebarIcon,
  company: icons.CompanyIcon,
  compass: icons.CompassIcon,
  configure2: icons.Configure2Icon,
  configure: icons.ConfigureIcon,
  contact_support2: icons.ContactSupport2Icon,
  contact_support: icons.ContactSupportIcon,
  dashboard: icons.DashboardIcon,
  dialog_bubble: icons.DialogBubbleIcon,
  dropdown: icons.DropdownIcon,
  edit_profile: icons.EditProfileIcon,
  email: icons.EmailIcon,
  expand_sidebar: icons.ExpandSidebarIcon,
  forecasting: icons.ForecastingIcon,
  gear_v1: icons.GearV1Icon,
  gear_v2: icons.GearV2Icon,
  gear_v3: icons.GearV3Icon,
  gears: icons.GearsIcon,
  hard_hat: icons.HardHatIcon,
  help: icons.HelpIcon,
  hide_alt: icons.HideAltIcon,
  hide: icons.HideIcon,
  historical_items: icons.HistoricalItemsIcon,
  home: icons.HomeIcon,
  insights: icons.InsightsIcon,
  items: icons.ItemsIcon,
  keyboard_shortcuts: icons.KeyboardShortcutsIcon,
  location_pin: icons.LocationPinIcon,
  logout2: icons.Logout2Icon,
  logout: icons.LogoutIcon,
  milestone: icons.MilestoneIcon,
  page: icons.PageIcon,
  project_health: icons.ProjectHealthIcon,
  project: icons.ProjectIcon,
  reports: icons.ReportsIcon,
  scenarios: icons.ScenariosIcon,
  search: icons.SearchIcon,
  settings: icons.SettingsIcon,
  show_direct_costs_only: icons.ShowDirectCostsOnlyIcon,
  show: icons.ShowIcon,
  show_markups_allocated: icons.ShowMarkupsAllocatedIcon,
  show_markups_separated: icons.ShowMarkupsSeparatedIcon,
  team: icons.TeamIcon,
  timeline: icons.TimelineIcon,
  user: icons.UserIcon,
  visit_help_center: icons.VisitHelpCenterIcon,
  whats_new2: icons.WhatsNew2Icon,
  whats_new: icons.WhatsNewIcon,
} as const;

export type JoshIconType = keyof typeof JoshIcons;

export const isJoshIcon = (icon: string): icon is JoshIconType => {
  const joshIconStrings = Object.keys(JoshIcons);
  return joshIconStrings.indexOf(icon as JoshIconType) !== -1;
};

export const getJoshIcon = (icon: string) => {
  return isJoshIcon(icon) ? JoshIcons[icon] : undefined;
};
