import { pluralizeCountString } from '../../../utilities/string';
import { Button } from '../../scales';

type CreateButtonProps = {
  activityCount: number;
  onSubmit: () => void;
};

export default function CreateButton(props: CreateButtonProps) {
  return (
    <Button
      disabled={props.activityCount === 0}
      label={`Create ${pluralizeCountString('activity', props.activityCount)}`}
      onClick={props.onSubmit}
      type="primary"
    />
  );
}
