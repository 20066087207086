import { gql } from '../../../../api/graphqlFragments';

export const statusEventsQuery = gql`
  query statusEvents(
    $projectID: UUID!
    $milestoneID: UUID!
    $costMode: CostMode!
    $viewFilter: ViewFilter!
    $columnInputs: [CostReportColumnInput!]!
    $quantityMagnitude: Numeric
  ) {
    costTrendlineEvents(
      projectID: $projectID
      milestoneID: $milestoneID
      costMode: $costMode
      viewFilter: $viewFilter
      columnInputs: $columnInputs
      quantityMagnitude: $quantityMagnitude
    ) {
      events {
        timestamp
        eventType
        item {
          id
        }
        eventContent {
          oldOptionStatus
          newOptionStatus
          oldStatus
          newStatus
        }
      }
    }
  }
`;
