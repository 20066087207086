import { EMPTY_COST } from '../../../utilities/string';
import { Tooltip } from '../../scales';

type Props = {
  className: string;
  costExact?: string;
  costPercentage?: string;
  costRounded?: string;
  description: string;
  icon?: JSX.Element;
  label: string;
  showTooltips?: boolean;
  'data-cy': string;
};

export default function ProjectCostRow(props: Props) {
  return (
    <Tooltip
      content={
        <div className="flex flex-col gap-0.5">
          <div className="type-label">{props.description}</div>
          <div className="type-table-number">{props.costExact || EMPTY_COST}</div>
        </div>
      }
      isDisabled={!props.costExact || !props.showTooltips}
      offset={20}
      placement="right"
    >
      <div className={`${props.className} flex items-center justify-between gap-1 type-table-text`}>
        <div className="flex w-8 items-center justify-center text-right">{props.icon}</div>
        <div className="line-clamp-1 w-[300px]">{props.label}</div>
        <div className="w-10 text-right">{props.costPercentage}</div>
        <div className="w-32 text-right" data-cy={props['data-cy']}>
          {props.costRounded}
        </div>
      </div>
    </Tooltip>
  );
}
