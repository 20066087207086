export enum EstimateType {
  ACTIVE_ESTIMATE = 'ACTIVE_ESTIMATE',
  BUDGET = 'BUDGET',
  ITEM = 'ITEM',
  OWNER_COST = 'OWNER_COST',
}

export enum ExportType {
  ESTIMATE = 'ESTIMATE',
  BUDGET = 'BUDGET',
}

export enum MarkupErrorField {
  DESCRIPTION = 'DESCRIPTION',
  REFERENCES = 'REFERENCES',
  TOTAL = 'TOTAL',
}

export enum CheckboxType {
  NOTCHECKED = 0,
  CHECKED = 1,
}

export enum TermKey {
  ESTIMATE = 'ESTIMATE',
  TARGET = 'TARGET',
  DELTA = 'DELTA',
  RUNNING_TOTAL = 'RUNNING_TOTAL',
  GAP = 'GAP',
  DIRECT_COST = 'DIRECT_COST',
  MARKUP = 'MARKUP',
  ACCEPTED_CHANGES = 'ACCEPTED_CHANGES',
  PENDING_ADDS_DEDUCTS = 'PENDING_ADDS_DEDUCTS',
  POTENTIAL_RANGE = 'POTENTIAL_RANGE',
  GAP_TRENDING_MINIMUM = 'GAP_TRENDING_MINIMUM',
  GAP_TRENDING_MAXIMUM = 'GAP_TRENDING_MAXIMUM',
}

export enum TransitionModal {
  MILESTONE = 1,
}

export enum AddToMilestoneModal {
  ADD_TO_MILESTONE = 1,
  AS_DRAFT_OR_PUBLISH = 2,
  ESTIMATE_PUBLISHED = 3,
}

export enum AddToMilestoneStates {
  EXISTING_MILESTONE_NO_ESTIMATE = 0,
  EXISTING_MILESTONE_WITH_ESTIMATE = 1,
  NEW_MILESTONE = 2,
}

export enum ImportModal {
  MAP_UF_MF = 1,
  INCLUDE = 2,
  MAP_WBS = 3,
  ORDER = 4,
  TOTAL_TYPE = 5,
}

export enum VarianceModal {
  MILESTONES = 1,
  EXPRESSION = 2,
  WHAT_TO_SEE = 3,
}

export enum MergeModal {
  // TODO: Review needed steps
  MILESTONE = 1,
  BASIS = 2,
  PREVIEW = 3,
}

export enum VarianceExpression {
  CURRENCY = '$',
  CURRENCY_SF = '$/SF',
  CURRENCY_KEY = '$/Key',
}

export enum PermissionGroup {
  ADMIN = 'Administrator',
  DESIGNTEAM = 'Design Team',
  GC = 'General Contractor',
  SUB = 'Subcontractor',
  OWNER = 'Owner',
}

export enum ToastType {
  NONE = 'NONE',
  DEFAULT = 'DEFAULT',
  DETACH_OPTION = 'DETACH_OPTION',
  NEW_ITEM = 'NEW_ITEM',
  COPY_ITEM = 'COPY_ITEM',
  BULK_ITEM_UPDATE = 'BULK_ITEM_UPDATE',
  SERVER_ERROR = 'SERVER_ERROR',
  IMPORT_ESTIMATE = 'IMPORT_ESTIMATE',
  IMPORT_ESTIMATE_WINEST_EST = 'IMPORT_ESTIMATE_WINEST_EST',
  EXPORTING_EXCEL = 'EXPORTING_EXCEL',
  EXCLUDE_INCLUDE_PROJECT_COMP = 'EXCLUDE_INCLUDE_PROJECT_COMP',
  SUCCESS = 'SUCCESS',
}

export enum InputVariant {
  GRID = 'grid',
  CHIP_INPUT = 'chipInput',
}

export enum SelectVariant {
  SINGLE = 'single',
  MULTI = 'multi',
}

export enum CategorizationDialogType {
  NEW = 'NEW',
  ADD = 'ADD',
  REMOVE = 'REMOVE',
  EDIT = 'EDIT',
  NONE = 'NONE',
  VIEW = 'VIEW',
  MULTILEVEL_VIEW = 'MULTILEVEL_VIEW',
  MULTILEVEL_EDIT = 'MULTILEVEL_EDIT',
}

export enum TimelineGroups {
  TIMELINE = 'TIMELINE',
  ITEMS = 'ITEMS',
}

export enum ReportGroups {
  MY_REPORTS = 'MY_REPORTS',
  SHARED_REPORTS = 'SHARED_REPORTS',
  BUILT_IN_REPORTS = 'BUILT_IN_REPORTS',
}

export enum JoinRoutes {
  COMPANY_TAB = 'COMPANY_TAB',
  PROJECT = 'PROJECT',
}

export enum JoinProjectRoutes {
  BREAKDOWNS = 'BREAKDOWNS',
  CONTINGENCY_ALL_MILESTONES_REPORT = 'CONTINGENCY_ALL_MILESTONES_REPORT',
  CONTINGENCY_ALLOWANCE_REPORT = 'CONTINGENCY_ALLOWANCE_REPORT',
  EXPORT_ACTIVE_MILESTONE_CONTINGENCY = 'EXPORT_ACTIVE_MILESTONE_CONTINGENCY',
  EXPORT_ALL_MILESTONES_CONTINGENCY = 'EXPORT_ALL_MILESTONES_CONTINGENCY',
  EXPORT_ITEMS_LIST = 'EXPORT_ITEMS_LIST',
  EXPORT_MILESTONE_BUDGET = 'EXPORT_MILESTONE_BUDGET',
  EXPORT_MILESTONE_ESTIMATE = 'EXPORT_MILESTONE_ESTIMATE',
  EXPORT_MSR = 'EXPORT_MSR',
  EXPORT_PROJECT_COST_BREAKDOWN_REPORT = 'EXPORT_PROJECT_COST_BREAKDOWN_REPORT',
  EXPORT_VARIANCE = 'EXPORT_VARIANCE',
  IMPORT_ESTIMATE = 'IMPORT_ESTIMATE',
  MILESTONE_DETAILS = 'MILESTONE_DETAILS',
  MILESTONES = 'MILESTONES',
  MSR = 'MSR',
  PRINT_CONTINGENCY_ALL_MILESTONES_REPORT = 'PRINT_CONTINGENCY_ALL_MILESTONES_REPORT',
  PRINT_CONTINGENCY_ALLOWANCE_REPORT = 'PRINT_CONTINGENCY_ALLOWANCE_REPORT',
  PRINT_ITEM_DETAILS = 'PRINT_ITEM_DETAILS',
  PRINT_ITEMS_LIST = 'PRINT_ITEMS_LIST',
  PRINT_ITEMS_LIST_DETAILS = 'PRINT_ITEMS_LIST_DETAILS',
  PRINT_MSR = 'PRINT_MSR',
  PRINT_MSR_SUMMARY = 'PRINT_MSR_SUMMARY',
  PRINT_PROJECT_COST_BREAKDOWN = 'PRINT_PROJECT_COST_BREAKDOWN',
  PRINT_PROJECT_DASHBOARD = 'PRINT_PROJECT_DASHBOARD',
  PRINT_PROJECT_DASHBOARD_SUMMARY = 'PRINT_PROJECT_DASHBOARD_SUMMARY',
  PRINT_REPORT_ROUTER = 'PRINT_REPORT_ROUTER',
  PRINT_SCENARIOS = 'PRINT_SCENARIOS',
  PRINT_VARIANCE = 'PRINT_VARIANCE',
  PROJECT_DASHBOARD = 'PROJECT_DASHBOARD',
  PROJECT_COST_BREAKDOWN_REPORT = 'PROJECT_COST_BREAKDOWN_REPORT',
  REPORT_DISTRIBUTION_FILE = 'REPORT_DISTRIBUTION_FILE',
  REPORT_DISTRIBUTION_UNSUBSCRIBE = 'REPORT_DISTRIBUTION_UNSUBSCRIBE',
  REPORT_ROUTER = 'REPORT_ROUTER',
  REPORTS = 'REPORTS',
  SCENARIOS = 'SCENARIOS',
  SETTINGS = 'SETTINGS',
  SIGNUP_CONFIRM_COMPANY = 'SIGNUP_CONFIRM_COMPANY',
  SIGNUP_CREATE_COMPANY = 'SIGNUP_CREATE_COMPANY',
  SIGNUP_SETUP_USER = 'SIGNUP_SETUP_USER',
  TIMELINE = 'TIMELINE',
  TRENDING_COST_REPORT = 'TRENDING_COST_REPORT',
  VARIANCE = 'VARIANCE',
}

export enum JoinCompanyRoutes {
  COMPANY_COLLABORATORS = 'COMPANY_COLLABORATORS',
  COMPANY_COLLABORATORS_PROFILE = 'COMPANY_COLLABORATORS_PROFILE',
  COMPANY_INSIGHTS_ALERTS = 'COMPANY_INSIGHTS_ALERTS',
  COMPANY_MEMBERS = 'COMPANY_MEMBERS',
  COMPANY_MEMBERS_PROFILE = 'COMPANY_MEMBERS_PROFILE',
  COMPANY_PROJECT_STATS = 'COMPANY_PROJECT_STATS',
  COMPANY_ORGANIZATIONS = 'COMPANY_ORGANIZATIONS',
  COMPANY_SETTINGS = 'COMPANY_SETTINGS',
  COMPANY_STANDARDS = 'COMPANY_STANDARDS',
}

export enum JoinCompanyOrganizationsRoutes {
  COMPANY_ORGANIZATION = 'COMPANY_ORGANIZATION',
}

export enum JoinCompanyStandardsRoutes {
  COMPANY_CATEGORIZATIONS = 'COMPANY_CATEGORIZATIONS',
  COMPANY_PROJECT_TEMPLATES = 'COMPANY_PROJECT_TEMPLATES',
  COMPANY_PROJECT_TYPES = 'COMPANY_PROJECT_TYPES',
}

// Note: the order here is important
// this is the order we want to see the orgs in the UI
export enum OrganizationType {
  OWNER = 'Owner',
  GC = 'General Contractor',
  DESIGNTEAM = 'Design Team',
}

export enum UploadPlatform {
  EMPTY_STATE = 'EMPTY_STATE',
  DESTINI = 'Destini',
  WINEST = 'Winest',
  SAGE = 'Sage',
  EXCEL = 'Excel',
  PROCORE = 'Procore',
}

export enum ProjectStatuses {
  IN_PURSUIT = 'In Pursuit',
  ACTIVE = 'Active',
  COMPLETED = 'Completed',
  ON_HOLD = 'On Hold',
  ABANDONED = 'Abandoned',
  LOST = 'Lost',
  TEST_PROJECT = 'Test Project',
}

// ToDo: This list should probably be updated
export enum ProjectTypes {
  RESIDENTIAL = 'Residential',
  PUBLIC = 'Public',
  EDUCATION = 'Education',
  HEALTHCARE = 'Healthcare',
  INFRASTRUCTURE = 'Infrastructure',
  MIXED_USE = 'Mixed Use',
  WASTEWATER = 'Wastewater',
  MULTIFAMILY = 'Multi-Family',
  COMMERCIAL = 'Commercial',
  CORPORATE = 'Corporate',
}

export enum ItemDueDateTypes {
  PAST_DUE = 'Past Due',
  DUE_THIS_WEEK = 'Due This Week',
  UPCOMING = 'Upcoming',
  NO_DUE_DATE = 'No Due Date Assigned',
}

export enum ExecutiveDashboardOpenItemsDueSorting {
  MOST_OPEN = 'Pending Items',
  MOST_OVERDUE = 'Past Due Items',
  MOST_DUE_THIS_WEEK = 'Items Due This Week',
}

export enum UserActivity {
  COMMENTS_ADDED = 'commentsAdded',
  ITEMS_CREATED = 'itemsCreated',
}

export enum ChartLegendOrientation {
  VERTICAL = 'VERTICAL',
  VERTICAL_WRAP = 'VERTICAL_WRAP',
  HORIZONTAL = 'HORIZONTAL',
  WRAP = 'WRAP',
  COLUMNS = 'COLUMNS',
}

export enum PieChartComponentOrientation {
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL',
}

export enum InputSettingsPanelSize {
  SMALL = 'small',
  MEDIUM = 'medium',
}

export enum FieldGroup {
  CATEGORIZATIONS = 'Categorizations',
  LABOR = 'Labor',
  EQUIPMENT = 'Equipment',
  MATERIAL = 'Material',
  SUBCONTRACTOR = 'Subcontractor',
  OTHER = 'Other',
  USER = 'User',
}

export enum ProjectCompSectionType {
  SECTION_COSTS = 'SECTION_COSTS',
  SECTION_ESCALATION = 'SECTION_ESCALATION',
  SECTION_FIELDS = 'SECTION_FIELDS',
  SECTION_GRAPHS = 'SECTION_GRAPHS',
  SECTION_METRICS = 'SECTION_METRICS',
  SECTION_SETTINGS = 'SECTION_SETTINGS',
  SUBSECTION_MARKUPS = 'SUBSECTION_MARKUPS',
}

export enum CustomizationTab {
  IMAGE = 'Upload Image',
  COLOR = 'Choose Color',
}

export enum DesignPhaseEnum {
  'Programming',
  'Pre-Design',
  'Conceptual Design',
  'Schematic Design',
  'Design Development',
  'Construction Documents',
  'Guaranteed Maximum Price',
  'As-Built Costs/Final Construction Costs',
}
