import * as d3 from 'd3';
import React from 'react';

import { formatCost } from '../../../../utilities/currency';
import { Tooltip } from '../../../scales';

import { PieChartData, PieChartOptions, PieChartSize, describeArc } from './ChartsPieGraphUtils';
import { getColor } from './ChartsPieLabelTooltip';

type Props = {
  chartSize: PieChartSize;
  colors: string[];
  data: PieChartData[];
  dataOther?: PieChartData[]; // Used for the tooltip to display list of "Other" entries that are consisted in this grouping
  displaySectionTooltip?: boolean;
  isCurrency?: boolean;
  options?: PieChartOptions;
};

// By default the chart will start drawing at 3 o' clock,
// and draw clockwise around the circle
// We want to start drawing at noon.  So, to start drawing
// at noon we need change the start angle by -90 degrees
// which in radians is -π / 2
function ChartsPieGraphPaths({
  chartSize,
  colors,
  data,
  dataOther,
  displaySectionTooltip,
  isCurrency,
  options,
}: Props) {
  const totalShare = data.reduce((sum, item) => sum + item.share, 0);

  const pieData = d3
    .pie<PieChartData>()
    .startAngle(-Math.PI / 2) // see comment above
    .value((d) => d.share)
    .padAngle(options?.spaceBetweenWedges ? 0.02 : 0)
    .sort(() => {
      // Disable the default sorting, and just use the order of the input data
      return 0;
    })(data);

  // set the colors based on the data
  // note that the length of data, and colors should be the same
  const scale = d3.scaleOrdinal(data.map((d) => d.name)).range(colors);
  const radius = chartSize.diameter / 2;

  return (
    <>
      {/* Pie chart in SVG */}
      <svg height={chartSize.diameter} width={chartSize.diameter}>
        {pieData.map((d) => (
          <Tooltip
            key={d.data.name}
            content={
              <DonutTooltipContent
                dataOther={dataOther}
                fill={getColor(colors, d.data, d.index)}
                isCurrency={isCurrency}
                name={d.data.name}
                share={d.data.share}
                totalShare={totalShare}
              />
            }
            isDisabled={!displaySectionTooltip}
            placement="bottom"
          >
            <path
              key={d.data.name}
              className={scale(d.index.toString())}
              d={describeArc(radius, radius, radius, d.startAngle, d.endAngle, d.padAngle)}
              fill={getColor(colors, d.data, d.index)}
            />
          </Tooltip>
        ))}
      </svg>
    </>
  );
}

export default ChartsPieGraphPaths;

type DonutTooltipContentProps = {
  dataOther?: PieChartData[];
  fill: string;
  isCurrency?: boolean;
  name: string;
  share: number | null;
  totalShare: number | null;
};

function DonutTooltipContent({
  dataOther,
  fill,
  isCurrency,
  name,
  share,
  totalShare,
}: DonutTooltipContentProps) {
  return (
    <div className="flex type-body2">
      {name.includes('Other ') && dataOther ? (
        <div className="flex flex-col">
          <div className="flex flex-row items-center gap-2">
            <div className="h-3 w-3" style={{ backgroundColor: fill }} />
            <div className="type-heading3">{name}</div>
          </div>
          <div className="grid grid-cols-[130px_40px_40px] items-center gap-1 text-left">
            {dataOther?.map((item) => (
              <React.Fragment key={item.name}>
                <div className="line-clamp-1 w-full">{item.name}</div>
                <div className="text-right">
                  {!isCurrency
                    ? item.share
                    : formatCost(item.share, { short: true, showCents: false })}
                </div>
                {/* Calculate percentage */}
                <div className="text-right">
                  {totalShare && ((item.share / totalShare) * 100).toFixed()}%
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-[16px_110px_40px_40px] items-center gap-1 text-left">
          <div className="h-3 w-3" style={{ backgroundColor: fill }} />
          <div className="line-clamp-1 w-full">{name}</div>
          <div className="text-right">
            {isCurrency ? formatCost(share, { short: true, showCents: false }) : share}
          </div>
          {/* Calculate percentage */}
          <div className="text-right">
            {share && totalShare && ((share / totalShare) * 100).toFixed()}%
          </div>
        </div>
      )}
    </div>
  );
}
