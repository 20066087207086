import { useParams } from 'react-router-dom';

import ProgramDashboardData from './ProgramDashboardData';

export default function ProgramDashboardRoute() {
  const { programID } = useParams();

  if (!programID) {
    throw new Error('Failed to get programID param');
  }

  return <ProgramDashboardData programID={programID} />;
}
