import ChartsPieGraph from '../../Charts/ChartsD3/ChartsPieGraph/ChartsPieGraph';
import { InsightsProject } from '../hooks/useInsightsProjectsQuery';

export default function PendingItemDonutChart(props: { project: InsightsProject }) {
  const total =
    props.project.pendingItemsDueInFuture +
    props.project.pendingItemsMissingDueDates +
    props.project.pendingItemsPastDue;
  const pieChartData = [
    {
      name: 'Due in future',
      share: props.project.pendingItemsDueInFuture / total,
    },
    {
      name: 'Past Due',
      share: props.project.pendingItemsPastDue / total,
    },
    {
      name: 'Missing Due Dates',
      share: props.project.pendingItemsMissingDueDates / total,
    },
  ];

  return (
    <ChartsPieGraph
      centerLabel={{
        label: 'Pending',
        sublabel: String(total),
      }}
      chartSize={{
        diameter: 170,
        insideDiameter: 110,
      }}
      colors={[
        'var(--colors-item-status-pending)',
        'var(--colors-entities-item-pastdue)',
        'var(--colors-item-status-pending-tint)',
        'var(--colors-item-status-not-applicable)',
      ]}
      data={pieChartData}
    />
  );
}
