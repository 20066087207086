import { SourceSystem, UserSourcesQuery } from '../../../../generated/graphql';

import { getSourceSystemIcon } from './IntegrationsUtils';

type Props = {
  name: string;
  userSources: UserSourcesQuery['userSources'] | undefined;
};

function IntegrationsHeader(props: Props) {
  const linkedAccounts = props.userSources?.sources?.map((s) => s.sourceSystem);
  const autodeskLoggedIn = linkedAccounts?.includes(SourceSystem.AUTODESK);
  const procoreLoggedIn = linkedAccounts?.includes(SourceSystem.PROCORE);

  return (
    <div className="flex flex-grow  justify-between">
      <div
        className="pt-0.5 text-base font-medium leading-none text-type-primary"
        data-cy="item-details-integrations-panel"
      >
        {props.name}
      </div>
      <div className="flex gap-3">
        {autodeskLoggedIn && getSourceSystemIcon(SourceSystem.AUTODESK)}
        {procoreLoggedIn && getSourceSystemIcon(SourceSystem.PROCORE)}
      </div>
    </div>
  );
}

export default IntegrationsHeader;
