import { Link, To } from 'react-router-dom';

import { LocationOnOutlined } from '@material-ui/icons';

import { ImageDimension } from '../../../generated/graphql';
import { SizeIcon } from '../../Nav/icons';
import { Chip } from '../../scales';
import Thumbnail from '../../shared-widgets/Thumbnail/Thumbnail';

type Props = {
  hasAlert: boolean;
  project: InsightsProjects;
  to: To;
};

export default function ProjectTableEntry({ hasAlert, project, to }: Props) {
  const chips = (
    <div className="flex flex-wrap gap-2">
      <div className="max-w-[200px]">
        <Chip icon={<LocationOnOutlined />} text={project.location} />
      </div>
      {project.milestoneGSF && Number(project.milestoneGSF) !== 0 && (
        <div className="max-w-[200px]">
          <Chip icon={<SizeIcon />} text={Number(project.milestoneGSF).toLocaleString()} />
        </div>
      )}
    </div>
  );

  return (
    <div className="flex items-center gap-2">
      <Thumbnail
        dimension={ImageDimension._92}
        padding={0}
        showError={hasAlert}
        size={60}
        thumbnail={project.thumbnail}
      />
      <div className="flex flex-col justify-center gap-2">
        <div>
          {project.hasAccess ? (
            <Link to={to}>
              <div className="line-clamp-2 text-type-link type-heading3">{project.name}</div>
            </Link>
          ) : (
            <div className="line-clamp-2 type-heading3">{project.name}</div>
          )}
        </div>
        {chips}
      </div>
    </div>
  );
}
