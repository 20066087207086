import { useEffect } from 'react';

import { YC_DEMO_MOCKS_INSIGHTS_V2 } from '../../../features';
import {
  InsightsProjectsQuery,
  InsightsProjectsQueryVariables,
  TimelineQuery,
} from '../../../generated/graphql';
import { useHasFeature } from '../../../hooks/useHasFeature';
import { useQuery } from '../../../hooks/useMountAwareQuery';
import useQueryPagination from '../../../hooks/useQueryPagination';
import { getTodayUtcNoon } from '../../../utilities/dates';
import { ItemTimeSeries } from '../../dragon-scales/TimelineCharts/InsightsItems/types';
import { fixMocksStartEnd, fixMocksToday } from '../utils';

import { parseDemoData } from './demoData';
import { insightsProjectsQuery } from './queries';

export type InsightsProject = InsightsProjects & {
  itemsDueDateSeriesMockData?: ItemTimeSeries[];
  timelineActivitiesMockData?: TimelineQuery['timeline']['activities'];
};

export const useInsightsProjectsQuery = (params: InsightsProjectsQueryVariables) => {
  const useDemoData = useHasFeature(YC_DEMO_MOCKS_INSIGHTS_V2);
  const query = useQuery<InsightsProjectsQuery, InsightsProjectsQueryVariables>(
    insightsProjectsQuery,
    {
      variables: params,
      skip: !params.input.companyID,
    }
  );
  const insightsProjectsData = query?.data?.insights;

  const { fetchMore, resetPagination } = useQueryPagination(
    params.pagination.limit,
    insightsProjectsData?.length ?? 0,
    query.fetchMore
  );

  // Fully reset pagination whenever the query variables (filters, search, sort..) change.
  useEffect(() => {
    resetPagination();
  }, [resetPagination, params.input]);

  if (!useDemoData) {
    return { data: insightsProjectsData, fetchMore };
  }
  return { data: insightsProjectsData?.map((p) => fixProjectMocks(p)), fetchMore };
};

export const fixProjectMocks = (project: InsightsProject, isFix?: boolean) => {
  if (!isFix && (!project.demoData || project.demoData.length === 0 || project.demoData === '{}')) {
    return project;
  }
  const parsed = parseDemoData(project.demoData || '');

  const referenceDate = parsed.demoDataReferenceDate;
  const delta = referenceDate ? getTodayUtcNoon().getTime() - referenceDate.getTime() : 0;
  if (parsed.costTimeseries) {
    parsed.costTimeseries = fixMocksToday(parsed.costTimeseries, delta);
  }
  if (parsed.contingenciesTimeseries) {
    parsed.contingenciesTimeseries = fixMocksToday(parsed.contingenciesTimeseries, delta);
  }
  if (parsed.milestones) {
    parsed.milestones = fixMocksToday(parsed.milestones, delta);
  }
  if (parsed.itemsDueDateSeriesMockData) {
    parsed.itemsDueDateSeriesMockData = fixMocksToday(
      parsed.itemsDueDateSeriesMockData || [],
      delta
    );
  }
  if (parsed.timelineActivitiesMockData) {
    parsed.timelineActivitiesMockData = fixMocksStartEnd(
      parsed.timelineActivitiesMockData || [],
      delta
    );
  }

  // Override fields in project with the parsed demo data.
  const parsedProject: InsightsProject = {
    ...project,
    ...parsed,
  };
  return parsedProject;
};
