import { ScalePoint, ScaleTime } from 'd3-scale';

import useMemoWrapper from '../../useMemoWrapper';

type Data = { date: Date };

export default function TimelineHoverSections(props: {
  data: Data[];
  bounds: {
    right: number;
    left: number;
    top: number;
    bottom: number;
  };
  x: ScaleTime<number, number> | ScalePoint<Date>;
  onHoverIndex: (index: number) => void;
}) {
  const reversed = useMemoWrapper(reverseData, props.data);
  const xPositions = useMemoWrapper(getXPositions, reversed, props.x);

  if (!props.data?.length) return null;
  return (
    <rect
      fill="#00000000"
      height={props.bounds.top - props.bounds.bottom}
      onMouseMove={(evt) => {
        const { left } = evt.currentTarget.getBoundingClientRect();
        const xLeft = evt.clientX - left;
        const reversedIndex = xPositions.findIndex((xPosition) => xPosition < xLeft);
        const index = props.data.length - 1 - reversedIndex;
        props.onHoverIndex(index);
      }}
      onMouseOut={(evt) => {
        const { left, top } = evt.currentTarget.getBoundingClientRect();
        const xLeft = evt.clientX - left;
        const yTop = evt.clientY - top;
        if (xLeft > 0 && yTop > 0 && xLeft < props.bounds.right && yTop < props.bounds.top) return;
        props.onHoverIndex(-1);
      }}
      width={props.bounds.right - props.bounds.left}
    />
  );
}

const reverseData = (data: Data[]) => [...data].reverse();

const getXPositions = (data: Data[], x: ScaleTime<number, number> | ScalePoint<Date>) =>
  data.map(({ date }) => x(date) ?? 0);
