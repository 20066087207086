import { FC, KeyboardEvent, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  LeadEvent,
  LeadKey,
  NewProjectEvent,
  leadEvent,
  newProjectEvent,
} from '../../../analytics/analyticsEventProperties';
import { UserStatus } from '../../../api/gqlEnumsBe';
import { UNASSIGNED } from '../../../constants';
import { DD_TIMELINE_ACTIVITIES_NUDGE } from '../../../features';
import { CreateProjectMutationVariables, ProjectType } from '../../../generated/graphql';
import { useCreateProject } from '../../../hooks/ProjectHooks';
import useCollaboratorsQuery from '../../../hooks/useCollaboratorsQuery';
import { useCompanyProjectTypesQuery } from '../../../hooks/useCompanyProjectTypesQuery';
import { useHasFeature } from '../../../hooks/useHasFeature';
import { useProjectDeliveryTypes } from '../../../hooks/useProjectDeliveryTypesQuery';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { EmptyThumbnail } from '../../AssigneeSelect/AssigneeSelect';
import { useCreateCollaborators } from '../../Collaborators/hooks';
import { useSetProjectLead } from '../../Collaborators/hooks/projectLead';
import UserAvatar from '../../Collaborators/UserAvatar';
import useOrganizationsQuery from '../../CompanyTab/CompanyTabOrganizations/hooks/useOrganizationsQuery';
import { filterPublished } from '../../CompanyTab/CompanyTabOrganizations/OrgSelector/utils';
import { useCompanyRoutesData } from '../../CompanyTab/CompanyTabUtils';
import { useCompanyUsersQuery } from '../../CompanyTab/useCompanyUsersQuery';
import { useKeyDates } from '../../KeyDatesNudge/hooks/useKeyDates';
import { useDesignPhaseTypes } from '../../Milestone/hooks/useDesignPhaseTypesQuery';
import { useSetProjectOrgs } from '../../Organizations/hooks/hooks';
import useProjectStatusesQuery from '../../ProjectsList/hooks/useProjectStatusesQuery';
import { DialogFlow, SelectEntry } from '../../scales';
import { ACTIVITIES_DEFAULT } from '../../Timeline/TimelineUtils';
import useMemoWrapper from '../../useMemoWrapper';

import {
  computeClearInactive,
  computeProjectTypes,
  getProjectInputDefault,
} from './DialogsNewProjectUtils';
import getKeyDatesStep from './steps/KeyDatesStep/getKeyDatesStep';
import getLeadStep from './steps/LeadStep/getLeadStep';
import getMilestoneStep from './steps/MilestoneStep/getMilestoneStep';
import getOverviewStep from './steps/OverviewStep/getOverviewStep';

type DialogsNewProjectProps = {
  isOpen: boolean;
  onClose: () => void;
  template?: ProjectTemplate;
};

const DialogsNewProject: FC<DialogsNewProjectProps> = ({
  isOpen: dialogOpen = false,
  onClose,
  template,
}) => {
  const createProject = useCreateProject();
  const [createCollaborators] = useCreateCollaborators();
  const [setProjectOrgs] = useSetProjectOrgs();
  const [setProjectLead] = useSetProjectLead();

  const sendAnalytics = useSendAnalytics();
  const hasKeyDatesFeature = useHasFeature(DD_TIMELINE_ACTIVITIES_NUDGE);
  const navigate = useNavigate();

  const [submitted, setSubmitted] = useState(false);

  const templateProject = template?.project;
  const projectInput = getProjectInputDefault(templateProject, template?.settings);
  const { data } = useCollaboratorsQuery(templateProject?.id);
  const collaboratorsFromTemplate = useMemo(() => data?.collaborators || [], [data?.collaborators]);
  const [project, setProject] = useState<CreateProjectMutationVariables>(projectInput);
  const [projectID, setProjectID] = useState<string | null>(null);
  const [projectLeadID, setProjectLeadID] = useState<UUID | undefined>(
    collaboratorsFromTemplate.find((c) => c.isProjectLead === true)?.user.id
  );

  const resetProject = () => setProject(projectInput);
  const {
    milestone,
    milestoneDate,
    milestoneDesignPhaseID,
    name,
    statusID,
    typeID,
    projectDeliveryTypeID,
  } = project;

  const onKeyDown = (evt: KeyboardEvent<HTMLDivElement>) => {
    if (evt.key === 'Enter' && name !== '' && milestone && !submitted && project.statusID) {
      setSubmitted(true);
      onCreateProject();
    }
  };

  const { companyID } = useCompanyRoutesData();
  const { data: typesDataNew } = useCompanyProjectTypesQuery(false, companyID);
  const projectTypes: ProjectType[] = useMemoWrapper(
    computeProjectTypes,
    typesDataNew?.companyProjectTypes || []
  );

  const onCreateProject = () => {
    setSubmitted(true);
    createProject(
      project,
      (res) => {
        setSubmitted(false);
        setProjectID(res?.id);
      },
      () => {
        setSubmitted(false);
        onClose();
        resetProject();
      }
    );
    sendAnalytics(
      newProjectEvent(NewProjectEvent.CREATE_CTA, {
        location: window.location.pathname,
        milestoneDesignPhase: milestoneDesignPhaseID,
        milestoneName: milestone,
        milestoneStartDate: milestoneDate,
        orgNodeIDs: project.orgNodeIDs ?? undefined,
        projectDeliveryMethod: projectDeliveryTypeID ?? undefined,
        projectStatus: statusID,
        projectType: typeID ?? undefined,
      })
    );
  };
  const designPhaseTypes = useDesignPhaseTypes();
  const milestoneStep = getMilestoneStep({
    designPhaseTypes,
    project,
    setProject,
    submitted,
    onKeyDown,
    onCreateProject,
    sendAnalytics,
  });

  const projectStatuses = useProjectStatusesQuery().data?.projectStatuses ?? [];
  const statuses = useMemoWrapper(computeClearInactive, projectStatuses);
  const projectDeliveryTypes = useProjectDeliveryTypes();
  const overviewStep = getOverviewStep({
    project,
    projectDeliveryTypes,
    projectTypes,
    statuses,
    setProject,
    submitted,
    template,
    onKeyDown,
  });

  const organizationsQueryResult = useOrganizationsQuery(companyID);
  const orgs = organizationsQueryResult.data?.organizations;
  const publishedOrgs = useMemoWrapper(filterPublished, orgs);
  const { data: companyUserData } = useCompanyUsersQuery({
    variables: { companyID },
    skip: !companyID,
  });
  const activeUsers =
    useMemo(
      () =>
        companyUserData?.companyUsers.filter(
          (companyUser) => companyUser?.user?.status !== UserStatus.DEACTIVATED
        ),
      [companyUserData]
    ) || [];
  const projectLeadEntries: SelectEntry[] = [];
  activeUsers.forEach((companyUser) => {
    if (companyUser.user) {
      projectLeadEntries.push({
        id: companyUser.user?.id,
        label: companyUser.user?.name,
        startAdornment: <UserAvatar assignee={companyUser.user} />,
      });
    }
  });
  const unassignedEntry: SelectEntry = {
    id: UNASSIGNED,
    label: UNASSIGNED,
    startAdornment: <EmptyThumbnail />,
    isSection: false,
  };
  projectLeadEntries.unshift(unassignedEntry);
  const leadStep = getLeadStep({
    activeUsers,
    createCollaborators,
    navigate,
    projectLeadEntries,
    projectLeadID,
    projectID,
    publishedOrgs,
    setProjectLeadID,
    setProjectLead,
    setProjectOrgs,
    submitted,
    templateProject,
    hasKeyDatesFeature: false,
    orgs: [],
    project,
    sendAnalytics,
    setProject,
  });

  const keyDates = useKeyDates({ activityTypes: ACTIVITIES_DEFAULT, projectID });

  const steps = [
    overviewStep,
    milestoneStep,
    ...(hasKeyDatesFeature
      ? [
          getKeyDatesStep({
            keyDates,
            name,
            submitted,
          }),
        ]
      : []),
    leadStep,
  ];

  return (
    <DialogFlow
      isOpen={dialogOpen}
      onClose={(index) => {
        onClose();
        sendAnalytics(
          leadEvent(LeadKey.NEW_PROJECT, LeadEvent.CLOSE, {
            step: index + 1,
          })
        );
      }}
      onComplete={() => onClose()}
      size="md"
      steps={steps}
      title="New Project"
    />
  );
};

export default DialogsNewProject;
