import { getTextColor } from '../../../../utilities/colors';

type Data = { date: Date; value: number };

type Props = {
  backgroundColor: string[];
  data: Data[];
  content: string[];
  yDomain: [number, number];
};

const MIN_GAP = 1;
const VIEW_HEIGHT = 28;

export default function TimelineMultiTooltipContent(props: Props) {
  const min = props.yDomain[0];
  const max = props.yDomain[1];
  const local = props.data.map((data, i) => ({
    data,
    backgroundColor: props.backgroundColor[i],
    content: props.content[i],
  }));
  const m = [0];
  return (
    <div>
      {local
        .sort((a, b) => b.data.value - a.data.value)
        .map((l, i) => {
          const backgroundColor = l.backgroundColor;
          const content = l.content;
          const value = l.data.value;
          //
          const fraction = 1 - (value - min) / (max - min);
          const absoluteTop = fraction * 100;
          let marginTop = absoluteTop;
          marginTop -= VIEW_HEIGHT / 2;

          const lastMargin = m[m.length - 1];
          // Add value
          m.push(marginTop);
          // Relative to the top element
          marginTop = marginTop - lastMargin - (i === 0 ? VIEW_HEIGHT / 2.5 : VIEW_HEIGHT);
          // Roll back
          if (marginTop < 0) {
            marginTop = MIN_GAP;
            m[m.length - 1] = m[m.length - 2] + VIEW_HEIGHT + MIN_GAP;
          }
          const textClassName = getTextColor(backgroundColor);
          return (
            <div
              key={`${i.toString()}-${content}`}
              className={[
                'max-w-[400px] overflow-hidden rounded-md border-border-muted',
                'px-1 py-0',
                textClassName,
              ].join(' ')}
              style={{
                backgroundColor,
                marginTop,
              }}
            >
              {content}
            </div>
          );
        })}
    </div>
  );
}
