import { ProgramProjectFieldsFragment } from '../../../../../generated/graphql';
import ProjectSelection from '../../../../ProjectsList/ProjectSelection/ProjectSelection';
import { Button, DialogFlowStep } from '../../../../scales';

type Props = {
  onChange: (newProjectIDs: UUID[]) => void;
  onSubmit: () => void;
  selectedProjects: ProgramProjectFieldsFragment[];
};

export default function getProjectSelectionStep(props: Props): DialogFlowStep {
  const selectedProjectCount = props.selectedProjects.length;
  return {
    title: 'New Program',
    renderContent: () => (
      <ProjectSelectionContent
        onChange={props.onChange}
        selectedProjects={props.selectedProjects}
      />
    ),
    renderFooterRight: (args) => {
      const disabled = selectedProjectCount < 2;
      return (
        <div className="flex items-center gap-4">
          {selectedProjectCount < 2 && (
            <div>Select at least 2 Projects you are an Admin role in</div>
          )}
          <Button
            disabled={disabled}
            label={`Select ${selectedProjectCount} and add Details`}
            onClick={() => {
              props.onSubmit();
              args.onNext();
            }}
            type="primary"
          />
        </div>
      );
    },
  };
}

type ProjectSelectionContentProps = {
  selectedProjects: ProgramProjectFieldsFragment[];
  onChange: (newProjectIDs: UUID[]) => void;
};

function ProjectSelectionContent(props: ProjectSelectionContentProps) {
  return (
    <ProjectSelection
      disableIfNotAdmin
      onChange={(newProjectIDs) => props.onChange(newProjectIDs)}
      selectedProjectIDs={props.selectedProjects.map((p) => p.id)}
      selectionMode="multiple"
    />
  );
}
