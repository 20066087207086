// Constants of known feature flags. These should directly reflect those in scalar/featureflag.go

export const PROJECT_COMPS = 'PROJECT_COMPS';
export const FORECASTED_COST_REPORT = 'FORECASTED_COST_REPORT';
export const YC_TIMELINE_WITHOUT_DUE = 'YC_TIMELINE_WITHOUT_DUE';
export const YC_INSIGHTS_V2 = 'YC_INSIGHTS_V2';
export const YC_DEMO_MOCKS_INSIGHTS_V2 = 'YC_DEMO_MOCKS_INSIGHTS_V2';
export const YC_COST_TRENDLINE_V2 = 'YC_COST_TRENDLINE_V2';
export const CT_ITEM_ASSIST_EXPERIMENT = 'CT_ITEM_ASSIST_EXPERIMENT';
export const CT_BRAND_ASSIST_EXPERIMENT = 'CT_BRAND_ASSIST_EXPERIMENT';
export const YC_ITEM_ESTIMATE_DELETION = 'YC_ITEM_ESTIMATE_DELETION';
export const NS_MSR_FOLLOWING_ACTIVE_MILESTONE = 'NS_MSR_FOLLOWING_ACTIVE_MILESTONE';
export const NS_REMOVE_S2_ITEM_MARKUP_REFS = 'NS_REMOVE_S2_ITEM_MARKUP_REFS';
export const NS_ITEM_ESTIMATE_HISTORY = 'NS_ITEM_ESTIMATE_HISTORY';
export const NS_PROGRAM_REPORTING = 'NS_PROGRAM_REPORTING';
export const DD_TIMELINE_ACTIVITIES_NUDGE = 'DD_TIMELINE_ACTIVITIES_NUDGE';
export const DD_PROJECT_IDEAS = 'DD_PROJECT_IDEAS';
