import * as d3 from 'd3';
import { FC, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Typography } from '@material-ui/core';

import theme from '../../../../theme/komodo-mui-theme';
import { formatCost } from '../../../../utilities/currency';
import { renderPercentString } from '../../../../utilities/string';

const VERTICAL_CURVE_WIDTH = 35;
const VERTICAL_CURVE_SLOPE = 25;

const calculateDrawPercentage = (usedAndDrawn: number, starting: number): string => {
  const percentage = Math.round(((starting - usedAndDrawn) * 100) / starting);
  return starting ? renderPercentString({ value: percentage }) : '0%';
};

const getGraphNumbers = (
  starting: number,
  used: number,
  drawn: number,
  pending: number,
  overdrawLimit: number
) => {
  // the "used" and "drawn" sections of the graph will be, at most, the size of the "starting" amount
  // everything after that will be shown as "overdrawn"
  const usedAmount = Math.min(starting, used);
  const drawnAmount = Math.min(starting - usedAmount, drawn);
  let overdrawnAmount = 0;
  let remainingAmount = 0;
  // we're going to draw the "pending" bar in two sections, because we need different colors
  // when it becomes overdrawn
  let pendingInsideRemainingAmount = 0;
  let pendingOutsideRemainingAmount = 0;

  const usedAndDrawn = used + drawn;
  if (usedAndDrawn > starting) {
    overdrawnAmount = usedAndDrawn - starting;
    pendingOutsideRemainingAmount = pending;
  } else if (usedAndDrawn + pending > starting) {
    pendingOutsideRemainingAmount = usedAndDrawn + pending - starting;
    pendingInsideRemainingAmount = pending - pendingOutsideRemainingAmount;
  } else {
    remainingAmount = starting - usedAndDrawn - pending;
    pendingInsideRemainingAmount = pending;
  }

  const drawPercentage = calculateDrawPercentage(usedAndDrawn, starting);

  // the width of the bar without any overdrawn pieces
  const innerBarAmount = usedAmount + drawnAmount + pendingInsideRemainingAmount + remainingAmount;
  // the overdrawn parts that extend past that
  const outerBarAmount = overdrawnAmount + pendingOutsideRemainingAmount;

  const overdrawRatio = outerBarAmount / innerBarAmount;
  // we want to cut off the width of the bar graph once the overdraws go past the visual limit
  const isOverdrawnMoreThanLimit = overdrawRatio > overdrawLimit;
  const graphScale = isOverdrawnMoreThanLimit ? 1 + overdrawLimit : 1 + overdrawRatio;
  const totalGraphAmount = graphScale * innerBarAmount;
  return {
    totalGraphAmount,
    usedAmount,
    drawnAmount,
    overdrawnAmount,
    remainingAmount,
    pendingInsideRemainingAmount,
    pendingOutsideRemainingAmount,
    drawPercentage,
    graphScale,
    isOverdrawnMoreThanLimit,
  };
};

type ContingencyBarProps = {
  starting: number;
  used: number;
  drawn: number;
  pending: number;
  isInsights?: boolean;
  isOrangeTheme?: boolean;
  isVertical?: boolean;
  overdrawLimit?: number;
};

const ContingencyBar: FC<ContingencyBarProps> = ({
  starting,
  used,
  drawn,
  pending,
  isInsights = false,
  isOrangeTheme = false,
  isVertical = false,
  overdrawLimit = 0.25,
}) => {
  const graphRef = useRef<HTMLDivElement>(null);

  let graphWidth = isVertical ? 240 : 180;
  const graphHeight = isVertical ? 100 : 24;
  if (isInsights) {
    graphWidth = 90;
  }

  const chartTheme = {
    dark: isOrangeTheme
      ? 'var(--colors-entities-allowances)'
      : 'var(--colors-entities-contingencies)',
    light: isOrangeTheme
      ? 'var(--colors-entities-allowances-pending)'
      : 'var(--colors-entities-contingencies-pending)',
  };

  const {
    totalGraphAmount,
    drawnAmount,
    usedAmount,
    overdrawnAmount,
    remainingAmount,
    pendingInsideRemainingAmount,
    pendingOutsideRemainingAmount,
    drawPercentage,
    graphScale,
    isOverdrawnMoreThanLimit,
  } = getGraphNumbers(starting, used, drawn, pending, overdrawLimit);

  const uniqueId = `diagonalHatch-${uuidv4()}`;

  useEffect(() => {
    if (!graphRef.current) return;

    const elementWidth = graphRef.current?.clientWidth ?? 0;
    const elementHeight = graphRef.current?.clientHeight ?? 0;
    const svg = d3
      .select(graphRef.current)
      .selectAll('svg')
      .data([null]) // Use enter/update pattern to avoid creating duplicate SVGs
      .join('svg')
      .attr('width', isVertical ? graphWidth : elementWidth)
      .attr('height', isVertical ? elementHeight : graphHeight)
      .append('g');

    const scale = d3
      .scaleLinear()
      .domain([0, totalGraphAmount])
      .rangeRound([0, isVertical ? elementHeight : elementWidth]);

    // Draw gray bar for the "used" amount
    svg
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', isVertical ? graphWidth : scale(usedAmount))
      .attr('height', isVertical ? scale(usedAmount) : graphHeight)
      .attr('fill', theme.palette.backgroundGrey);

    // Draw light blue bar for the "drawn" amount
    svg
      .append('rect')
      .attr('x', isVertical ? 0 : scale(usedAmount))
      .attr('y', isVertical ? scale(usedAmount) : 0)
      .attr('width', isVertical ? graphWidth : scale(drawnAmount))
      .attr('height', isVertical ? scale(drawnAmount) : graphHeight)
      .attr('fill', chartTheme.light);

    // Draw the overdrawn width
    if (overdrawnAmount) {
      svg
        .append('rect')
        .attr('x', isVertical ? graphWidth / 2 : scale(usedAmount + drawnAmount))
        .attr('y', isVertical ? scale(usedAmount + drawnAmount) : 0)
        .attr('width', isVertical ? graphWidth / 2 : scale(overdrawnAmount))
        .attr('height', isVertical ? scale(overdrawnAmount) : graphHeight)
        .attr('fill', theme.palette.chartBrightRed);
    }

    // Draw inside pending bar with a diagonal pattern of dark blue and light blue
    const defs = svg.append('defs');
    if (pendingInsideRemainingAmount) {
      // Append the pattern to defs
      const pattern = defs
        .append('pattern')
        .attr('id', uniqueId)
        .attr('patternUnits', 'userSpaceOnUse')
        .attr('width', 4)
        .attr('height', 4)
        .attr('patternTransform', 'rotate(90)');

      pattern
        .append('path')
        .attr('d', 'M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2')
        .attr('stroke', chartTheme.dark)
        .attr('stroke-width', 2);

      svg
        .append('rect')
        .attr('x', isVertical ? 0 : scale(usedAmount + drawnAmount))
        .attr('y', isVertical ? scale(usedAmount + drawnAmount) : 0)
        .attr('width', isVertical ? graphWidth : scale(pendingInsideRemainingAmount))
        .attr('height', isVertical ? scale(pendingInsideRemainingAmount) : graphHeight)
        .attr('fill', chartTheme.light);

      svg
        .append('rect')
        .attr('x', isVertical ? 0 : scale(usedAmount + drawnAmount))
        .attr('y', isVertical ? scale(usedAmount + drawnAmount) : 0)
        .attr('width', isVertical ? graphWidth : scale(pendingInsideRemainingAmount))
        .attr('height', isVertical ? scale(pendingInsideRemainingAmount) : graphHeight)
        .attr('fill', `url(#${uniqueId})`);
    }

    // Draw dark blue bar for the remaining amount
    if (remainingAmount) {
      svg
        .append('rect')
        .attr('x', isVertical ? 0 : scale(usedAmount + drawnAmount + pendingInsideRemainingAmount))
        .attr('y', isVertical ? scale(usedAmount + drawnAmount + pendingInsideRemainingAmount) : 0)
        .attr('width', isVertical ? graphWidth : scale(remainingAmount))
        .attr('height', isVertical ? scale(remainingAmount) : graphHeight)
        .attr('fill', chartTheme.dark);
    }

    // Draw outside pending bar with a diagonal pattern of red and white
    if (pendingOutsideRemainingAmount) {
      const secondPattern = defs
        .append('pattern')
        .attr('id', 'redHatch')
        .attr('patternUnits', 'userSpaceOnUse')
        .attr('width', 4)
        .attr('height', 4)
        .attr('patternTransform', 'rotate(90)');

      secondPattern
        .append('path')
        .attr('d', 'M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2')
        .attr('stroke', theme.palette.chartBrightRed)
        .attr('stroke-width', 1);

      svg
        .append('rect')
        .attr(
          'x',
          isVertical
            ? graphWidth / 2
            : scale(usedAmount + drawnAmount + overdrawnAmount + pendingInsideRemainingAmount)
        )
        .attr(
          'y',
          isVertical
            ? scale(usedAmount + drawnAmount + overdrawnAmount + pendingInsideRemainingAmount)
            : 0
        )
        .attr('width', isVertical ? graphWidth : scale(pendingOutsideRemainingAmount))
        .attr('height', isVertical ? scale(pendingOutsideRemainingAmount) : graphHeight)
        .attr('fill', 'white');
      svg
        .append('rect')
        .attr(
          'x',
          isVertical
            ? graphWidth / 2
            : scale(usedAmount + drawnAmount + overdrawnAmount + pendingInsideRemainingAmount)
        )
        .attr(
          'y',
          isVertical
            ? scale(usedAmount + drawnAmount + overdrawnAmount + pendingInsideRemainingAmount)
            : 0
        )
        .attr('width', isVertical ? graphWidth : scale(pendingOutsideRemainingAmount))
        .attr('height', isVertical ? scale(pendingOutsideRemainingAmount) : graphHeight)
        .attr('fill', 'url(#redHatch)');
    }

    if (isVertical) {
      const isOverdrawn = !!(overdrawnAmount || pendingOutsideRemainingAmount);
      // Draw a curved blue rectangle covering the left side of the graph
      const rectBottomX = graphWidth / 2;
      const rectBottomY =
        scale(usedAmount + drawnAmount + pendingInsideRemainingAmount) + (isOverdrawn ? 0 : 1); // add an extra pixel (if not overdrawn) to make sure there's no gap below
      const curveStartX = graphWidth / 2 - VERTICAL_CURVE_WIDTH;
      const curveStartY = scale(usedAmount);
      const curveEndX = graphWidth / 2;
      // if we're overdrawn, we want the curve's midpoint to be the bottom of the graph
      const curveEndY = isOverdrawn
        ? rectBottomY
        : scale(usedAmount + (drawnAmount + pendingInsideRemainingAmount) / 2);
      svg
        .append('path')
        .attr(
          'd',
          `M 0,${rectBottomY} L 0,${scale(usedAmount)} L ${curveStartX},${curveStartY} C ${
            curveStartX + VERTICAL_CURVE_SLOPE
          },${curveStartY} ${curveEndX},${curveEndY} ${curveEndX},${curveEndY} L ${rectBottomX},${rectBottomY} Z`
        )
        .attr('fill', chartTheme.dark);

      // Draw a red bar at the bottom to show that we're going past the limit
      if (isOverdrawnMoreThanLimit) {
        svg
          .append('rect')
          .attr('x', graphWidth / 2)
          .attr('y', totalGraphAmount - 1)
          .attr('width', graphWidth / 2)
          .attr('height', 2)
          .attr('fill', theme.palette.chartBrightRed);
      }

      // Draw a shape on the right side of the graph to complete the curve
      const secondCurveStartX = graphWidth / 2;
      const secondCurveStartY = curveEndY;
      const secondCurveEndX = graphWidth / 2 + VERTICAL_CURVE_WIDTH;
      // if we're overdrawn, this graph will extend from the bottom of the graph, and be white to mask part of the overdrawn portion
      const secondCurveEndY = isOverdrawn
        ? totalGraphAmount
        : scale(usedAmount + drawnAmount + pendingInsideRemainingAmount) + 1; // add an extra pixel to make sure there's no gap below
      svg
        .append('path')
        .attr(
          'd',
          `M ${secondCurveStartX},${secondCurveStartY} C ${secondCurveStartX},${secondCurveStartY} ${
            secondCurveEndX - VERTICAL_CURVE_SLOPE
          },${secondCurveEndY} ${secondCurveEndX},${secondCurveEndY}L ${secondCurveStartX},${secondCurveEndY} L ${secondCurveStartX},${secondCurveStartY} Z`
        )
        .attr('fill', isOverdrawn ? theme.palette.backgroundWhite : chartTheme.dark);
    }
  }, [
    graphHeight,
    graphWidth,
    usedAmount,
    drawnAmount,
    overdrawnAmount,
    pendingInsideRemainingAmount,
    pendingOutsideRemainingAmount,
    remainingAmount,
    totalGraphAmount,
    isVertical,
    isOverdrawnMoreThanLimit,
    chartTheme.dark,
    chartTheme.light,
    uniqueId,
  ]);

  if (starting - used < 0) {
    return (
      <div
        className={`${
          isVertical ? 'mt-8' : ''
        } flex items-center justify-center text-type-inactive`}
      >
        Negative Value
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: isVertical || isInsights ? 'column' : 'row',
        alignItems: 'center',
        width: isVertical ? graphWidth : undefined,
        position: 'relative',
        gap: '2px',
      }}
    >
      {isInsights && (
        <div className="flex w-full justify-between">
          <div className="type-body3">Remaining</div>
          <div className="type-body3">{drawPercentage}</div>
        </div>
      )}
      <div
        key={`${starting},${pending},${used},${drawn}`} // this key forces the graph to re-render when props change, which is useful for storybook
        ref={graphRef}
        style={{
          width: isVertical ? graphWidth : graphScale * graphWidth,
          height: isVertical ? graphScale * graphHeight : graphHeight,
          overflow: 'hidden',
          ...(isVertical
            ? {
                borderTopLeftRadius: '4px',
                borderTopRightRadius: '4px',
                ...(!isOverdrawnMoreThanLimit && {
                  borderBottomLeftRadius: '4px',
                  borderBottomRightRadius: '4px',
                }),
              }
            : {
                borderTopLeftRadius: '4px',
                borderBottomLeftRadius: '4px',
                ...(!isOverdrawnMoreThanLimit && {
                  borderTopRightRadius: '4px',
                  borderBottomRightRadius: '4px',
                }),
              }),
        }}
      />
      {isOverdrawnMoreThanLimit && (
        <div
          style={{
            ...(isVertical
              ? {
                  width: 4,
                  position: 'relative',
                  top: '-1px',
                  right: '-4px',
                  alignSelf: 'end',
                  borderTop: `1px solid ${theme.palette.chartBrightRed}`,
                }
              : {
                  height: graphHeight + 4,
                  borderLeft: `1px solid ${theme.palette.chartBrightRed}`,
                }),
          }}
        />
      )}
      {!isInsights && (
        <Typography
          style={{
            marginLeft: 8,
            fontWeight: 400,
            color: !isVertical && overdrawnAmount ? theme.palette.chartBrightRed : undefined,
            ...(isVertical
              ? {
                  background: theme.palette.backgroundWhite,
                  borderRadius: '4px',
                  position: 'absolute',
                  right: '4px',
                  top: '4px',
                  fontSize: 12,
                  padding: '2px 4px',
                }
              : {}),
          }}
        >
          {`${drawPercentage}${isVertical ? ' Remaining' : ''}`}
        </Typography>
      )}
      {isInsights && (
        <div className="flex w-full items-center justify-end text-type-muted type-body3">
          <div className="type-body3">{formatCost(remainingAmount, { short: true })}</div>/
          <div className="type-body3">{formatCost(totalGraphAmount, { short: true })}</div>
        </div>
      )}
    </div>
  );
};

export default ContingencyBar;
