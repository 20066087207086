import { cloneDeep } from 'lodash';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { ErrorOutline } from '@material-ui/icons';

import { COMPANY_DASHBOARD } from '../../constants';
import {
  InsightsProjectsBreakdownItem,
  InsightsSortKey,
  SearchResultType,
  SortDirection,
} from '../../generated/graphql';
import { useProjectDeliveryTypes } from '../../hooks/useProjectDeliveryTypesQuery';
import { usePersistentStates } from '../../utilities/urlState';
import useOrganizationsQuery from '../CompanyTab/CompanyTabOrganizations/hooks/useOrganizationsQuery';
import { useCompanyTabID } from '../CompanyTab/CompanyTabUtils';
import { useDesignPhaseTypes } from '../Milestone/hooks/useDesignPhaseTypesQuery';
import { ProjectIcon } from '../Nav/icons';
import useSearchProjectsFilterOptionsQuery from '../ProjectsList/hooks/useSearchProjectsFilterOptionsQuery';
import { useFilterProjects } from '../ProjectsList/ProjectsListUtils';
import { Tooltip } from '../scales';
import useMemoWrapper from '../useMemoWrapper';

import DesignPhaseStackedBarChart, { StackDataItem } from './Charts/DesignPhaseStackedBarChart';
import CompanySelector from './CompanySelector';
import InsightsFilterMenu from './FilterMenu/InsightsFilterMenu';
import { useInsightsProjectsQuery } from './hooks/useInsightsProjectsQuery';
import { useInsightsV2ProjectCountsQuery } from './hooks/useInsightsV2ProjectCountsQuery';
import InsightsListCountsVolBar, { HeaderDisplayBy } from './InsightsListHeaderPieBar';
import InsightsProjectDetails from './InsightsProjectDetails';
import InsightsTabs from './InsightsTabs';
import HeaderDonutCharts from './PieCharts/HeaderDonutCharts';
import ProjectListData from './ProjectsListData';
import ProjectsSummaryStackedTooltip from './ToolTips/ProjectsSummaryStackedTooltip';
import { InsightsTabId } from './types';

const InsightsProjectsList = () => {
  const { projectId } = useParams();
  const [selectedTabID, setSelectedTabID] = useState(InsightsTabId.Costs);

  // Company data
  const companyID = useCompanyTabID();
  // Sorting
  const [sortState, setSortState] = useState({
    sortKey: InsightsSortKey.NAME,
    sortDirection: SortDirection.SORT_DESCENDING,
  });
  const setSort = (sortBy: InsightsSortKey) => {
    const sortDirection = InsightsSortKey.LAST_UPDATED
      ? SortDirection.SORT_DESCENDING
      : SortDirection.SORT_ASCENDING;
    setSortState({ sortKey: sortBy, sortDirection });
  };
  // Organizations
  const organizationsQueryResult = useOrganizationsQuery(companyID);
  const orgs = organizationsQueryResult.data?.organizations;

  // Filtering
  const [settings, setSettings] = usePersistentStates(
    window.location,
    '',
    {
      deliveryMethods: [],
      estimateCostRange: {
        max: null,
        min: null,
      },
      gsfRange: {
        max: null,
        min: null,
      },
      statuses: [],
      types: [],
      projectTypes: [],
      companies: [],
      locations: [],
      orgNodeIDs: [],
      milestoneDesignPhases: [],
      projectLeadIDs: [],
      designPhases: [],
    },
    'Insights Filters - '
  );
  const designPhaseTypes = useDesignPhaseTypes();
  const projectDeliveryTypes = useProjectDeliveryTypes();
  const filterManager = useFilterProjects(settings);
  const { data: filterOptionsData } = useSearchProjectsFilterOptionsQuery(SearchResultType.ALL);

  if (filterOptionsData && designPhaseTypes && projectDeliveryTypes) {
    const intialFilterOptions: SearchProjectsFilterOptions = cloneDeep(
      filterOptionsData.searchProjectsFilterOptions
    );
    intialFilterOptions.gsfRange = {
      max: null,
      min: null,
    };
    intialFilterOptions.estimateCostRange = {
      max: null,
      min: null,
    };
    intialFilterOptions.designPhases = designPhaseTypes.map((d) => d.name) || [];
    intialFilterOptions.deliveryMethods = projectDeliveryTypes.map((d) => d.name) || [];
    filterManager.filterOptions = intialFilterOptions;
    if (filterManager.filterOptions)
      filterManager.filterOptions.types =
        filterOptionsData.searchProjectsFilterOptions.projectTypes || [];
  }
  const insightsInput = {
    companyID: companyID || '',
    filter: {
      deliveryTypes: filterManager.filterState?.deliveryMethods || [],
      gsfRange:
        typeof filterManager.filterState.gsfRange === 'string'
          ? {
              max: null,
              min: null,
            }
          : filterManager.filterState.gsfRange,
      statuses: filterManager.filterState?.statuses || [],
      types:
        filterOptionsData?.searchProjectsFilterOptions.projectTypes
          .filter((t) => filterManager.filterState?.types?.includes(t.id))
          .map((t) => t.name) || [],
      companies: filterManager.filterState?.companies || [],
      estimateCostRange:
        typeof filterManager.filterState.estimateCostRange === 'string'
          ? {
              max: null,
              min: null,
            }
          : filterManager.filterState.estimateCostRange,
      locations: filterManager.filterState?.locations || [],
      orgNodeIDs: filterManager.filterState?.orgNodeIDs || [],
      milestoneDesignPhases: filterManager.filterState?.designPhases || [],
      projectLeadIDs: filterManager.filterState?.projectLeadIDs || [],
    },
    sort: sortState,
  };
  const projectCounts = useInsightsV2ProjectCountsQuery(insightsInput) || {
    filteredProjects: 0,
    totalProjects: 0,
    totalAlerts: 0,
    projectsWithAlerts: 0,
    organizationBreakdowns: [],
    projectLeadsBreakdown: [],
    projectTypesBreakdown: [],
    designPhasesBreakdown: [],
  };
  const { data: insightsProjects, fetchMore } = useInsightsProjectsQuery({
    input: insightsInput,
    pagination: {
      offset: 0,
      limit: 10,
    },
  });

  // Header pie charts display selector
  const [selectedDisplayByOption, setSelectedDisplayByOption] = useState<HeaderDisplayBy>(
    HeaderDisplayBy.COUNT
  );
  const handleOptionChange = (option: HeaderDisplayBy) => {
    setSelectedDisplayByOption(option);
  };

  // Generate design phase chart input data
  const designPhaseChartData = useMemoWrapper(
    generateDesignPhaseChartInput,
    projectCounts.designPhasesBreakdown
  );

  if (projectId) {
    return (
      /** TODO: Make sure that we use InsightsProjectRoute with Outlet */
      <InsightsProjectDetails project={insightsProjects?.find(({ id }) => id === projectId)} />
    );
  }

  return (
    <div className="flex h-full max-w-full grow flex-col gap-2">
      <div>
        <div className="flex items-center justify-between px-5 py-4">
          <div className="flex type-heading1">{COMPANY_DASHBOARD}</div>
          <div className="ml-auto flex">
            <InsightsFilterMenu filterManager={filterManager} setSettings={setSettings} />
          </div>
          <CompanySelector />
        </div>

        <div className="flex items-center justify-end gap-1 px-5">
          <div className="type-body1">Display by</div>
          <InsightsListCountsVolBar
            onOptionChange={handleOptionChange}
            selectedOption={selectedDisplayByOption}
          />
        </div>

        <div className="flex h-36 flex-row justify-between px-5 py-4">
          <div className="flex flex-col">
            <div className="type-label">Alerts</div>
            <div className="flex items-center gap-1">
              <ErrorOutline color="error" style={{ width: 20, height: 20 }} />
              <div className="text-type-error type-heading3">
                {projectCounts.totalAlerts} alerts
              </div>
            </div>
            <div className="flex items-center gap-1">
              <ProjectIcon />
              <div className="type-heading3">
                {projectCounts.projectsWithAlerts} project with alerts
              </div>
            </div>
          </div>

          <div className="flex flex-row items-center gap-8">
            {/* Header Design Phases Stacked Chart */}
            <div className="flex flex-col">
              <Tooltip
                content={
                  <div>
                    <ProjectsSummaryStackedTooltip stackData={designPhaseChartData} />
                  </div>
                }
              >
                <div>
                  <DesignPhaseStackedBarChart data={designPhaseChartData} />
                </div>
              </Tooltip>
            </div>
            {/* //Header Donut Charts */}
            <HeaderDonutCharts
              orgs={orgs || []}
              projectCounts={projectCounts || []}
              selectedDisplayBy={selectedDisplayByOption}
            />
          </div>
        </div>
      </div>

      <div className="mx-3 flex flex-col justify-stretch">
        <InsightsTabs
          onSortByChange={setSort}
          onTabChange={setSelectedTabID}
          selectedSortBy={sortState.sortKey}
          selectedTabID={selectedTabID}
        />
      </div>

      <div className="mx-3 flex-1 overflow-y-auto">
        <ProjectListData
          insightsProjects={insightsProjects || []}
          onFetchMore={fetchMore}
          projectCounts={{
            current: projectCounts.filteredProjects,
            total: projectCounts.totalProjects,
          }}
          tabId={selectedTabID}
        />
      </div>
    </div>
  );
};

export default InsightsProjectsList;

const generateDesignPhaseChartInput = (
  designPhaseBreakdown: InsightsProjectsBreakdownItem[]
): StackDataItem[] => {
  const dataItems = designPhaseBreakdown.map((bi) => {
    return {
      category: bi.label,
      values: [
        { name: 'projectsWithAlerts', value: bi.countWithAlerts },
        { name: 'projectsCount', value: bi.count },
      ],
    };
  });
  return dataItems;
};
