import Help from '@material-ui/icons/Help';

import { BabyButton, Tooltip } from '../../scales';

type Props = {
  tooltipContent: React.ReactNode;
};

export default function HelpTooltip(props: Props) {
  return (
    <Tooltip
      content={<div className="type-label">{props.tooltipContent}</div>}
      isDisabled={!props.tooltipContent}
      placement="right"
    >
      <BabyButton aria-label="Info" disabled icon={<Help />} />
    </Tooltip>
  );
}
