import { gql } from '../../../api/graphqlFragments';

export const insightsProjectIDsQuery = gql`
  query Insights($input: InsightsInput!, $pagination: Pagination!) {
    insights(input: $input, pagination: $pagination) {
      id
    }
  }
`;

export const insightsV2ProjectCountsQuery = gql`
  query InsightsV2ProjectCounts($input: InsightsInput!) {
    insightsV2ProjectCounts(input: $input) {
      totalProjects
      filteredProjects
      projectsWithAlerts
      totalAlerts
      projectTypesBreakdown {
        label
        count
        countWithAlerts
        volume
        volumeWithAlerts
      }
      designPhasesBreakdown {
        label
        count
        countWithAlerts
        volume
        volumeWithAlerts
      }
      projectLeadsBreakdown {
        label
        count
        countWithAlerts
        volume
        volumeWithAlerts
      }
      organizationBreakdowns {
        organizationID
        breakdown {
          label
          count
          countWithAlerts
          volume
          volumeWithAlerts
        }
      }
    }
  }
`;

export const insightsProjectsQuery = gql`
  query InsightsProjects($input: InsightsInput!, $pagination: Pagination!) {
    insights(input: $input, pagination: $pagination) {
      id
      name
      projectLeadName: projectLead
      type: projectType
      location
      status
      lastUpdated
      lastUpdatedBy
      deliveryType
      milestoneDesignPhase
      milestoneGSF
      runningTotal
      budget
      estimate
      milestones {
        id
        name
        date
        active
        designPhase
      }
      orgNodes {
        id
        name
      }
      costTimeseries {
        date
        budget
        estimate
        runningTotal
        pendingMin
        pendingMax
      }
      contingenciesTimeseries {
        date
        allowanceRemaining
        allowancePending
        allowanceTotal
        allowanceDraw
        allowanceNumDraws
        contingencyRemaining
        contingencyPending
        contingencyTotal
        contingencyDraw
        contingencyNumDraws
      }
      pendingItemsPastDue
      pendingItemsDueInFuture
      pendingItemsMissingDueDates
      pastDueItems
      dueNext14DaysItems
      hasAccess
      thumbnail
      demoData
    }
  }
`;
