import { useState } from 'react';

import { CircularProgress } from '@material-ui/core';
import { Add, OpenInNew } from '@material-ui/icons';

import { ToastType } from '../../../../api/gqlEnums';
import { UserSourcesQuery } from '../../../../generated/graphql';
import useItemIntegrationObjectsQuery from '../../../../hooks/useItemIntegrationObjectsQuery';
import { setToast } from '../../../../hooks/useToastParametersLocalQuery';
import { useProjectID } from '../../../../utilities/routes/params';
import { useLoadSourceHubProjects } from '../../../DocumentMagic/FileExplorer/hooks/FileExplorerHooks';
import NormalTooltip from '../../../NormalTooltip/NormalTooltip';
import { Button } from '../../../scales';

import { useGetSourceLinkedProjectQuery } from './hooks/useGetSourceLinkedProjectQuery';
import IntegrationsCreateChangeEventModal from './IntegrationsCreateItemIntegrationObjectModal';
import {
  ItemInfo,
  UserSource,
  getItemIntegrationBaseURL,
  getItemIntegrationObjectTypeBySourceSystem,
  getItemIntegrationObjectsBySourceSystem,
  getSourceSystemIcon,
  getSourceSystemString,
} from './IntegrationsUtils';
import ItemIntegrationObjectsContent from './ItemIntegrationObjectsContent';

type Props = {
  canCreateChangeEvent: boolean;
  canDeleteItemIntegration: boolean;
  isPrint?: boolean;
  itemInfo: ItemInfo;
  onEditAccount: (userSource: UserSource) => void;
  userSource: UserSourcesQuery['userSources']['sources'][number];
};

export default function IntegrationsSourceContent(props: Props) {
  const projectID = useProjectID();
  const [isCreatingItemIntegrationObject, setIsCreatingItemIntegrationObject] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const linkedProject = useGetSourceLinkedProjectQuery(
    projectID ?? '',
    props.userSource.sourceSystem,
    props.userSource.id,
    props.userSource.email ?? ''
  ).data?.sourceLinkedProject;

  const sourceHubProjects = useLoadSourceHubProjects(
    props.userSource.sourceSystem,
    props.userSource.id,
    linkedProject?.sourceCompanyID
  );
  const matchingProject = sourceHubProjects.data?.sourceHubProjects.projects.find(
    (p) => p.id === linkedProject?.sourceProjectID
  );

  const itemIntegrationObjectsData = useItemIntegrationObjectsQuery(
    {
      sourceSystem: props.userSource?.sourceSystem,
      sourceID: props.userSource?.id || '',
      projectID: projectID || '',
      itemID: props.itemInfo.id,
    },
    (data) => {
      if (data?.itemIntegrationObjects?.error)
        setToast({ message: data.itemIntegrationObjects.error }, ToastType.SERVER_ERROR);
    }
  );

  const hasLinkedIntegrationObject = !!getItemIntegrationObjectsBySourceSystem(
    itemIntegrationObjectsData.data?.itemIntegrationObjects,
    props.userSource?.sourceSystem
  )?.length;

  const pageLoadTime = itemIntegrationObjectsData?.data?.itemIntegrationObjects?.refetchedAt
    ? new Date(itemIntegrationObjectsData?.data?.itemIntegrationObjects?.refetchedAt)
    : undefined;

  const isLoadingItemIntegrationObjects = submitted;

  const itemIntegrationObjectType = getItemIntegrationObjectTypeBySourceSystem(
    props.userSource?.sourceSystem
  );

  if (!props.userSource) return <></>;

  let tooltip = '';
  if (!props.canCreateChangeEvent) {
    tooltip = `Change Item status to Accepted to create a ${itemIntegrationObjectType}`;
  } else if (linkedProject && !linkedProject.canCreateItemIntegrationObject) {
    tooltip = `Cannot create a ${itemIntegrationObjectType} for this linked project`;
  }

  if (props.isPrint && !hasLinkedIntegrationObject) return <></>;

  return (
    <div>
      <div
        className={`flex items-center justify-between gap-4 p-4 ${props.isPrint ? '' : 'border'}`}
      >
        <div className="flex items-center gap-4">
          {getSourceSystemIcon(props.userSource?.sourceSystem)}
          <div className="flex flex-col gap-1">
            <div className="type-body3">
              {getSourceSystemString(props.userSource?.sourceSystem)}
            </div>
            <div className="type-body1">{props.userSource?.email}</div>
          </div>
        </div>
        {!props.isPrint &&
          (matchingProject ? (
            <a
              className="flex type-link"
              href={getItemIntegrationBaseURL(props.userSource.sourceSystem, matchingProject?.id)}
              rel="noreferrer"
              target="_blank"
            >
              <div className="flex items-center gap-1">
                {matchingProject.name}
                <div className="text-base type-link">
                  <OpenInNew className="mb-[1px]" fontSize="inherit" />
                </div>
              </div>
            </a>
          ) : (
            <Button
              label="Connect a Project"
              onClick={() => props.onEditAccount(props.userSource)}
              startIcon={<Add />}
              type="secondary"
            />
          ))}
      </div>
      <div className={`${props.isPrint ? '' : 'border border-t-0 p-4 '}`}>
        {isLoadingItemIntegrationObjects && (
          <div className="flex h-36 w-full items-center justify-items-center">
            <div className="ml-auto mr-auto">
              <CircularProgress style={{ height: 20, width: 20 }} />
            </div>
          </div>
        )}
        {hasLinkedIntegrationObject && !isLoadingItemIntegrationObjects ? (
          <ItemIntegrationObjectsContent
            canDeleteItemIntegration={props.canDeleteItemIntegration}
            itemID={props.itemInfo.id}
            itemIntegrationObjects={getItemIntegrationObjectsBySourceSystem(
              itemIntegrationObjectsData.data?.itemIntegrationObjects,
              props.userSource?.sourceSystem
            )}
            linkedProjectID={linkedProject?.sourceProjectID}
            pageLoadTime={pageLoadTime}
            refetchChangeEvents={() => itemIntegrationObjectsData.refetch()}
            setSubmitted={setSubmitted}
            sourceID={props.userSource.id}
            sourceSystem={props.userSource?.sourceSystem}
          />
        ) : (
          <NormalTooltip placement="bottom-start" title={tooltip}>
            <div>
              <Button
                disabled={
                  !props.canCreateChangeEvent ||
                  !linkedProject ||
                  itemIntegrationObjectsData.loading ||
                  !linkedProject.canCreateItemIntegrationObject
                }
                label={itemIntegrationObjectType}
                onClick={() => setIsCreatingItemIntegrationObject(true)}
                startIcon={<Add />}
                type="secondary"
              />
            </div>
          </NormalTooltip>
        )}
      </div>
      {linkedProject && props.userSource && (
        <IntegrationsCreateChangeEventModal
          isCreatingItemIntegrationObject={isCreatingItemIntegrationObject}
          itemInfo={props.itemInfo}
          savedSourceCompanyID={linkedProject.sourceCompanyID}
          savedSourceProjectID={linkedProject.sourceProjectID}
          setIsCreatingItemIntegrationObject={setIsCreatingItemIntegrationObject}
          setSubmitted={setSubmitted}
          sourceSystem={props.userSource.sourceSystem}
          userSourceID={props.userSource.id}
        />
      )}
    </div>
  );
}
