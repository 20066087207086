import {
  CostReportColumnType,
  StatusEventsQuery,
  StatusEventsQueryVariables,
} from '../../../../generated/graphql';
import { useQuery } from '../../../../hooks/useMountAwareQuery';
import { useCostMode } from '../../../../utilities/costMode';

import { statusEventsQuery } from './queries';

const dashboardColumnTypes: CostReportColumnType[] = [
  CostReportColumnType.ACCEPTED_REPORT,
  CostReportColumnType.ESTIMATE_REPORT,
  CostReportColumnType.TARGET_REPORT,
  CostReportColumnType.RUNNINGTOTAL_REPORT,
  CostReportColumnType.REMAINING_REPORT,
];
const columnInputs: CostReportColumnInput[] = dashboardColumnTypes.map((type) => ({
  type,
}));

export function useItemStatusEventsQuery(
  projectID: UUID | undefined,
  milestoneID: UUID | undefined
) {
  return useQuery<StatusEventsQuery, StatusEventsQueryVariables>(statusEventsQuery, {
    variables: {
      projectID,
      viewFilter: {},
      // costMode should always be bind to the same project ID as in variables
      costMode: useCostMode(projectID),
      milestoneID,
      columnInputs,
      quantityMagnitude: undefined,
    },
    errorPolicy: 'all',
    skip: !milestoneID || !projectID,
  });
}
