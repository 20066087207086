import { CostReportColumnType } from '../../../../generated/graphql';
import { getCostReportByType } from '../../../CostReport/CostReportUtils';
import { useMilestoneCostReportsQuery } from '../../../Milestone/hooks';

export default function useSummaryCostReport(projectID?: UUID, milestoneID?: UUID) {
  // Uses milestoneCostReportsQuery to get the cost reports for the summary.
  // This is setup included in the REFETCH_NAV refetch set, so update that if
  // the underlying query here is changed.
  const costReports = useMilestoneCostReportsQuery(milestoneID, projectID, {}, COST_REPORT_COLUMNS)
    .data?.milestoneCostReports[0];

  const accepted = getCostReportByType(costReports, CostReportColumnType.ACCEPTED_REPORT);
  const estimate = getCostReportByType(costReports, CostReportColumnType.ESTIMATE_REPORT);
  const remaining = getCostReportByType(costReports, CostReportColumnType.REMAINING_REPORT);
  const running = getCostReportByType(costReports, CostReportColumnType.RUNNINGTOTAL_REPORT);
  const target = getCostReportByType(costReports, CostReportColumnType.TARGET_REPORT);

  return {
    accepted: accepted?.range,
    estimate: estimate?.range,
    gap: remaining?.range,
    running: running?.range,
    target: target?.range,
  };
}

const COST_REPORT_COLUMNS = [
  CostReportColumnType.ACCEPTED_REPORT,
  CostReportColumnType.ESTIMATE_REPORT,
  CostReportColumnType.REMAINING_REPORT,
  CostReportColumnType.RUNNINGTOTAL_REPORT,
  CostReportColumnType.TARGET_REPORT,
].map((type) => ({ type }));
