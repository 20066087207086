import { BusinessOutlined } from '@material-ui/icons';

import { LeadEvent, LeadKey, leadEvent } from '../../../analytics/analyticsEventProperties';
import {
  CostRangeInput,
  DateRangeInput,
  ForecastingProjectsFilters as ForecastingProjectsFiltersType,
  OrgNode,
} from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { TreeEntry } from '../../../utilities/utilities';
import ExpandableFiltersSection from '../common/ExpandableFiltersSection';

import CostRangeFilters from './CostRangeFilters';
import DateRangeFilters from './DateRangeFilters';
import FilterGroup from './FilterGroup';
import FilterGroupLevels from './FilterGroupLevels';
import { ListFilterOption } from './FilterGroupUtils';
import ProjectTypeFilter from './ProjectTypeFilter';

export type FilterOptions = {
  companies: ListFilterOption[];
  locations: ListFilterOption[];
  milestoneDesignPhases: ListFilterOption[];
  projectLeads: ListFilterOption[];
  statuses: ListFilterOption[];
  types: {
    label: string;
    hidden?: boolean | null;
    id: string;
    parentID?: string | null;
  }[];
  organizationNodes: TreeEntry<OrgNode>[];
};

type Props = {
  filterOptions: FilterOptions;
  filterValues: ForecastingProjectsFiltersType;
  isExpanded: boolean;
  onChangeFilters: (
    filterName: keyof ForecastingProjectsFiltersType,
    filterValue: string[] | CostRangeInput | DateRangeInput
  ) => void;
  onClickSectionName?: () => void;
};

const ProjectsFilters = (props: Props) => {
  const sendAnalytics = useSendAnalytics();
  return (
    <ExpandableFiltersSection
      icon={<BusinessOutlined />}
      isExpanded={props.isExpanded}
      setIsExpanded={props.onClickSectionName}
      title="Project Filters"
    >
      <FilterGroup
        data-cy="projectLocation-FilterGroup"
        entries={props.filterOptions.locations}
        isAllSelectable
        isSearchable
        onChange={(value) => props.onChangeFilters('locations', value)}
        searchPlaceholder="Search locations"
        selectedEntries={props.filterValues.locations}
        title="Project Location"
      />
      <ProjectTypeFilter
        data-cy="projectType-FilterGroup"
        entries={props.filterOptions.types}
        isAllSelectable
        isSearchable
        onChange={(value) => {
          props.onChangeFilters('types', value);
          props.onChangeFilters('projectTypes', value);
        }}
        searchPlaceholder="Search types"
        selectedEntries={props.filterValues.types}
        title="Project Type"
      />
      <FilterGroup
        data-cy="projectStatus-FilterGroup"
        entries={props.filterOptions.statuses}
        isAllSelectable
        onChange={(value) => props.onChangeFilters('statuses', value)}
        selectedEntries={props.filterValues.statuses}
        title="Status"
      />
      <FilterGroup
        data-cy="projectLead-FilterGroup"
        entries={props.filterOptions.projectLeads}
        isAllSelectable
        onChange={(value) => props.onChangeFilters('projectLeadIDs', value)}
        onExpand={() => sendAnalytics(leadEvent(LeadKey.PROJECTS, LeadEvent.FilterClick))}
        selectedEntries={props.filterValues.projectLeadIDs ?? []}
        title="Project Lead"
      />
      <FilterGroupLevels
        data-cy="organization-FilterGroup"
        entries={props.filterOptions.organizationNodes}
        isAllSelectable
        isSearchable
        onChange={(value) => {
          props.onChangeFilters('orgNodeIDs', value);
        }}
        searchPlaceholder="Search organizations"
        selectedEntries={props.filterValues.orgNodeIDs ?? []}
        title="Organization"
      />
      <FilterGroup
        data-cy="milestoneDesignPhase-FilterGroup"
        description="Active Milestone"
        entries={props.filterOptions.milestoneDesignPhases}
        isAllSelectable
        onChange={(value) => props.onChangeFilters('milestoneDesignPhases', value)}
        selectedEntries={props.filterValues.milestoneDesignPhases || []}
        title="Design Phase"
      />
      <CostRangeFilters
        data-cy="projectEstimate-CostRange"
        description="Active Milestone"
        onChange={(value: CostRangeInput) => props.onChangeFilters('estimateCostRange', value)}
        title="Estimate Cost"
        value={props.filterValues.estimateCostRange}
      />
      <DateRangeFilters
        data-cy="projectMilestone-DateRange"
        onChange={(value) => props.onChangeFilters('milestoneDateRange', value)}
        title="Milestone Date"
        value={props.filterValues.milestoneDateRange}
      />
      <FilterGroup
        data-cy="projectCompanies-FilterGroup"
        entries={props.filterOptions.companies}
        isAllSelectable
        isSearchable
        onChange={(value) => props.onChangeFilters('companies', value)}
        selectedEntries={props.filterValues.companies}
        title="Companies"
      />
    </ExpandableFiltersSection>
  );
};

export default ProjectsFilters;
