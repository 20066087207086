import { useParams } from 'react-router-dom';
import { isUUID } from 'validator';

export default function IdeaRoute() {
  const { projectId, ideaID } = useParams();
  if (!projectId || !isUUID(projectId)) {
    throw new Error('Failed to get projectId param');
  } else if (!ideaID || !isUUID(ideaID)) {
    throw new Error('Failed to get ideaID param');
  }

  return <div className="h-full w-full bg-type-error" />;
}
