import { useState } from 'react';

import { Info } from '@material-ui/icons';

import {
  CompanyAdminEventType,
  companyAdminAnalyticsEvent,
} from '../../../../analytics/analyticsEventProperties';
import { ProjectType } from '../../../../generated/graphql';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { Button, Callout, Dialog, DialogContent, TextInput } from '../../../scales';
import { useUpdateProjectTypeMutation } from '../../CompanyHooks';

import { AlreadyExistText } from './CreateProjectTypeDialog';
import { hasName } from './UpdateProjectSubtypeDialog';

export type Props = {
  isOpen?: boolean;
  onClose?: () => void;
  type: ProjectType;
  types?: ProjectType[];
};

export default function UpdateProjectTypeDialog(props: Props) {
  const [name, setName] = useState(props.type.name);
  const [errorMsg, setErrorMsg] = useState<string | undefined>();
  const sendAnalytics = useSendAnalytics();

  const [updateProjectType, { loading }] = useUpdateProjectTypeMutation({
    onCompleted: () => {
      if (errorMsg) setErrorMsg(undefined);
      sendAnalytics(
        companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_TYPES_EDIT_TYPE_SAVE, {
          typeName: name,
        })
      );
      props.onClose?.();
    },
    onError: () => {
      setErrorMsg('Something went wrong');
    },
  });
  const disabled = Boolean(!name || errorMsg || loading);

  const onClose = () => {
    sendAnalytics(
      companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_TYPES_EDIT_TYPE_CLOSE_CANCEL)
    );
    props.onClose?.();
  };

  const onUpdate = () => {
    updateProjectType(props.type.id, name, props.type.parentID);
  };

  const onChange = (newValue: string) => {
    if (errorMsg) setErrorMsg(undefined);
    const isAlreadyExist = hasName(props.types, props.type.id, newValue);
    if (isAlreadyExist) setErrorMsg(AlreadyExistText);
    setName(newValue);
  };
  return (
    <Dialog
      {...props}
      footerRight={<Button disabled={disabled} label="Save" onClick={onUpdate} type="primary" />}
      isNotDismissable={loading}
      onClose={onClose}
      title="Edit Project Type"
    >
      <DialogContent className="flex flex-col gap-2">
        <TextInput
          errorMessage={errorMsg}
          label="Project Type Name *"
          onChange={onChange}
          placeholder="Project Type Name"
          value={name}
        />
        <Callout fullWidth>
          <Info className="mb-0.5" fontSize="inherit" />
          <div className="inline pb-1 pl-1 type-label">
            Editing the Project Type Name will update all existing Projects using this name.
          </div>
        </Callout>
      </DialogContent>
    </Dialog>
  );
}
