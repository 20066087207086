import { ContingenciesTimeSeries } from './types';

// Top line remaining. Bottom line remaining - pending
export const getAreaDataCont = (data: ContingenciesTimeSeries[]) =>
  data.map((d) => ({
    x: d.date,
    y0: d.contingencyRemaining - d.contingencyPending,
    y: d.contingencyRemaining,
  }));

// Top line remaining. Bottom line remaining - pending
export const getAreaDataAllow = (data: ContingenciesTimeSeries[]) =>
  data.map((d) => ({
    x: d.date,
    y0: d.allowanceRemaining - d.allowancePending,
    y: d.allowanceRemaining,
  }));

export const getYDomain = (
  data: ContingenciesTimeSeries[],
  hasAllowance: boolean,
  hasContingency: boolean
): [number, number] => {
  // Helper function to extract values
  const extractFieldsC = () => {
    return data
      .map((d) => [
        d.contingencyRemaining,
        d.contingencyRemaining - d.contingencyPending,
        d.contingencyTotal,
      ])
      .flat();
  };
  // Helper function to extract values
  const extractFieldsA = () => {
    return data
      .map((d) => [
        d.allowanceRemaining,
        d.allowanceRemaining - d.allowancePending,
        d.allowanceTotal,
      ])
      .flat();
  };
  let common: number[] = [];
  if (hasContingency) {
    common = [...common, ...extractFieldsC()];
  }
  if (hasAllowance) {
    common = [...common, ...extractFieldsA()];
  }
  return [Math.min(...common) ?? 0, Math.max(...common) ?? 0];
};
