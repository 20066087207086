import { gql } from '@apollo/client';

import { programFields, programProjectFields } from '../../api/graphqlFragments';

export const programEligibleProjectsQuery = gql`
  query programEligibleProjects {
    programEligibleProjects {
      ...programProjectFields
    }
  }
  ${programProjectFields}
`;

export const programQuery = gql`
  query program($programID: UUID!) {
    program(programID: $programID) {
      ...programFields
    }
  }
  ${programFields}
`;
