import { extent } from 'd3-array';

import { DesignPhaseEnum } from '../../api/gqlEnums';
import { EventType, InsightsMilestone, Status, StatusEventsQuery } from '../../generated/graphql';
import { setAllDayRange } from '../../utilities/dates';
import { isNonNullable } from '../../utilities/types';
import {
  ItemDueState,
  TimelineItemData,
} from '../shared-widgets/TimelineChart/timeline/timeline.types';

export type StatusEvent = StatusEventsQuery['costTrendlineEvents']['events'][number];

export const getDueDateState = (item: ItemsTimelineItem, todayDate: Date) => {
  const dueDate = new Date(item.dueDate || '0');
  let status = ItemDueState.Upcoming;
  if (dueDate < todayDate && item.status === Status.PENDING) {
    status = ItemDueState.PastDue;
  } else if (
    item.status === Status.ACCEPTED ||
    item.status === Status.REJECTED ||
    item.status === Status.INCORPORATED ||
    item.status === Status.NOTAPPLICABLE
  ) {
    status = ItemDueState.Decided;
  }
  return status;
};

export const getDueDateStateNoDue = (event: StatusEvent | undefined) => {
  let status;
  if (event?.timestamp) {
    const { oldStatus, newStatus } = event.eventContent;
    // Past Bars
    if (
      // pending to anything
      oldStatus === Status.PENDING ||
      // accepted to rejected
      (oldStatus === Status.ACCEPTED && newStatus === Status.REJECTED) ||
      // rejected to accepted
      (oldStatus === Status.REJECTED && newStatus === Status.ACCEPTED)
    ) {
      status = ItemDueState.Decided;
    }

    // accepted to incorporated
    if (oldStatus === Status.ACCEPTED && newStatus === Status.INCORPORATED) {
      // no count
      status = null;
    }
  }
  return status;
};

export const computeItemDecidedDate = (
  list: ItemsTimelineItem[],
  today: string,
  events: EventsTimelineEvent[]
): TimelineItemData[] => {
  // Filter by CHANGE_STATUS event type
  const statusChangeEvents = events
    .filter(({ eventType }) => eventType === EventType.CHANGE_STATUS)
    // Recent events first, descending order
    .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
  const todayDate = new Date(today);
  todayDate.setDate(todayDate.getDate() - 1);
  // Since we are comparing dueDate < todayDate, it is important to set todayDate to the end of the day.
  setAllDayRange([new Date(today), todayDate]);

  const result = list
    .map((item: ItemsTimelineItem) => {
      const event = statusChangeEvents.find((event) => event.item?.id === item.id);
      // We are interested in the most recent event
      // Due state for items without due date
      const dueState = item.dueDate
        ? getDueDateState(item, todayDate)
        : getDueDateStateNoDue(event);
      if (dueState === null || dueState === undefined) return undefined;
      const useDueDate = dueState === ItemDueState.PastDue || dueState === ItemDueState.Upcoming;
      let dueDate = useDueDate ? item.dueDate || event?.timestamp : event?.timestamp;

      // When the event is null, set the due date to the created date for decided items
      if (!dueDate && dueState === ItemDueState.Decided && !event) {
        dueDate = item.createdAt;
      }

      if (!dueDate) return undefined;
      return {
        id: item.id,
        name: item.name,
        dueDate,
        dueState,
      };
    })
    .filter(isNonNullable);
  // The array of non null TimelineItemData
  return result;
};

type TimeDate = {
  date: Date;
};

/** Moves an array of time series to today's date */
export function fixMocksToday<T extends TimeDate>(data: T[], delta: number) {
  return data.map((d) => ({ ...d, date: new Date(d.date.getTime() + delta) }));
}

export function getRange<T extends TimeDate>(data: T[]): [Date, Date] {
  const set = data.map((d) => d.date);
  const dateRange = extent(set);
  return dateRange as [Date, Date];
}

type StartEnd = {
  startDate: string;
  endDate?: string | null;
};

/** Moves an array of time series to today's date */
export function fixMocksStartEnd<T extends StartEnd>(data: T[], delta: number) {
  return data.map((d) => {
    const obj = {
      ...d,
      startDate: new Date(new Date(d.startDate).getTime() + delta).toISOString(),
    };
    if (!d.endDate) return obj;
    return {
      ...obj,
      endDate: new Date(new Date(d.endDate).getTime() + delta).toISOString(),
    };
  });
}

export function isGmpInsightsMilestone(m: InsightsMilestone) {
  // Cast to string since we are comparing with backend provided value which is string
  const gmp = DesignPhaseEnum['Guaranteed Maximum Price'] as unknown as string;
  return m.designPhase === gmp;
}
