import * as d3 from 'd3';
import { ScaleLinear, ScalePoint, ScaleTime } from 'd3-scale';

import useMemoWrapper from '../../useMemoWrapper';

type TimeDate = {
  [key in string]: Date;
};

type Value = {
  [key in string]: number;
};

// Default key fields:
const DATE_KEY = 'x';
const VALUE_MIN_KEY = 'y0';
const VALUE_MAX_KEY = 'y';

export default function TimelineArea<V extends Value, T extends TimeDate>(props: {
  data: (V & T)[];
  fieldDate?: string;
  fieldMin?: string;
  fieldMax?: string;
  fill: string;
  hasSteps?: boolean;
  x: ScaleTime<number, number> | ScalePoint<Date>;
  y: ScaleLinear<number, number>;
  strokeWidth?: number;
  stroke?: string;
}) {
  const area =
    useMemoWrapper(
      getAreaCoords,
      props.data,
      props.x,
      props.y,
      props.fieldDate ?? DATE_KEY,
      props.fieldMin ?? VALUE_MIN_KEY,
      props.fieldMax ?? VALUE_MAX_KEY,
      props.hasSteps
    ) ?? undefined;
  if (!props.data) return null;

  return (
    <path
      className={props.stroke}
      d={area}
      data-cy="path-area"
      fill={props.fill}
      stroke={props.stroke}
      strokeWidth={props.strokeWidth ?? 1}
    />
  );
}

function getAreaCoords(
  data: (TimeDate & Value)[],
  x: ScaleTime<number, number> | ScalePoint<Date>,
  y: ScaleLinear<number, number>,
  fieldDate: string,
  fieldMin: string,
  fieldMax: string,
  hasSteps: boolean | undefined
) {
  const area = d3.area<TimeDate & Value>();
  if (hasSteps) area.curve(d3.curveStepAfter);
  return area
    .x((d) => x(d[fieldDate]) ?? 0)
    .y0((d) => y(d[fieldMin]))
    .y1((d) => y(d[fieldMax]))(data);
}
