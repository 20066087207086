import { useState } from 'react';

import { useProgramQuery } from './hooks/useProgramQuery';
import ProgramDashboard from './ProgramDashboard';

type Props = {
  programID: UUID;
};

export default function ProgramDashboardData({ programID }: Props) {
  const [selectedProjectIDs, setSelectedProjectIDs] = useState<UUID[]>([]);

  const { data } = useProgramQuery(programID, {
    onCompleted: () => {
      setSelectedProjectIDs(data?.program?.projects?.map((p) => p.id) ?? []);
    },
  });
  const program = data?.program;

  return (
    <ProgramDashboard
      program={program}
      selectedProjectIDs={selectedProjectIDs}
      setSelectedProjectIDs={setSelectedProjectIDs}
    />
  );
}
