import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import {
  INSIGHTS_DEFAULT_MAX_ITEMS_BAR,
  INSIGHTS_DEFAULT_TIME,
  insightsMaxItemsBar,
  insightsTimeDomain,
} from '../../../api/apollo/reactiveVars';
import { ForecastingProjectsSortKey } from '../../../generated/graphql';
import { RouteKeys } from '../../../routes/paths';
import { getTodayUtcNoon } from '../../../utilities/dates';
import { generateSharedPath } from '../../../utilities/routes/links';
import { formatNumber } from '../../../utilities/string';
import ChartsPieGraph from '../../Charts/ChartsD3/ChartsPieGraph/ChartsPieGraph';
import ContingencyBar from '../../ContingencyReport/ContingencyReport/BarGraph/ContingencyReportBarGraph';
import { Checkbox, SortManager, Table, Tooltip } from '../../scales';
import ZoomTimeline from '../Charts/ZoomTimeline';
import { InsightsProject } from '../hooks/useInsightsProjectsQuery';
import ProjectContingenciesAndAllowances from '../ProjectBabyCharts/ProjectContingenciesAndAllowances';
import ContingenciesAllowanceBarToolTip from '../ToolTips/ContingenciesAllowanceBarToolTip';
import ContingenciesPieToolTip from '../ToolTips/ContingenciesPieToolTip';
import ProjectTableTooltip from '../ToolTips/ProjectTableTooltip';
import { InsightsTabId } from '../types';

import ProjectTableEntry from './ProjectTableEntry';
import StickyBottomRow from './StickyBottomRow';
import { getProjectsRange } from './utils';

type Props = {
  counts: { current: number; total: number };
  loading: boolean;
  onFetchMore: () => void;
  projects: InsightsProject[];
  sortManager: SortManager;
};

const ProjectsContingenciesAndAllowances: FC<Props> = (props) => {
  useEffectOnce(() => () => {
    insightsTimeDomain(INSIGHTS_DEFAULT_TIME);
    insightsMaxItemsBar(INSIGHTS_DEFAULT_MAX_ITEMS_BAR);
  });
  const navigate = useNavigate();
  const [startDate, endDate] = getProjectsRange(getTodayUtcNoon());
  const [startStr, setStartStr] = useState(startDate.toISOString());
  const [endStr, setEndStr] = useState(endDate.toISOString());
  const setStartEnd = ([start, end]: string[]) => {
    setStartStr(start);
    setEndStr(end);
  };
  const onZoom = (domain: string[]) => {
    setStartEnd(domain);
  };

  const stickyFooterEntry = StickyBottomRow({
    columnsCount: 5,
    component: <ZoomTimeline onZoom={onZoom} projects={props.projects} zoom={[startStr, endStr]} />,
  });

  const [hasContingency, setHasContingency] = useState(true);
  const [hasAllowance, setHasAllowances] = useState(true);
  const chartsHeader = (
    <div className="ml-auto flex gap-2">
      <Checkbox
        isSelected={hasContingency}
        onChange={() => {
          // never have a situation where both checkboxes are unchecked
          if (hasContingency && !hasAllowance) {
            setHasAllowances(!hasAllowance);
          }
          setHasContingency(!hasContingency);
        }}
      >
        <div>Contingencies</div>
      </Checkbox>
      <Checkbox
        isSelected={hasAllowance}
        onChange={() => {
          // never have a situation where both checkboxes are unchecked
          if (hasAllowance && !hasContingency) {
            setHasContingency(!hasContingency);
          }
          setHasAllowances(!hasAllowance);
        }}
      >
        <div>Allowances</div>
      </Checkbox>
    </div>
  );

  return (
    <>
      <Table
        columnWidths={[
          'minmax(250px, 3fr)',
          'minmax(125px, 0.5fr)',
          'minmax(125px, 0.5fr)',
          'minmax(125px, 0.5fr)',
          'minmax(600px, 6fr)',
        ]}
        entries={props.projects.map((project) => {
          const key = project.id;
          const to = {
            pathname: generateSharedPath(RouteKeys.INSIGHTS_PROJECT, { projectId: project.id }),
            hash: `#${InsightsTabId.Contingencies}`,
          };
          const mostRecentData =
            project.contingenciesTimeseries.length > 0
              ? project.contingenciesTimeseries[project.contingenciesTimeseries.length - 1]
              : null;

          const pieChartData = [];
          let caTotalPct = 0.0;
          if (mostRecentData?.allowanceTotal && project.estimate) {
            pieChartData.push({
              name: 'Allowance',
              share: mostRecentData.allowanceTotal / project.estimate,
            });
            caTotalPct += mostRecentData.allowanceTotal / project.estimate;
          }

          if (mostRecentData?.contingencyTotal && project.estimate) {
            pieChartData.push({
              name: 'Contingency',
              share: mostRecentData.contingencyTotal / project.estimate,
            });
            caTotalPct += mostRecentData.contingencyTotal / project.estimate;
          }
          pieChartData.push({
            name: 'Remaining',
            share: 1.0 - caTotalPct,
          });
          return [
            {
              key,
              component: (
                <Tooltip
                  content={
                    <div>
                      <ProjectTableTooltip project={project} tabType={InsightsTabId.Items} />
                    </div>
                  }
                >
                  <div>
                    <ProjectTableEntry hasAlert={Math.random() < 0.5} project={project} to={to} />
                  </div>
                </Tooltip>
              ),
            },
            {
              key,
              centered: true,
              component: (
                <Tooltip
                  content={
                    <div>
                      <ContingenciesPieToolTip
                        currentContingencyData={mostRecentData}
                        estimateTotal={project.estimate || 1}
                      />
                    </div>
                  }
                >
                  <div>
                    <ChartsPieGraph
                      centerLabel={{
                        label: '',
                        sublabel: `${String(Math.floor(caTotalPct))}%`,
                      }}
                      chartSize={{
                        diameter: 60,
                        insideDiameter: 35,
                      }}
                      colors={[
                        'var(--colors-theme-bauhaus-marcelbreuer)',
                        'var(--colors-theme-bauhaus-laszlo)',
                        'var(--colors-item-status-not-applicable-tint)',
                      ]}
                      data={pieChartData}
                    />
                  </div>
                </Tooltip>
              ),
            },
            {
              key,
              centered: true,
              component: (
                <Tooltip
                  content={
                    <div>
                      <ContingenciesAllowanceBarToolTip type="Contingency" />
                    </div>
                  }
                  isDisabled
                >
                  <div>
                    <ContingencyBar
                      drawn={0}
                      isInsights
                      overdrawLimit={0.15}
                      pending={mostRecentData?.contingencyPending || 0}
                      starting={mostRecentData?.contingencyTotal || 0}
                      used={mostRecentData?.contingencyDraw || 0}
                    />
                  </div>
                </Tooltip>
              ),
            },
            {
              key,
              centered: true,
              component: (
                <Tooltip
                  content={
                    <div>
                      <ContingenciesAllowanceBarToolTip type="Allowance" />
                    </div>
                  }
                  isDisabled
                >
                  <div>
                    <ContingencyBar
                      drawn={0}
                      isInsights
                      isOrangeTheme
                      overdrawLimit={0.15}
                      pending={mostRecentData?.allowancePending || 0}
                      starting={mostRecentData?.allowanceTotal || 0}
                      used={mostRecentData?.allowanceDraw || 0}
                    />
                  </div>
                </Tooltip>
              ),
            },
            {
              key,
              component: (
                <ProjectContingenciesAndAllowances
                  endStr={endStr}
                  hasAllowance={hasAllowance}
                  hasContingency={hasContingency}
                  onClick={() => navigate(to)}
                  project={project}
                  startStr={startStr}
                />
              ),
            },
          ];
        })}
        headerContent={[
          {
            component: <div className="h-9" />,
            copy: `Projects (${formatNumber(props.counts.current)} of ${formatNumber(
              props.counts.total
            )})`,
            key: 'projects',
            headerSortKey: ForecastingProjectsSortKey.NAME,
          },
          {
            copy: '% of Est',
            key: 'est',
          },
          {
            copy: 'Contingencies',
            key: 'cont',
          },
          { copy: 'Allowances', key: 'all' },
          { component: chartsHeader, key: 'ca' },
        ]}
        loading={props.loading}
        onNeedMoreData={props.onFetchMore}
        sortManager={props.sortManager}
        stickyFooterEntry={stickyFooterEntry}
      />
    </>
  );
};

export default ProjectsContingenciesAndAllowances;
