import { ForecastingProjectsFilters } from '../../../../generated/graphql';

export const activeFiltersCountProjects = (filters: ForecastingProjectsFilters) => {
  let count = 0;
  const filtersKeys = Object.keys(filters);
  filtersKeys.forEach((key: string) => {
    if (key === 'milestoneDateRange') {
      const values = filters[key];
      if (values.start || values.end) {
        count += 1;
      }
    } else if (key === 'estimateCostRange') {
      const values = filters[key];
      if (!!values.min || !!values.max) {
        count += 1;
      }
    } else {
      const values = filters[key as keyof ForecastingProjectsFilters];
      count += (values as string[])?.length ?? 0;
    }
  });
  return count;
};
