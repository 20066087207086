import * as React from 'react';

import { FieldGroup } from '../../api/gqlEnums';
import {
  EstimateArmatureQueryResult,
  EstimateTotalType,
  InputMaybe,
  ItemEstimateInput,
  MarkupDisplayType,
  SortDirection,
} from '../../generated/graphql';

import { Orderable } from './utilities/reorder';

export type GridController = {
  // BASELINE DATA --------------------------------------------------
  projectID?: UUID;
  estimateID?: UUID;
  linePrefix: string;
  footer?: Footer;
  linesReadOnly: boolean; // This property should only control grid behavior, eg is something editable, and not grid styling.
  isInherited: boolean;
  isItem: boolean;
  columnsReadOnly: boolean;
  canViewDirectCosts: boolean;
  hasMarkupCheckboxButton: boolean;
  canUpdateInheritedMarkupRefs: boolean;
  hasRemoveS2ItemMarkupFeature: boolean;
  isSummary?: boolean;
  isPrinting: boolean;
  data: GridData;
  quantity?: QuantityInfo;
  s1RefShouldIncludeS2?: boolean;

  numRows: () => number;
  numCols: () => number;

  // INTERACTION DATA -----------------------------------------------
  isRenderingEditor: KeyBufferState;
  addKeyToBuffer: (position: Position, key: string) => void;
  getKeyBufferString: (position: Position) => string;

  // RETRIGGER RENDERS -----------------------------------------------
  setUpdateTable: (update: () => void) => void;
  updateTable: () => void;
  setRowUpdater: (i: number, update: () => void) => void;
  updateRow: (i: number) => void;
  setUpdateHeader: (update: () => void) => void;
  updateHeader: () => void;

  // SIZING UTILITIES ------------------------------------------------
  colWidths: () => number[];
  getRowTotals: () => number[];
  getRowHeights: () => number[];
  // Rows that are fully (not fractionally) visible to the user
  visibleRows: Range<number>;
  // Rows that are rendered in the DOM (visible + fractional + buffer)
  renderedRows: Range<number>;
  visibleWidth: () => number;
  overallWidth: () => number;
  maxHeight(): number;
  // this is the height of the estimate scroll container
  setMaxHeight: (n: number) => void;
  setOverallWidth: (width: number, immediate?: boolean) => void;
  isReorderable: boolean;
  isFiltering: boolean;
  isSorting: boolean;
  sortEnabled: boolean;
  scrollBarWidth: () => number;
  setBodyRef: (bodyRef: React.RefObject<HTMLDivElement>) => void;
  bodyRef: () => React.RefObject<HTMLDivElement> | undefined;

  // RESIZING & REORDERING  ------------------------------------------
  //  Tell the grid we're about to be manipulating the column sizes,
  //  and to not bother resizing row heights in accordance with widths
  //  until we're done.
  startResizingColumn: (i: number) => void;
  //  Propagate a re-render with new widths, albeit not new heights.
  resizeColumns: (widths: number[], scrollGrid?: boolean) => void;
  //  Tell the grid we're done, and to perform all of its potentially
  //  expensive layout utilities if necessary.
  stopResizingColumn: (i: number) => void;
  //  Incremented when we want to be sure to re-render.
  getRenderGeneration: () => number;
  //  Tell the grid that we're reordering rows.
  isReorderingRow: (row: number) => boolean;
  startReorderingRow: (row: number) => void;
  //  Shift the view in cellData, does NOT make an API call.
  //  Prev here was the last 'selected' row, which does not
  //  necessarily equal the original row we started dragging.
  shiftRow: (prev: number, next: number) => void;
  //  Post the API call, update all the ordering states
  finishReordering: (original: number, next: number) => void;
  scrollToTop: () => void;
  scrollToBottom: () => void;
  scrollToRow: (i: number) => void;
  scrollToColumn: (j: number) => void;
  moveFocus: (to: Position) => void;

  // DATA MUTATION & CLIPBOARDING -----------------------------------
  // Can this cell be edited (by this user)
  isCellEditable: (cell: Position) => boolean;
  // Is this cell of the type that can be activated (edited) by pressing enter?
  isCellActivatable: (cell: Position) => boolean;
  copyToClipboard: (start: Position, end: Position) => void;
  pasteClipboard: (start: Position) => void;
  mutateData: (start: Position, end: Position, values: (GridCellValue | undefined)[][]) => void;
  toggleMarkupWithoutS2Reference: (index: number, withoutS2Reference: boolean) => void;
  replaceCategory: (field: UUID, oldValue?: string, newValue?: UUID) => void;
  undoLastMutation: () => void;
  addLine: (method: CreationMethod, onSuccess?: () => void) => void;
  deleteLine: (index: number) => void;
  deleteLines: () => void;
  toggleInheritedItemMarkupLine: (index: number) => void;
  toggleAllocatedMarkupLine: (index: number) => void;
  addColumns: (fieldInputs: FieldInput[]) => void;
  removeColumns: (columnIDs: UUID[]) => void;
  moveColumn: (currentIndex: number, futureIndex: number) => void;
  setTotalType: (variant: EstimateTotalType) => void;
  // PAGINATION
  fetchMore: (varsObj: PaginationInput, cb?: () => void) => void;
  getLinesCallParams?: () => LinesCallParams | undefined;
  setLinesCallParams: (p: LinesCallParams) => void;
  populateMetricCategorizations: (categories: GridCategoryCellInputs[][]) => void;

  // SELECTION SETTING ----------------------------------------------
  selection: { start: Position; end: Position };
  isRowSelectedArr: boolean[];
  numSelectedRows: number;
  previouslySelectedRow: number;
  isSelecting: () => boolean;
  setSelecting: (b: boolean) => void;
  setSelectionRange: (start: Position, end: Position) => void;
  setSelectionRangeEnd: (end: Position) => void;
  selectRow: (rowIndex: number) => void;
  selectCell: (rowIndex: number, columnIndex: number) => void;

  // Errors
  closeErrorHeaders: () => void;

  // HELPERS ---------------------------------------------------------
  getCellPosition: (p: Position) => EditorPosition | undefined;
  getCellData: (i: number, j: number) => CellData | undefined;
  getCellValue: (i: number, j: number) => GridCellValue | undefined;
  variant?: GridVariant;
  type?: GridType;
  totalType?: EstimateTotalType;
  refetch?: () => void;
};

export type EstimateGridPermissions = {
  canEditMarkups: boolean;
  canEditOwnerCosts: boolean;
  canViewEstimate: boolean;
  canViewEstimateCostSubtotals: boolean;
  canViewMarkups: boolean;
  canViewMarkupDetails: boolean;
  canViewOwnerCosts: boolean;
  canEditLines: boolean;
  canEditColumns: boolean;
  summaryMarkups: boolean;
};

export interface JoinGridWrapperProps {
  t: TermStore;
  costOfConstruction: number;
  estimateID?: UUID;
  hasOwnerCostEstimate: boolean;
  gridType: { gridType: string; model: string };
  sortData: GridSortData;
  errors?: ImportEstimateError[];
  refetchOuter: () => void;
  projectID: UUID;
  milestoneID?: UUID;
  itemID?: UUID;
  includeDraws?: boolean;
  enabledCategorizationsIDs: UUID[];
  hasRemoveS2ItemMarkupFeature: boolean;
  permissions: EstimateGridPermissions;
  analytics: GridAnalytics;
  quantity?: QuantityInfo;
  updateCostReports: () => void;
  collapseSizeRef: React.RefObject<HTMLDivElement>;
  width?: number;
  clearFilters?: () => void;
  variant: GridVariant;
  viewFilter?: ViewFilterInput;
  isItemEstimateView?: boolean;
  isExpanded?: boolean;
  sendRefetch?: boolean;
  subtotals?: EstimateArmatureSubtotals;
}

export type EstimateArmatureSubtotals = Pick<
  NonNullable<NonNullable<EstimateArmatureQueryResult['data']>['estimate']>,
  | 'subtotal'
  | 'markupSubtotal'
  | 'incorporatedDrawsSubtotal'
  | 'incorporatedSubtotal'
  | 'inheritedSubtotal'
>;

export enum GridType {
  ESTIMATE_GRID = 'Estimate',
  INCORPORATED_ITEM_MARKUP_GRID = 'Incorporated Item Markup',
  INCORPORATED_ITEM_DRAWS_GRID = 'Incorporated Item Draws',
  ITEM_DRAWS_GRID = 'Contingency & Allowance Draws',
  INHERITED_GRID = 'Inherited',
  MARKUP_GRID = 'Markup',
  OWNER_COST_GRID = 'Owner Cost',
  INHERITED_OWNER_COST_MARKUP_GRID = 'Inherited Owner Cost Markup',
}
export enum ReferenceDisplay {
  DELETED_ITEM = 'Deleted Item',
  EMPTY_COST = '--',
  NOT_APPLIED = 'Not Applied',
  S1 = 'S1',
  S2 = 'S2',
  S1_S2 = 'S1, S2',
  S3 = 'S3',
  TOTAL = 'TOTAL',
  COST_OF_CONSTRUCTION = 'Cost of Construction',
}

export interface Position {
  row: number;
  column: number;
}

export type Range<T> = { start: T; end: T };
export type Selection = Range<Position>;

export interface EditorPosition {
  top: number;
  left: number;
  width: number;
  height: number;
}

export type Borders = {
  top: boolean;
  left: boolean;
  right: boolean;
  bottom: boolean;
};

export type CellState = {
  value: GridCellValue;
  error: string;
  selected: boolean;
  borders: Borders;
  rangeHead: boolean;
  update: () => void;
};

export interface HeaderError {
  type?: string; // make it optional to avoid conflict with FieldError type covertion
  categoryErrorIndicies?: CategoryErrorIndicies[];
  errorsIndices?: number[];
  errorsMap?: Map<string, number[]>;
  errorsPresence?: boolean[];
  count: number;
  errorsResolutionId?: UUID;
  isNewCategorization?: boolean;
}

export interface CategoryErrorIndicies {
  categoryNumber?: string;
  indicies?: number[];
}

export interface Column {
  divider?: HTMLDivElement;
  type: string;
  name: string;
  group?: string;
  id: string;
  categorization?: CategorizationMetadata | null;
  errors?: HeaderError[];
  isErrorsMode?: boolean;
  hasColumnMenu?: boolean;
  placeholder?: string;
  update?: () => void;
  headerToolTip?: string;
  helpTip?: React.ReactNode;
}

export interface Footer {
  name: string;
  prefix: string;
  type: string;
  data: CellData;
}

export interface GridData {
  columns: Column[];
  lines: GridLine[];
  linesReadOnly: boolean;
  hasMarkupCheckboxButton: boolean;
  canUpdateInheritedMarkupRefs: boolean;
}

export interface ColumnSettings {
  isFirstCategoryColumn: boolean;
  isLastAddableColumn: boolean;
  isLastCategoryColumn: boolean;
  isTotalCell: boolean;
  isTotalCellShadow: boolean;
  isSourceCell: boolean;
  isAllocatedCell: boolean;
}

export interface CellData {
  data: CellState;
  dom: React.RefObject<HTMLDivElement> | null;
}

// Mimics the type in JoinGridWrapper
export type CategorizationGridPermissions = {
  canView: boolean;
  canEdit: boolean;
};

export type IDMapping = { [key: string]: number };

export enum KeyBufferState {
  CLEAR = 0, // Received the input in the editor.
  BUFFERING = 1, // Start buffering, hasn't received input yet
  DISPATCHED = 2, // Never hit the editor, dispatched an update ourselves.
}

export type GridSortData = {
  sortKey: UUID;
  sortDirection: SortDirection;
};

export type PaginationInput = {
  variables: {
    pagination: Pagination;
  };
};

export type LinesCallParams = {
  offset?: number;
  limit?: number;
  time: number;
};

export interface SizingState {
  data: GridData;
  bodyRef?: React.RefObject<HTMLDivElement>;
  widths: number[];
  maxHeight: number;
  heights: number[];
  rowTotals: number[];
  visibleWidth: number;
  overallWidth: number;
  renderGeneration: number;
  measureDiv: HTMLDivElement;
  heightCache: { [key: string]: number };
  isAccordion: boolean;
  isResizingColumn: boolean;
  // Maintain a local 'dirty bit' to decide if we actually need to re-data
  // row heights. For some updates, it won't be necessary. Initialize the heights
  // with a conservative over-data.
  updateRowHeights: boolean;
  updateTable: () => void;
  rowUpdaters: (() => void)[];
  updateRow: (row: number) => void;
}

export interface GridSelectionState {
  data?: GridData;
  cellData: (CellData[] | null)[];
  visibleRows: Range<number>;
  renderedRows: Range<number>;
  selection: Selection;
  isRowSelectedArr: boolean[];
  numSelectedRows: number;
  previouslySelectedRow: number;
  currentlySelecting: boolean;
  isRenderingEditor: KeyBufferState;
  // Keyed by a cache key based on the position tuple stringified,
  // e.g. "1-1", "123,4" etc.
  keyBuffers: { [key: string]: string[] };
}

export type GenericGridState = SizingState &
  GridSelectionState & {
    bodyRef?: React.RefObject<HTMLDivElement>;
    currentlyReorderingRow: number;
    indexMap: IDMapping;
    orderingData: Orderable[];
    isInherited?: boolean;
    isItem: boolean;
    linesReadOnly: boolean;
    // Each array here is one old set of cells. Each edit that takes place
    // stashes the old "Cell" objects right before it changes them. Restoring
    // state is as simple as popping from the stack and asking to API to update
    // back to these values - since they have IDs on them, they'll figure out
    // exactly where to go.
    mutationHistory: IndexedGridCell[][];
    linesCallParams?: LinesCallParams;
    updateCostReports: () => void;
    updateHeader: () => void;
    refetch?: () => void;
    variant?: GridVariant;
    itemEstimateInput?: ItemEstimateInput;
  };

export interface GridButtonData {
  id?: string;
  isAddDisabled?: boolean;
  onAddClick?: () => void;
  onDeleteClick?: () => void;
  onReplaceClick?: () => void;
  tooltip?: string;
  replaceCategorization?: boolean;
}
export type EstimateGridState = GenericGridState & {
  projectID: UUID;
  estimateID: UUID;
  subtotal: CellData;
  analytics: GridAnalytics;
  sortBy: EstimateSortBy | undefined;
  itemEstimateInput?: ItemEstimateInput;
  viewFilter?: ViewFilterInput;
  replaceMarkups: (markups: Markup[], newSubtotal: number) => void;
  updateInheritedMarkupsController: (markups: Markup[], newSubtotal: number) => void;
  updateIncorporatedMarkupsController: (markup: Markup[], newSubtotal: number) => void;
  updateIncorporatedDrawsController: (markup: Markup[], newSubtotal: number) => void;
  updateInheritedOwnerCostMarkupsController: (markup: Markup[], newSubtotal: number) => void;
  refetch?: () => void;
  totalType?: EstimateTotalType;
  quantity?: QuantityInfo;
  variant: GridVariant;
  estimateTerm: string;
};

export type MarkupGridState = GenericGridState & {
  projectID: UUID;
  isInherited: boolean;
  isIncorporated: boolean;
  estimateID?: UUID;
  subtotal: CellData;
  analytics: GridAnalytics;
  viewFilter?: ViewFilterInput;
  hasIncorporatedMarkups?: boolean;
  canUpdateInheritedMarkupRefs: boolean;
  hasSourceColumn?: boolean;
  hasDisplayTypeColumn?: boolean;
  estimateTotalType: EstimateTotalType;
  updateInheritedMarkupsController: (markup: Markup[], newSubtotal: number) => void;
  updateIncorporatedMarkupsController: (markup: Markup[], newSubtotal: number) => void;
  updateIncorporatedDrawsController: (markup: Markup[], newSubtotal: number) => void;
  updateInheritedOwnerCostMarkupsController: (markup: Markup[], newSubtotal: number) => void;
  type: GridType;
};

export const stringAsFieldGroup = (v: string | undefined): FieldGroup | undefined => {
  if (!v) return undefined;
  return Object.values(FieldGroup).find((f) => f.toString() === v);
};

// The GridVariants below should be used to set the styling / sizing of the grid
// it shouldn't be used for permissions or any grid interactions
export enum GridVariant {
  CATEGORIZATION = 'CATEGORIZATION',
  ITEM_DETAILS = 'ITEM_DETAILS',
  ITEM_FULLSCREEN = 'ITEM_FULLSCREEN',
  READ_ONLY = 'READ_ONLY',
  ITEM_TEMPLATE = 'ITEM_TEMPLATE',
  MILESTONE_ESTIMATE = 'MILESTONE_ESTIMATE',
  MILESTONE_BUDGET = 'MILESTONE_BUDGET',
  QUANTITY = 'QUANTITY',
}

export type CreationMethod = 'Button' | 'EnterPress' | 'Paste' | 'Initial';

export type GridAnalytics = {
  addColumnAnalytics: (p: AddColumnProperties) => void;
  createLinesAnalytics: (p: CreateLinesProperties) => void;
  createMarkupAnalytics: (p: CreateMarkupsProperties, gridType: GridType) => void;
  removeLineAnalytics: (p: RemoveLinesProperties) => void;
  removeMarkupAnalytics: (gridType: GridType) => void;
  reorderLineAnalytics: () => void;
  reorderMarkupAnalytics: () => void;
  updateMarkupAnalytics: (p: UpdateMarkupsProperties, gridType: GridType) => void;
  updateCellsAnalytics: (p: EditCellsProperties) => void;
  moveColumnAnalytics: () => void;
  removeColumnAnalytics: (p: RemoveColumnProperties) => void;
  setTotalTypeAnalytics: (p: SetTotalTypeProperties) => void;
  toggleInheritedItemMarkupAnalytics: (p: ToggleInheritedItemMarkupProperties) => void;
  toggleMarkupWithoutS2ReferenceAnalytics: (p: ToggleMarkupWithoutS2ReferenceProperties) => void;
  toggleAllocatedMarkupAnalytics: (p: ToggleAllocatedMarkupProperties, gridType: GridType) => void;
};

export type AddColumnProperties = {
  columns: number; // number of columns after creation
};

export type CreateLinesProperties = {
  createdLines: number; // number of lines that were added
  source: CreationMethod;
};

export type CreateMarkupsProperties = {
  method: CreationMethod;
};

export type RemoveLinesProperties = {
  estimateLength: number; // # of rows after action completed
};

export type UpdateMarkupsProperties = {
  includesCategoryFilters: boolean;
  displayType: MarkupDisplayType | undefined | InputMaybe<MarkupDisplayType>;
  shouldNotApplyCategoryFiltersToOtherMarkups: InputMaybe<boolean> | undefined;
};

export type EditCellsProperties = {
  cells: number; // # of cells touched;
  fields: string[]; // names of the columns that were touched
  includesCategoryCells: boolean;
};

export type RemoveColumnProperties = {
  columns: number; // # of columns after action completed
};

export type SetTotalTypeProperties = {
  newTotalType: string;
  oldTotalType: string;
};

export type ToggleInheritedItemMarkupProperties = {
  status: string;
};

export type ToggleMarkupWithoutS2ReferenceProperties = {
  status: string;
};

export type ToggleAllocatedMarkupProperties = {
  shouldExcludeFromAllocation: boolean;
};
