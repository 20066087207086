import { useState } from 'react';

import {
  CompanyAdminEventType,
  companyAdminAnalyticsEvent,
} from '../../../../analytics/analyticsEventProperties';
import { ProjectType } from '../../../../generated/graphql';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { Button, Dialog, DialogContent, TextInput } from '../../../scales';
import useMemoWrapper from '../../../useMemoWrapper';
import { useCompanyRoutesData } from '../../CompanyTabUtils';
import useCreateProjectTypeMutation from '../ProjectTypes/hooks/useCreateProjectTypeMutation';

import { TYPE_NAME_MAX_LENGTH, filterParentTypes } from './utils';

export const AlreadyExistText = 'Project Type name already exists. Try a different one.';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const hasName = (values: any[] | undefined, value: string) =>
  (values || []).some(({ name }) => name.trim().toLowerCase() === value.trim().toLowerCase());

export type Props = {
  isOpen?: boolean;
  onClose?: () => void;
  types?: ProjectType[];
};

export default function CreateProjectTypeDialog(props: Props) {
  const [name, setName] = useState('');
  const [errorMsg, setErrorMsg] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const { companyID } = useCompanyRoutesData();
  const createProjectType = useCreateProjectTypeMutation();
  const parentProjectTypes = useMemoWrapper(filterParentTypes, props.types || []);
  const sendAnalytics = useSendAnalytics();
  const disabled = Boolean(!name || errorMsg || isLoading);

  const onCreate = () => {
    setIsLoading(true);
    const onSuccess = () => {
      setIsLoading(false);
      setName('');
      setErrorMsg(undefined);
      sendAnalytics(
        companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_TYPES_CREATE_CREATE, {
          typeName: name,
        })
      );
      props.onClose?.();
    };
    const onFail = (error: string | undefined) => {
      setIsLoading(false);
      setErrorMsg(error || 'Something went wrong');
    };
    createProjectType(name, companyID || '', undefined, onSuccess, onFail);
  };

  const onClose = () => {
    sendAnalytics(
      companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_TYPES_CREATE_CLOSE_CANCEL)
    );
    props.onClose?.();
  };

  const onChange = (newValue: string) => {
    if (errorMsg) setErrorMsg(undefined);
    const isAlreadyExist = hasName(parentProjectTypes, newValue);
    if (isAlreadyExist) setErrorMsg(AlreadyExistText);
    setName(newValue.substring(0, TYPE_NAME_MAX_LENGTH));
  };
  return (
    <Dialog
      {...props}
      footerRight={
        <Button
          data-cy="create-button"
          disabled={disabled}
          label="Create"
          onClick={onCreate}
          type="primary"
        />
      }
      isNotDismissable={isLoading}
      onClose={onClose}
      title="Create Project Type"
    >
      <DialogContent className="flex flex-col gap-2">
        <TextInput
          data-cy="project-type-text-input"
          errorMessage={errorMsg}
          label="Project Type Name *"
          onChange={onChange}
          placeholder="Project Type Name"
          value={name}
        />
      </DialogContent>
    </Dialog>
  );
}
