import { FC, useEffect, useMemo } from 'react';

import { useReactiveVar } from '@apollo/client';

import { ItemEvent, itemEvent } from '../../../analytics/analyticsEventProperties';
import { itemDetailsViewStateVar } from '../../../api/apollo/reactiveVars';
import {
  FILTER_COMMENTS,
  FILTER_DRAFT_HISTORY,
  FILTER_ITEM_HISTORY_ALL,
  FILTER_ITEM_HISTORY_NONE,
  ITEM_DETAILS,
  ITEM_HISTORY,
  SHARE_SETTING_CHANGE,
  getFilterSelectEntries,
} from '../../../constants';
import { EventType, PermissionResource, ResourceType } from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { computeFiltersList, computeItemHistoryEvents } from '../../../utilities/itemHistoryEvents';
import { computeItemCostImpact } from '../../../utilities/items';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { useShouldDisplayCosts } from '../../../utilities/permissions/useShouldDisplayCosts';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { useLocalStorageParams } from '../../../utilities/urlState';
import { toSharedResourceUsers } from '../../../utilities/utilities';
import FilterSelect from '../../Inputs/InputsFilterSelect/InputsFilterSelect';
import { useGetSharedResource } from '../../ItemsList/ItemsSharing/hooks/useGetShareResourceHook';
import useMilestonesQuery from '../../Milestones/hooks/useMilestonesQuery';
import { isScheduleSettingsDisabled } from '../../ProjectProperties/ProjectScheduleImpact/ProjectScheduleImpactSettings';
import { renderTextValue } from '../../Select/JoinSelect/JoinSelectUtils';
import useMemoWrapper from '../../useMemoWrapper';

import { usePrintItemHistory } from './EvenItemsHistoryListUtils';
import EventsItemHistoryListContent from './EventsItemHistoryListContent';
import useItemHistoryQuery from './hooks/useItemHistoryQuery';

const ItemHistoryFilterEntry: FC<{ text: string }> = ({ text }) => {
  return <div className="type-body-2 ml-2 mt-2 h-full">{text}</div>;
};

type EventsItemHistoryListProps = {
  costMode: CostMode;
  customProjectId?: UUID;
  item?: ItemDataQueryItem;
  itemLoading: boolean;
  triggerOnComplete?: () => void;
  variant: string;
};

const EventsItemHistoryList: FC<EventsItemHistoryListProps> = ({
  costMode,
  customProjectId,
  item,
  itemLoading,
  triggerOnComplete = () => {},
  variant,
}) => {
  const isScheduleImpactEnabled = !isScheduleSettingsDisabled();

  const sendAnalytics = useSendAnalytics();
  const filterSelectEntries = getFilterSelectEntries(isScheduleImpactEnabled);

  // ITEM DETAILS VIEW STATE
  const { isPrivateCollapsed } = useReactiveVar(itemDetailsViewStateVar);
  const onToggleCollapsePrivate = () =>
    itemDetailsViewStateVar({ isPrivateCollapsed: !isPrivateCollapsed });

  // ITEM HISTORY FILTERING STATE
  const itemId = item?.id;
  const defaultSetting = { historyFilters: JSON.stringify(filterSelectEntries) };
  const [settings, setSettings] = useLocalStorageParams(defaultSetting, `Item History Filter`);
  const historyFilters = useMemo(
    () =>
      (settings && settings.historyFilters && JSON.parse(settings.historyFilters)) ||
      filterSelectEntries,
    [settings, filterSelectEntries]
  );
  const setHistoryFilters = (newFilters: string[]) =>
    setSettings({ historyFilters: JSON.stringify(newFilters) });
  const filtersList = useMemoWrapper(computeFiltersList, historyFilters, isScheduleImpactEnabled);
  // DATA
  const projectId = customProjectId ?? getProjectIdFromUrl();
  const { data: { milestones = [] } = {}, loading: milestoneLoading } = useMilestonesQuery(
    projectId,
    false
  );

  const { data: itemHistoryData, loading: itemHistoryLoading } = useItemHistoryQuery(
    itemId ?? null,
    {},
    filtersList,
    costMode
  );
  const events = itemHistoryData?.itemHistory?.events;
  const isPublishEventVisible =
    Array.isArray(historyFilters) && historyFilters.includes(SHARE_SETTING_CHANGE);

  const { data: sharedResourceData } = useGetSharedResource(item?.id, ResourceType.ITEM);
  const sharedUsers = toSharedResourceUsers(sharedResourceData);

  // Trigger print
  useEffect(() => {
    if (!itemHistoryLoading && !itemLoading && !milestoneLoading) {
      setTimeout(() => {
        triggerOnComplete();
      }, 1000);
    }
  }, [itemHistoryLoading, itemLoading, milestoneLoading, triggerOnComplete]);

  const printItemHistory = usePrintItemHistory();

  // PERMISSIONS
  const { canView } = usePermissions({ trades: item?.categories });

  const currentItem = useMemoWrapper(computeItemCostImpact, item);
  const canViewItemComments = canView(PermissionResource.ITEM_COMMENTS);
  const canControlCommments = canViewItemComments && !printItemHistory;
  const canViewItemAttachments = canView(PermissionResource.ITEM_ATTACHMENTS);
  const { shouldDisplayCosts } = useShouldDisplayCosts();

  // EVENTS DESCRIPTIONS
  const itemEvents = useMemoWrapper(
    computeItemHistoryEvents,
    currentItem,
    events,
    milestones,
    canViewItemComments,
    shouldDisplayCosts
  );

  // FILTERING
  const showComments = historyFilters.includes(FILTER_COMMENTS);
  const showEvents = !showComments || historyFilters.length !== 1;
  const showPrivateHistory = historyFilters.includes(FILTER_DRAFT_HISTORY);

  const changeFilterSelection = (filterSetting: { [ITEM_HISTORY]: string[] }) => {
    setHistoryFilters(filterSetting[ITEM_HISTORY]);
    sendAnalytics(
      itemEvent(ItemEvent.CHANGE_HISTORY_FILTER, { filters: filterSetting[ITEM_HISTORY] })
    );
  };

  const renderValue = useMemo(
    () =>
      renderTextValue(
        historyFilters.length === filterSelectEntries.length,
        FILTER_ITEM_HISTORY_ALL,
        historyFilters.length === 0,
        FILTER_ITEM_HISTORY_NONE,
        (text: string) => () => <ItemHistoryFilterEntry text={text} />
      ),
    [filterSelectEntries.length, historyFilters.length]
  );

  const filteredItemEvents = itemEvents.filter((itemEvent) => {
    if (itemEvent.eventTypes.includes(EventType.ADD_COMMENT)) return showComments;
    if (itemEvent.eventTypes.includes(EventType.CHANGE_SCHEDULE_IMPACT))
      return isScheduleImpactEnabled;
    if (itemEvent.eventTypes.includes(EventType.ADD_ASSET) && !canViewItemAttachments) return false;
    return showEvents;
  });

  return (
    <div>
      {variant === ITEM_DETAILS && (
        <div className="flex break-before-page items-center pb-2">
          <div className="float-left w-full type-heading3">Item History</div>
          <div className="float-right min-w-[300px]" title="Filter History">
            {canControlCommments && (
              <FilterSelect
                defaultLabel={FILTER_ITEM_HISTORY_ALL}
                displaySelectAll
                filterKey={ITEM_HISTORY}
                labelFn={(s: string) => s}
                maxChipsShown={2}
                onChange={(f) => changeFilterSelection(f)}
                options={filterSelectEntries}
                renderValue={renderValue}
                selected={historyFilters}
              />
            )}
          </div>
        </div>
      )}
      <EventsItemHistoryListContent
        canViewItemAttachments={canViewItemAttachments}
        filteredItemEvents={filteredItemEvents}
        isPresentationView={!showPrivateHistory}
        isPrivateCollapsed={isPrivateCollapsed}
        isPublishEventVisible={isPublishEventVisible}
        item={item}
        onToggleCollapsePrivate={onToggleCollapsePrivate}
        sharedUsers={sharedUsers}
        shouldDisplayCosts={shouldDisplayCosts}
      />
    </div>
  );
};

export default EventsItemHistoryList;
