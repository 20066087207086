import { TermKey } from '../../../api/gqlEnums';
import useProjectTermStore from '../../../hooks/useProjectTermStore';
import { EMPTY_COST } from '../../../utilities/string';
import { renderCostString } from '../../CostReport/CostReportUtils';
import useItemStatusCostReport from '../../Nav/Sidebar/hooks/useItemStatusCostReport';
import useSummaryCostReport from '../../Nav/Sidebar/hooks/useSummaryCostReport';
import { TerminologyDescriptions } from '../../ProjectDisplaySettings/constants';
import { InsightsProject } from '../hooks/useInsightsProjectsQuery';
import {
  budgetImg,
  estimateImg,
  gapIcon,
  gapMaxIcon,
  gapMinIcon,
  pendingAddDedIcon,
  potentialRangeIcon,
  runningTotalImg,
} from '../InsightsIcons';

import ProjectCostRow from './ProjectCostRow';

type ItemStatusData = {
  cost?: AddDeductCost;
  numItems?: number;
};

type Props = { project: InsightsProject; showTooltips?: boolean };

export default function ProjectCostTable(props: Props) {
  const activeMilestoneID = (props.project.milestones || []).find((m) => m.active);
  const terms = useProjectTermStore();
  const costsReport = useSummaryCostReport(props.project.id, activeMilestoneID?.id || '');
  const itemsReport = useItemStatusCostReport(props.project.id, activeMilestoneID?.id || '');

  return (
    <div className="flex max-w-max flex-col gap-2 p-2 text-left type-table-text">
      {/* ESTIMATE row */}
      <CostRow
        className="text-type-primary"
        cost={costsReport.estimate}
        data-cy="cost-summary-estimate"
        description="Base estimate for the active milestone"
        icon={estimateImg}
        label={terms.titleCase(TermKey.ESTIMATE)}
        showTooltips={props.showTooltips}
      />
      {/* ACCEPTED_CHANGES row */}
      <CostRow
        className="text-item-status-accepted"
        cost={costsReport.accepted}
        data-cy="cost-summary-accepted-changes"
        description={TerminologyDescriptions(terms)(TermKey.ACCEPTED_CHANGES)}
        label={terms.titleCase(TermKey.ACCEPTED_CHANGES)}
        showTooltips={props.showTooltips}
      />

      <hr />

      {/* RUNNING_TOTAL row */}
      <CostRow
        className="text-type-primary"
        cost={costsReport.running}
        data-cy="cost-summary-running-total"
        description="Revised estimate including accepted items"
        icon={runningTotalImg}
        label={terms.titleCase(TermKey.RUNNING_TOTAL)}
        showTooltips={props.showTooltips}
      />
      {/* POTENTIAL_RANGE row  row */}
      <PendingAddDeducts
        className="text-type-primary"
        data-cy="cost-summary-pending-adds-deducts"
        description={TerminologyDescriptions(terms)(TermKey.PENDING_ADDS_DEDUCTS)}
        icon={pendingAddDedIcon}
        itemData={itemsReport.pending}
        label={terms.titleCase(TermKey.PENDING_ADDS_DEDUCTS)}
        showTooltips={props.showTooltips}
      />
      {/* POTENTIAL_RANGE row */}
      <PotentialRange
        className="text-type-primary"
        cost={costsReport.running}
        data-cy="cost-summary-potential-range"
        description={TerminologyDescriptions(terms)(TermKey.POTENTIAL_RANGE)}
        icon={potentialRangeIcon}
        itemData={itemsReport.pending}
        label={terms.titleCase(TermKey.POTENTIAL_RANGE)}
        showTooltips={props.showTooltips}
      />
      {/* GAP row */}
      <GapTrending
        className="text-type-primary"
        costBudget={costsReport.target}
        costRunning={costsReport.running}
        data-cy="cost-summary-gap"
        description={TerminologyDescriptions(terms)(TermKey.GAP)}
        icon={gapIcon}
        label={terms.titleCase(TermKey.GAP)}
        showTooltips={props.showTooltips}
        type="Base"
      />
      {/*  GAP_TRENDING_MINIMUM row */}
      <GapTrending
        className="text-type-primary"
        costBudget={costsReport.target}
        costRunning={costsReport.running}
        data-cy="cost-summary-gap-min"
        description={TerminologyDescriptions(terms)(TermKey.GAP_TRENDING_MINIMUM)}
        icon={gapMinIcon}
        itemData={itemsReport.pending}
        label={terms.titleCase(TermKey.GAP_TRENDING_MINIMUM)}
        showTooltips={props.showTooltips}
        type="Min"
      />
      {/*  GAP_TRENDING_MAXIMUM row */}
      <GapTrending
        className="text-type-primary"
        costBudget={costsReport.target}
        costRunning={costsReport.running}
        data-cy="cost-summary-gap-min"
        description={TerminologyDescriptions(terms)(TermKey.GAP_TRENDING_MAXIMUM)}
        icon={gapMaxIcon}
        itemData={itemsReport.pending}
        label={terms.titleCase(TermKey.GAP_TRENDING_MAXIMUM)}
        showTooltips={props.showTooltips}
        type="Max"
      />

      <hr />

      {/*  Budget row */}
      <CostRow
        className="text-entities-milestone"
        cost={costsReport.target}
        data-cy="cost-summary-budget"
        description={`${terms.rawTerm(TermKey.TARGET)} value for this milestone`}
        icon={budgetImg}
        label={terms.titleCase(TermKey.TARGET)}
        showTooltips={props.showTooltips}
      />
    </div>
  );
}

// Base props type without itemData
type BaseProps = {
  'data-cy': string;
  className: string;
  cost?: Cost;
  description: string;
  icon?: JSX.Element;
  label: string;
  showTooltips?: boolean;
};

// Specific props for PendingAddDeducts and PotentialRange where itemData is needed
type ItemDataProps = BaseProps & {
  itemData?: ItemStatusData;
};

// Specific props for GapTrending where costBudget, costRunning, and type are needed
type GapTrendingProps = ItemDataProps & {
  costBudget?: Cost;
  costRunning?: Cost;
  type: 'Base' | 'Max' | 'Min';
};

function CostRow(props: BaseProps) {
  const roundedCost = renderCostString({
    cost: props.cost,
    short: true,
  });
  const exactCost = renderCostString({
    cost: props.cost,
    isExact: true,
    isWide: true,
  });

  return (
    <ProjectCostRow
      className={props.className}
      costExact={exactCost}
      costRounded={roundedCost}
      data-cy={props['data-cy']}
      description={props.description}
      icon={props.icon}
      label={props.label}
      showTooltips={props.showTooltips}
    />
  );
}

function PendingAddDeducts(props: ItemDataProps) {
  const itemAdds = renderCostString({
    cost: props.itemData?.cost?.adds,
    isSigned: true,
  });
  const itemDeducts = renderCostString({
    cost: props.itemData?.cost?.deducts,
    isSigned: true,
  });
  const itemCostRange = `${itemAdds} / ${itemDeducts}`;

  const exactRangAdds = renderCostString({
    cost: props.itemData?.cost?.adds,
    isExact: true,
    isWide: true,
  });
  const exactRangeDeducts = renderCostString({
    cost: props.itemData?.cost?.deducts,
    isExact: true,
    isWide: true,
  });
  const itemCostRangeExact = `${exactRangAdds} / ${exactRangeDeducts}`;

  return (
    <ProjectCostRow
      className={props.className}
      costExact={itemCostRangeExact}
      costRounded={itemCostRange}
      data-cy={props['data-cy']}
      description={props.description}
      icon={props.icon}
      label={props.label}
      showTooltips={props.showTooltips}
    />
  );
}

function PotentialRange(props: ItemDataProps) {
  const itemAdds = Number(props.itemData?.cost?.adds) || 0;
  const itemDeducts = Number(props.itemData?.cost?.deducts) || 0;
  let runningTotal = 0;
  if (props.cost) {
    const { value } = props.cost as CostScalar;
    if (value !== undefined) {
      runningTotal = Number(value);
    }
  }

  const rangeMax = runningTotal + itemAdds;
  const rangeMin = runningTotal + itemDeducts;

  const roundedRangeMax = renderCostString({
    cost: rangeMax / 100,
    short: true,
  });
  const roundedRangeMin = renderCostString({
    cost: rangeMin / 100,
    short: true,
  });
  const potentialRangeRounded = `${roundedRangeMax} to ${roundedRangeMin}`;

  const exactRangeMax = renderCostString({
    cost: rangeMax / 100,
    isExact: true,
    isWide: true,
  });
  const exactRangeMin = renderCostString({
    cost: rangeMin / 100,
    isExact: true,
    isWide: true,
  });
  const potentialRangeExact = `${exactRangeMax} to ${exactRangeMin}`;

  return (
    <ProjectCostRow
      className={props.className}
      costExact={potentialRangeExact}
      costRounded={potentialRangeRounded}
      data-cy={props['data-cy']}
      description={props.description}
      icon={props.icon}
      label={props.label}
      showTooltips={props.showTooltips}
    />
  );
}

function GapTrending(props: GapTrendingProps) {
  const itemAdds = Number(props.itemData?.cost?.adds) || 0;
  const itemDeducts = Number(props.itemData?.cost?.deducts) || 0;

  const getCostValue = (cost: Cost) => {
    if (cost && 'value' in cost) {
      return Number(cost.value) || 0;
    }
    return 0;
  };

  const costRunning = getCostValue(props.costRunning);
  const costBudget = getCostValue(props.costBudget);

  const gap = costBudget - costRunning;
  const rangeSummary = {
    Max: gap + itemAdds,
    Min: gap + itemDeducts,
    Base: gap,
  };
  const value = rangeSummary[props.type] || gap;

  const valueRounded = renderCostString({
    cost: value / 100,
    short: true,
  });
  const valueExact = renderCostString({
    cost: value / 100,
    isExact: true,
    isWide: true,
  });
  const percentage = costBudget > 0 ? `${((value / costBudget) * 100).toFixed()}%` : EMPTY_COST;

  return (
    <ProjectCostRow
      className={props.className}
      costExact={valueExact}
      costPercentage={percentage}
      costRounded={valueRounded}
      data-cy={props['data-cy']}
      description={props.description}
      icon={props.icon}
      label={props.label}
      showTooltips={props.showTooltips}
    />
  );
}
