import { StackDataItem } from '../Charts/DesignPhaseStackedBarChart';

type Props = {
  stackData: StackDataItem[];
};

export default function ProjectsSummaryStackedTooltip({ stackData }: Props) {
  // Extract the projectsCount values and calculate the total
  const dataWithCounts = stackData.map((item) => ({
    category: item.category,
    projectsCount: item.values.find((v) => v.name === 'projectsCount')?.value || 0,
  }));

  const totalCount = dataWithCounts.reduce((sum, item) => sum + item.projectsCount, 0);
  const formatPercent = (projectsCount: number) =>
    totalCount ? ((projectsCount / totalCount) * 100).toFixed() : 0;

  return (
    <div className="flex flex-col gap-2">
      <div className="type-heading3">Projects by Design Phase</div>
      <div className="flex flex-col gap-3 text-left">
        {dataWithCounts.map((item) => (
          <div key={item.category}>
            <div className="flex items-center justify-between gap-4">
              <div className="line-clamp-1 flex-1 type-body2">{item.category}</div>
              <div className="w-10 text-right type-body2">{item.projectsCount}</div>
              <div className="w-10 text-right type-body2">{formatPercent(item.projectsCount)}%</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
