import { FC, Fragment } from 'react';

import { ContingenciesTimeseries } from '../../../generated/graphql';
import { formatCost } from '../../../utilities/currency';
import { formatPercent } from '../../../utilities/string';

type Props = {
  currentContingencyData: ContingenciesTimeseries | null;
  estimateTotal: number;
};

const ContingenciesPieToolTip: FC<Props> = (props) => {
  const { currentContingencyData, estimateTotal } = props;
  const contingenciesData = [];
  if (currentContingencyData?.contingencyTotal) {
    contingenciesData.push({
      label: 'Contingencies',
      percentage: currentContingencyData.contingencyTotal / estimateTotal,
      count: formatCost(currentContingencyData.contingencyTotal, { short: true }),
      color: 'var(--colors-theme-bauhaus-marcelbreuer)',
    });
  }
  if (currentContingencyData?.allowanceTotal) {
    contingenciesData.push({
      label: 'Allowances',
      percentage: currentContingencyData.allowanceTotal / estimateTotal,
      count: formatCost(currentContingencyData.allowanceTotal, { short: true }),
      color: 'var(--colors-theme-bauhaus-laszlo)',
    });
  }

  return (
    <div className="flex flex-col">
      {/* Header */}
      <div className="mb-2 flex justify-between ">
        <div className="type-heading3">Estimate</div>
        <div className="type-heading3">{formatCost(estimateTotal, { short: true })}</div>
      </div>
      {/* Table section */}
      <div className="grid grid-cols-[160px_40px_40px] gap-2 text-left">
        {contingenciesData.map((item) => (
          <Fragment key={item.label}>
            <div className="flex items-center type-body2">
              <div className="mr-2 h-3 w-3" style={{ backgroundColor: item.color }} />
              <div className="w-full">{item.label}</div>
            </div>
            <div className="text-right type-body2">{formatPercent(item.percentage)}</div>
            <div className="text-right type-body2">{item.count}</div>
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default ContingenciesPieToolTip;
