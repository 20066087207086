import { useEffect, useState } from 'react';

import { useReactiveVar } from '@apollo/client';

import { insightsMaxItemsBar } from '../../../api/apollo/reactiveVars';
import { getTodayUtcNoon } from '../../../utilities/dates';
import InsightsBaseLine from '../../dragon-scales/TimelineCharts/InsightsCost/InsightsBaseLine';
import { ItemTimeSeries } from '../../dragon-scales/TimelineCharts/InsightsItems/types';
import {
  ItemDueState,
  TimelineItemData,
} from '../../shared-widgets/TimelineChart/timeline/timeline.types';
import { InsightsProject } from '../hooks/useInsightsProjectsQuery';
import TimelineDueDateChart from '../ProjectDetailsCharts/ProjectItems/TimelineDueDateChart';
import TableChartPlaceholder from '../TableChartPlaceholder';

import { useItems } from './hooks/useItems';
import { BASE_HEIGHT, CHART_HEIGHT, TOTAL_HEIGHT } from './utils';

export default function ProjectItems(props: {
  project: InsightsProject;
  startStr: string;
  endStr: string;
  onZoom?: (domain: string[]) => void;
  today?: Date;
  onClick?: () => void;
}) {
  const today = props.today ?? getTodayUtcNoon();
  const projectID = props.project.id;
  const milestones = props.project?.milestones || [];
  const activeMilestoneID = milestones.find((m) => m.active)?.id;

  // Activities
  const inMaxItemsBar = useReactiveVar(insightsMaxItemsBar);

  const { items: realItems, loading } = useItems(projectID, activeMilestoneID || '');
  const items = seriesToTimeline(props.project?.itemsDueDateSeriesMockData) || realItems;

  // Min and Max date range values for zoom domain and pickers
  const [startStr, setStartStr] = useState<string>(props.startStr);
  const [endStr, setEndStr] = useState<string>(props.endStr);

  useEffect(() => {
    if (startStr !== props.startStr) setStartStr(props.startStr);
    if (endStr !== props.endStr) setEndStr(props.endStr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.startStr, props.endStr]);

  const setStartEnd = ([start, end]: string[]) => {
    setStartStr(start);
    setEndStr(end);
  };

  const onZoom = (domain: string[]) => {
    setStartEnd(domain);
    props.onZoom?.(domain);
  };

  if (loading) return <TableChartPlaceholder />;

  const chart = (
    <TimelineDueDateChart
      height={CHART_HEIGHT}
      isMiniChart
      items={items}
      maxItemsBar={inMaxItemsBar}
      onZoom={onZoom}
      projectID={projectID}
      today={today.toISOString()}
      totalRange={[startStr, endStr]}
    />
  );

  const base = (
    <InsightsBaseLine
      baseRange={[new Date(startStr ?? '0'), new Date(endStr ?? '0')]}
      data={milestones}
      height={BASE_HEIGHT}
      today={today}
      totalRange={[new Date(startStr ?? '0'), new Date(endStr ?? '0')]}
    />
  );

  return (
    <div
      key={props.project.id}
      className={`flex h-[${TOTAL_HEIGHT}px] min-w-full cursor-pointer flex-col bg-background-primary`}
      onClick={props.onClick}
      onKeyPress={props.onClick}
    >
      {chart}
      {base}
    </div>
  );
}

export const seriesToTimeline = (
  items: ItemTimeSeries[] | undefined
): TimelineItemData[] | undefined => {
  if (!items) return undefined;
  const series: TimelineItemData[] = [];
  items.forEach((point) => {
    const {
      date,
      dueDates: { past, upcoming, decided, without },
    } = point;
    const dueDate = date.toISOString();
    // Past
    const pastArr: TimelineItemData[] = Array(past).fill({
      id: '',
      name: 'Item',
      dueDate,
      dueState: ItemDueState.PastDue,
    });
    series.push(...pastArr);
    // Upcoming
    const upcomingArr: TimelineItemData[] = Array(upcoming).fill({
      id: '',
      name: 'Item',
      dueDate,
      dueState: ItemDueState.Upcoming,
    });
    series.push(...upcomingArr);
    // Decided
    const decidedArr: TimelineItemData[] = Array(decided).fill({
      id: '',
      name: 'Item',
      dueDate,
      dueState: ItemDueState.Decided,
    });
    series.push(...decidedArr);
    //  Without
    const withoutArr: TimelineItemData[] = Array(without).fill({
      id: '',
      name: 'Item',
      dueDate,
      dueState: ItemDueState.Decided,
    });
    series.push(...withoutArr);
  });
  return series;
};
