import { FC } from 'react';

import { Help } from '@material-ui/icons';

import { CompanyAdminView } from '../../../analytics/analyticsEventProperties';
import { COMPANY_PROJECT_STATS } from '../../../constants';
import { SearchResultType } from '../../../generated/graphql';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import FilterSummaryText from '../../ProjectsList/FilterSummaryText';
import useSearchProjectsFilterOptionsQuery from '../../ProjectsList/hooks/useSearchProjectsFilterOptionsQuery';
import {
  ProjectFilterManager,
  ProjectFilterSetSettings,
  generateFilterTooltipCopy,
} from '../../ProjectsList/ProjectsListUtils';
import { BabyButton, SortManager, Tooltip } from '../../scales';
import CompanyTabSearch from '../CompanyTabSearch';
import { CompanyUserSearchManager, searchProjectStats } from '../CompanyTabUtils';
import CompanyTabHeader from '../Standards/CompanyTabHeader';

import CompanyTabProjectStatsTable from './CompanyTabProjectStatsTable';
import ProjectStatsFilterPanel from './ProjectStatsFilterPanel';

type CompanyTabProjectStatsProps = {
  companyID: UUID;
  searchManager: CompanyUserSearchManager;
  companyProjectStats: CompanyProjectStats[];
  loading: boolean;
  sortManager: SortManager;
  filterManager: ProjectFilterManager;
  setSettings: ProjectFilterSetSettings;
  projectCount: number | undefined;
  projectCountLoading: boolean;
};

const CompanyTabProjectStats: FC<CompanyTabProjectStatsProps> = ({
  companyID,
  companyProjectStats,
  loading,
  sortManager,
  searchManager,
  filterManager,
  setSettings,
  projectCount,
  projectCountLoading,
}) => {
  const { data: filterOptionsData } = useSearchProjectsFilterOptionsQuery(SearchResultType.ALL);
  const projectTypes = filterOptionsData?.searchProjectsFilterOptions?.projectTypes || [];

  const { searchTerm } = searchManager;

  const totalProjectCount = !projectCountLoading && projectCount !== undefined ? projectCount : '-';
  const searchedProjectStats = searchProjectStats(companyProjectStats, searchTerm);
  const countSearchedProjectStats = searchedProjectStats?.length ?? 0;

  const normalTooltipCopy = generateFilterTooltipCopy(filterManager.filterState, projectTypes).map(
    (t) => {
      return <div key={t}>{t}</div>;
    }
  );

  const projectStatsTooltip =
    'Project Stats displays projects created by your employees in Join. Projects created by partners will not be displayed here.';

  return (
    <>
      <div className="flex flex-wrap items-center justify-between gap-2">
        <div className="flex items-center justify-between gap-2">
          <CompanyTabHeader title={COMPANY_PROJECT_STATS} />
          <Tooltip content={projectStatsTooltip}>
            <BabyButton aria-label="Info" disabled icon={<Help />} />
          </Tooltip>
          <div className="type-body2">
            {countSearchedProjectStats} of {totalProjectCount} projects shown
          </div>
        </div>
        <div className="flex items-center justify-between gap-2 p-4">
          <NormalTooltip title={normalTooltipCopy}>
            <div>
              <FilterSummaryText filterManager={filterManager} />
            </div>
          </NormalTooltip>
          <div>
            <ProjectStatsFilterPanel filterManager={filterManager} setSettings={setSettings} />
          </div>
          <div>
            <CompanyTabSearch
              page={CompanyAdminView.PROJECT_STATS_LIST}
              searchManager={searchManager}
              variant="projectStats"
            />
          </div>
        </div>
      </div>
      <CompanyTabProjectStatsTable
        companyID={companyID}
        companyProjectStats={searchedProjectStats}
        loading={loading}
        searchTerm={searchTerm}
        sortManager={sortManager}
      />
    </>
  );
};

export default CompanyTabProjectStats;
