import * as DOMPurify from 'dompurify';
import linkifyString from 'linkify-string';

import { mergeAttributes } from '@tiptap/core';
import Blockquote from '@tiptap/extension-blockquote';
import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import Code from '@tiptap/extension-code';
import Document from '@tiptap/extension-document';
import Heading from '@tiptap/extension-heading';
import Highlight from '@tiptap/extension-highlight';
import History from '@tiptap/extension-history';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import Image from '@tiptap/extension-image';
import Italic from '@tiptap/extension-italic';
import Link from '@tiptap/extension-link';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Strike from '@tiptap/extension-strike';
import Task from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import Text from '@tiptap/extension-text';
import Underline from '@tiptap/extension-underline';
import Indent from '@weiruo/tiptap-extension-indent';

import useSendAnalytics from '../../../../hooks/useSendAnalytics';

import { ShiftEnterExtension, TabExtension } from './TipTapTextAreaCustomExtensions';
import ListItem from './utils/listItemv2';
import OrderedList from './utils/orderedv2';

// Any input to TipTap:
// turns plain text into rich text (with links)
// scrubs the input of any malicious code
// in HTML
// or in JSON
export const isRichText = (text?: string) => text?.startsWith('<');
export const getEditorContent = (text: string) => {
  let preparedText = text;
  if (!isRichText(text)) {
    // handle links as <A
    const linkifiedText = linkifyString(text, {
      attributes: {
        rel: 'noopener noreferrer',
        target: '_blank',
      },
    });
    // New lines as paragraphs <P
    const paragraphedText = linkifiedText.replace(/\n/g, '</p><p>');
    preparedText = `<p>${paragraphedText}</p>`;
  }
  return DOMPurify.sanitize(preparedText, { ADD_ATTR: ['target'] });
};

export const getExtensions = (placeholder: string | undefined, isUploadImageFeature: boolean) => {
  const extensions = [
    Document,
    Highlight.configure({ HTMLAttributes: { class: 'bg-selection-selected' } }),
    History,
    Text,
    Paragraph,
    Placeholder.configure({
      emptyEditorClass:
        'before:content-[attr(data-placeholder)] before:text-type-inactive before:float-left before:h-0',
      placeholder,
      showOnlyWhenEditable: true,
    }),
    Blockquote.configure({ HTMLAttributes: { class: 'border-l pl-4' } }),
    Bold,
    BulletList,
    Code.configure({
      HTMLAttributes: { class: 'type-body3 !font-mono bg-background-2 px-1 block' },
    }),
    Heading.configure({ levels: [1, 2] }).extend({
      renderHTML({ node, HTMLAttributes }) {
        const hasLevel = this.options.levels.includes(node.attrs.level);
        const level = hasLevel ? node.attrs.level : this.options.levels[0];

        const classes: Record<number, string> = {
          1: 'type-heading1',
          2: 'type-heading2',
        };

        return [
          `h${level}`,
          mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
            class: `${classes[level]}`,
          }),
          0,
        ];
      },
    }),
    HorizontalRule.configure({ HTMLAttributes: { class: 'my-4 border border-border-separator' } }),
    Indent.extend({ priority: 50 }),
    Italic,
    ListItem,
    OrderedList,
    ShiftEnterExtension,
    Strike,
    TabExtension,
    Task,
    TaskList,
    Link.configure({
      openOnClick: true,
      HTMLAttributes: { class: 'type-small-link' },
    }),
    Underline,
  ];
  if (isUploadImageFeature) return [...extensions, Image];
  return extensions;
};

// Text Styling in Descriptions
export enum MenuBarAction {
  BOLD = 'bold',
  ITALIC = 'italic',
  STRIKE = 'strike',
  UNDERLINE = 'underline',
  CODE = 'code',
  DIVIDER = 'divider',
  HIGHLIGHT = 'highlight',
  HEADING = 'heading',
  HEADING_1 = 'heading_1',
  HEADING_2 = 'heading_2',
  INDENT = 'indent',
  OUTDENT = 'outdent',
  BULLET_LIST = 'bulletList',
  ORDERED_LIST = 'orderedList',
  TASK_LIST = 'taskList',
  BLOCKQUOTE = 'blockquote',
  HORIZONTAL_RULE = 'horizontalRule',
  UNDO = 'undo',
  REDO = 'redo',
  CLEAR_FORMAT = 'clearFormat',
}

// Text Styling Image in Descriptions
export enum ImageAction {
  DROP = 'drop',
  PASTE = 'paste',
}

const TEXT_STYLING_EVENT = 'textStyling';
const getType = (eventName: string) => `${TEXT_STYLING_EVENT}-${eventName}`;

export const getTextStylingMenuAnalyticsEvent = (menuButton: MenuBarAction) => ({
  type: getType('menuButton'),
  eventProperties: { menuButton },
});

export const getTextStylingKeyAnalyticsEvent = (keyPress: string) => ({
  type: getType('keyCommand'),
  eventProperties: { keyPress },
});

export const getTextStylingImageAnalyticsEvent = (imageAction: ImageAction) => ({
  type: getType('imageAction'),
  eventProperties: { imageAction },
});

export const getKeyCommandFromKeyEvent = (event: KeyboardEvent) => {
  const { metaKey, ctrlKey, shiftKey, key, altKey } = event;
  const hasActionKey = metaKey || ctrlKey;
  const keyIsNotActionKey = !['Meta', 'Control', 'Shift', 'Alt'].includes(key);
  if (hasActionKey && keyIsNotActionKey) {
    let keyPress = '';
    if (metaKey) keyPress = keyPress.concat('⌘-');
    if (ctrlKey) keyPress = keyPress.concat('Ctrl-');
    if (shiftKey) keyPress = keyPress.concat('Shift-');
    if (altKey) keyPress = keyPress.concat('Alt-');
    keyPress = keyPress.concat(key);
    return keyPress;
  }
  return null;
};

export const useHandleDOMEvents = () => {
  const sendAnalytics = useSendAnalytics();
  const handleDOMEvents = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    keydown: (_: any, event: KeyboardEvent) => {
      event.stopPropagation();
      const keyPress = getKeyCommandFromKeyEvent(event);
      if (keyPress) {
        sendAnalytics(getTextStylingKeyAnalyticsEvent(keyPress));
      }
      return false;
    },
  };
  return handleDOMEvents;
};
