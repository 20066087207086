import { ImageDimension } from '../../generated/graphql';
import Thumbnail from '../shared-widgets/Thumbnail/Thumbnail';

type Props = {
  programCount: number;
  projectThumbnail: string;
};

export default function ProgramThumbnail({ projectThumbnail, programCount }: Props) {
  return (
    <div>
      <div className="relative right-1 z-0 -rotate-12 border border-background-primary">
        <Thumbnail
          dimension={ImageDimension._92}
          padding={0}
          size={70}
          thumbnail={projectThumbnail}
        />
      </div>
      <div className="relative bottom-[70px] left-1 z-10  border border-background-primary ">
        <Thumbnail
          dimension={ImageDimension._92}
          padding={0}
          size={70}
          thumbnail={projectThumbnail}
        />
      </div>
      <div className="relative bottom-[150px] left-[65px] z-20 flex min-h-[20px] w-5 flex-col justify-center rounded-[10px] bg-background-primary text-center type-body3">
        {programCount}
      </div>
    </div>
  );
}
