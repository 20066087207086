import { useState } from 'react';

import { ProgramFieldsFragment } from '../../generated/graphql';

import ProgramDashboardHeader from './ProgramDashboardHeader';
import ProgramDashboardProjectSelection from './ProgramDashboardProjectSelection';

type Props = {
  program?: ProgramFieldsFragment;
  selectedProjectIDs: UUID[];
  setSelectedProjectIDs: (newIDs: UUID[]) => void;
};

export default function ProgramDashboard({
  program,
  selectedProjectIDs,
  setSelectedProjectIDs,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const projects = program?.projects ?? [];
  if (!projects) return null;

  return (
    <div className="flex w-full flex-col gap-6 p-6">
      <div className="flex-grow type-giant">Program Dashboard</div>
      <ProgramDashboardHeader program={program} />
      <ProgramDashboardProjectSelection
        isOpen={isOpen}
        projects={projects}
        selectedProjectIDs={selectedProjectIDs}
        setIsOpen={setIsOpen}
        setSelectedProjectIDs={setSelectedProjectIDs}
      />
    </div>
  );
}
