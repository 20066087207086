import { FC } from 'react';

import {
  AnalyticsEvent,
  setItemEstimateExpanded,
} from '../../../analytics/analyticsEventProperties';
import { NS_REMOVE_S2_ITEM_MARKUP_REFS } from '../../../features';
import { useHasFeature } from '../../../hooks/useHasFeature';
import usePermissions, {
  getItemLinesPermissionResource,
} from '../../../utilities/permissions/usePermissions';
import { getCostValue } from '../../CostReport/CostReportUtils';
import CostEstimate from '../../estimate/CostEstimate';
import { GridVariant } from '../../JoinGrid/types';

import EstimateContentHeader from './EstimateContentHeader';
import ItemDetailsCollapse from './ItemDetailsCollapse';
import { SCROLL_MARGIN } from './ItemsCollapseStyles';

type Props = {
  categories: Category[];
  canExpand: boolean;
  estimateCost?: CostRange | CostScalar;
  item: ItemDataQueryItem;
  loading: boolean;
  isOption: boolean;
  isAllExpanded: boolean;
  isExpanded: boolean;
  options: DetailedOption[];
  projectID: UUID;
  sendAnalytics: (analyticsEvent: AnalyticsEvent) => void;
  setIsExpanded: (value: boolean) => void;
  setOpenDialogAddOption: (value: boolean) => void;
  itemID: UUID;
  hasGrayBackground: boolean;
  isReadOnly: boolean;
  milestoneID?: UUID;
  gridVariant: GridVariant;
};

const EstimateContent: FC<Props> = ({
  categories,
  canExpand,
  estimateCost,
  loading,
  item,
  isOption = false,
  isAllExpanded,
  isExpanded,
  options,
  projectID,
  sendAnalytics,
  setIsExpanded,
  setOpenDialogAddOption,
  itemID,
  hasGrayBackground,
  isReadOnly,
  milestoneID,
  gridVariant,
}) => {
  const activeEstimate = item?.activeEstimate;
  const { canView, inTrade } = usePermissions({ trades: item.categories });
  const canViewItemCost = canView(getItemLinesPermissionResource(inTrade));

  const hasRemoveS2ItemMarkupFeature = useHasFeature(NS_REMOVE_S2_ITEM_MARKUP_REFS);

  return (
    <ItemDetailsCollapse
      analyticsEvent={setItemEstimateExpanded(!isExpanded)} // update?
      canExpand={canExpand}
      hasGrayBackground={hasGrayBackground}
      headerContent={
        <EstimateContentHeader
          canViewContent={canViewItemCost}
          estimateCost={estimateCost}
          hasActiveEstimate
          hasOptions={false}
          isReadOnly={isReadOnly}
          itemID={itemID}
          options={options}
          projectID={projectID}
          scheduleImpact={undefined}
          sendAnalytics={sendAnalytics}
          setOpenDialogAddOption={setOpenDialogAddOption}
          shouldDisplayCosts // We can only get into EstimateContent with shouldDisplayCosts
        />
      }
      isExpanded={isExpanded}
      isReadOnly={isReadOnly}
      setIsExpanded={setIsExpanded}
      style={{ scrollMarginTop: SCROLL_MARGIN }}
    >
      <div
        className="flex flex-col"
        onDragOver={(e) => {
          if (e) e.preventDefault();
        }}
      >
        {activeEstimate ? (
          <CostEstimate
            activeEstimateID={activeEstimate.id}
            canViewDetails={canViewItemCost}
            costOfConstruction={getCostValue(estimateCost)}
            hasRemoveS2ItemMarkupFeature={hasRemoveS2ItemMarkupFeature}
            isExpanded={isAllExpanded}
            isItemEstimateView={!!window.location.hash}
            isOption={isOption}
            itemID={itemID}
            itemTrade={categories}
            linesReadOnly={isReadOnly}
            milestoneID={milestoneID}
            projectID={projectID}
            sendAnalytics={sendAnalytics}
            sendRefetch={loading}
            variant={gridVariant}
          />
        ) : null}
      </div>
    </ItemDetailsCollapse>
  );
};

export default EstimateContent;
