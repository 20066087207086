import './TipTapTextArea.scss';

import { useEffect } from 'react';

import { Slice } from '@tiptap/pm/model';
import { EditorProps, EditorView } from '@tiptap/pm/view';
import { EditorContent, useEditor } from '@tiptap/react';

import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { useUploadImage } from '../../../assets/hooks/AssetsMutationHook';
import MenuBar from '../MenuBar/MenuBar';

import { addImage } from './TipTapTextAreaImageUtils';
import {
  ImageAction,
  getEditorContent,
  getExtensions,
  getTextStylingImageAnalyticsEvent,
  useHandleDOMEvents,
} from './TipTapTextAreaUtils';

export enum TipTapTextAreaVariant {
  DEFAULT,
  DESCRIPTION,
}

type Props = {
  'data-cy'?: string;
  isDisabled?: boolean;
  isEditable: boolean;
  onAttachAsset?: (asset: Asset) => void;
  onChange?: (value: string, valueStyled: string) => void;
  placeholder?: string;
  value: string;
};

export default function TipTapTextArea(props: Props) {
  const sendAnalytics = useSendAnalytics();
  const uploadImage = useUploadImage();
  const isUploadImageFeature = !!props.onAttachAsset;

  const handlePaste = (view: EditorView, event: ClipboardEvent): boolean => {
    const file = (event.clipboardData?.files || [])[0];
    if (!file) return false; // not handled use default behaviour

    event.preventDefault();

    const position = view.state.selection.$anchor.pos || 0;
    const { left: x, top: y } = view.coordsAtPos(position);
    const onSuccess = (asset: Asset) => {
      if (props.onAttachAsset) props.onAttachAsset(asset);
      sendAnalytics(getTextStylingImageAnalyticsEvent(ImageAction.PASTE));
    };
    return addImage(view, file, x, y, uploadImage, onSuccess);
  };

  const handleDrop = (view: EditorView, event: DragEvent, _: Slice, moved: boolean): boolean => {
    const file = (event?.dataTransfer?.files || [])[0];
    if (moved || !file) return false; // not handled use default behaviour

    event.preventDefault();

    const { clientX: x, clientY: y } = event || {};
    const onSuccess = (asset: Asset) => {
      if (props.onAttachAsset) props.onAttachAsset(asset);
      sendAnalytics(getTextStylingImageAnalyticsEvent(ImageAction.DROP));
    };
    return addImage(view, file, x, y, uploadImage, onSuccess);
  };

  const handleDOMEvents = useHandleDOMEvents();

  const editorProps: EditorProps = { handleDOMEvents };

  if (isUploadImageFeature) {
    editorProps.handleDrop = handleDrop;
    editorProps.handlePaste = handlePaste;
  }

  const editable = props.isEditable && !props.isDisabled;
  const editor = useEditor({
    editable,
    editorProps,
    extensions: getExtensions(props.placeholder, isUploadImageFeature),
    content: getEditorContent(props.value),
    onBlur: ({ editor }) => {
      if (props.onChange) props.onChange(editor.getText(), editor.getHTML());
    },
  });

  // Changing the value of `editable` passed into the `useEditor` hook doesn't
  // update the editor instance that's already been rendered. This is a workaround
  // to update the editor instance with the new `editable` value.
  // Ref: https://github.com/ueberdosis/tiptap/issues/111
  useEffect(() => editor?.setOptions({ editable }), [editor, editable]);

  let className = 'cursor-default bg-transparent'; // !isEditable
  if (props.isEditable) {
    className = props.isDisabled
      ? 'border bg-button-inactive'
      : 'border bg-background-primary focus-within:outline cursor-text';
  }

  return (
    <div
      className={[
        'scales--rich-text-editor', // used for scoping in the css file
        'mb-2 flex w-full flex-col rounded-md text-type-primary type-body2 focus-visible:[&_*]:outline-none',
        props.isEditable ? 'min-h-[160px]' : '',
        className,
      ].join(' ')}
      onMouseDown={editable ? () => editor?.commands.focus() : undefined}
    >
      {props.isEditable && editor && <MenuBar editor={editor} isDisabled={props.isDisabled} />}
      <EditorContent
        className={['overflow-auto', props.isEditable ? 'p-4' : ''].join(' ')}
        data-cy={props['data-cy']}
        editor={editor}
        tabIndex={editable ? 0 : -1}
      />
    </div>
  );
}
