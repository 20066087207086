import {
  IntegrationFilterType,
  ItemsTimelineQuery,
  ItemsTimelineQueryVariables,
  VisibilityView,
} from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';
import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';
import { useCostMode } from '../../../utilities/costMode';

import { itemsTimelineQuery } from './queries';

export const useItemsTimelineQuery = (
  milestoneID: UUID | null,
  activityID: UUID | null,
  projectID: UUID | undefined,
  showOptions: boolean,
  viewFilter: ViewFilterInput,
  visibilityView: VisibilityView,
  integrations: IntegrationFilterType[],
  skipQuery = false
) =>
  useQuery<ItemsTimelineQuery, ItemsTimelineQueryVariables>(
    itemsTimelineQuery,
    {
      variables: {
        milestoneID,
        activityID,
        projectID,
        showOptions,
        loadItemCosts: false,
        costMode: useCostMode(),
        viewFilter,
        visibilityView,
        integrations,
      },
      skip: skipQuery || !projectID,
    },
    MountPolicy.FETCH_ON_VARS
  );
