import ProjectSelectionList from '../ProjectsList/ProjectSelection/ProjectSelectionList';

import ProgramSelectionCollapse from './ProgramSectionCollapse';

type Props = {
  projects: SearchProject[];
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  selectedProjectIDs: UUID[];
  setSelectedProjectIDs: (selectedProjectIDs: UUID[]) => void;
};

export default function ProgramDashboardProjectSelection({
  projects,
  isOpen,
  setIsOpen,
  selectedProjectIDs,
  setSelectedProjectIDs,
}: Props) {
  const text = `Showing ${selectedProjectIDs.length} of ${projects.length} Projects`;

  return (
    <div>
      <ProgramSelectionCollapse isOpen={isOpen} setIsOpen={setIsOpen} text={text}>
        {[
          <ProjectSelectionList
            key="ProjectSelectionList"
            fetchMore={() => {}}
            onChange={setSelectedProjectIDs}
            projects={projects}
            searchTerm=""
            selectedProjectIDs={selectedProjectIDs}
            selectionMode="multiple"
          />,
        ]}
      </ProgramSelectionCollapse>
    </div>
  );
}
