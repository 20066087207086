import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ForecastingProjectsSortKey } from '../../../generated/graphql';
import { RouteKeys } from '../../../routes/paths';
import { formatCost } from '../../../utilities/currency';
import { getTodayUtcNoon } from '../../../utilities/dates';
import { generateSharedPath } from '../../../utilities/routes/links';
import { formatNumber } from '../../../utilities/string';
import { SortManager, Table, Tooltip } from '../../scales';
import ZoomTimeline from '../Charts/ZoomTimeline';
import { InsightsProject } from '../hooks/useInsightsProjectsQuery';
import ProjectCost from '../ProjectBabyCharts/ProjectCost';
import ProjectTableTooltip from '../ToolTips/ProjectTableTooltip';
import { InsightsTabId } from '../types';

import ProjectTableEntry from './ProjectTableEntry';
import StickyBottomRow from './StickyBottomRow';
import { getProjectsRange } from './utils';

type Props = {
  counts: { current: number; total: number };
  loading: boolean;
  onFetchMore: () => void;
  projects: InsightsProject[];
  sortManager: SortManager;
};

const ProjectsCost: FC<Props> = (props) => {
  const navigate = useNavigate();
  const [startDate, endDate] = getProjectsRange(getTodayUtcNoon());
  const [startStr, setStartStr] = useState(startDate.toISOString());
  const [endStr, setEndStr] = useState(endDate.toISOString());
  const setStartEnd = ([start, end]: string[]) => {
    setStartStr(start);
    setEndStr(end);
  };
  const onZoom = (domain: string[]) => {
    setStartEnd(domain);
  };

  const stickyFooterEntry = StickyBottomRow({
    columnsCount: 5,
    component: <ZoomTimeline onZoom={onZoom} projects={props.projects} zoom={[startStr, endStr]} />,
  });

  if (!props.projects) return null;

  return (
    <>
      <Table
        columnWidths={[
          'minmax(250px, 3fr)',
          'minmax(125px, 0.5fr)',
          'minmax(125px, 0.5fr)',
          'minmax(125px, 0.5fr)',
          'minmax(600px, 6fr)',
        ]}
        entries={props.projects.map((project) => {
          const key = project.id;
          const to = {
            pathname: generateSharedPath(RouteKeys.INSIGHTS_PROJECT, { projectId: project.id }),
            hash: `#${InsightsTabId.Costs}`,
          };
          return [
            {
              key,
              component: (
                <Tooltip
                  content={
                    <div>
                      <ProjectTableTooltip project={project} tabType={InsightsTabId.Items} />
                    </div>
                  }
                >
                  <div>
                    <ProjectTableEntry hasAlert={Math.random() < 0.5} project={project} to={to} />
                  </div>
                </Tooltip>
              ),
            },
            {
              key,
              centered: true,
              component: <div>{formatCost(project.runningTotal, { showCents: false })}</div>,
            },
            {
              key,
              centered: true,
              component: <div>{formatCost(project.budget, { showCents: false })}</div>,
            },
            {
              key,
              centered: true,
              component: (
                <div>
                  {formatCost((project.runningTotal || 0) - (project.budget || 0), {
                    showCents: false,
                  })}
                </div>
              ),
            },
            {
              key,
              component: (
                <ProjectCost
                  endStr={endStr}
                  onClick={() => navigate(to)}
                  project={project}
                  startStr={startStr}
                />
              ),
            },
          ];
        })}
        headerContent={[
          {
            component: <div className="h-9" />,
            copy: `Projects (${formatNumber(props.counts.current)} of ${formatNumber(
              props.counts.total
            )})`,
            key: 'projects',
            headerSortKey: ForecastingProjectsSortKey.NAME,
          },
          {
            copy: 'Running Total',
            key: 'Running Total',
          },
          { copy: 'Budget', key: 'Budget' },
          {
            copy: 'Gap',
            key: 'Gap',
          },
          { copy: '', key: 'cost' },
        ]}
        loading={props.loading}
        onNeedMoreData={props.onFetchMore}
        sortManager={props.sortManager}
        stickyFooterEntry={stickyFooterEntry}
      />
    </>
  );
};

export default ProjectsCost;
