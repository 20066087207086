import { ScaleLinear, ScalePoint, ScaleTime } from 'd3-scale';

import truncateLabel from '../../../../utilities/dashboard';
import { getTextWidth } from '../../../../utilities/string';
import { isGmpInsightsMilestone } from '../../../ExecutiveDashboardV2/utils';
import useMemoWrapper from '../../../useMemoWrapper';
import TimelineContainer from '../TimelineContainer';

import { getMilestoneIcon } from './icons';
import { InsightsMilestone } from './types';
import { getDaysTo } from './utils';

const NAME_TRUNCATION = 25;
const ICON_SIZE = 14;

type Props = {
  bounds?: { left: number; right: number };
  data: InsightsMilestone;
  today: Date;
  x: ScaleTime<number, number> | ScalePoint<Date>;
  y: ScaleLinear<number, number>;
};

export default function InsightsMilestoneTip(props: Props) {
  const { data, today } = props;
  const { content, width } = useMemoWrapper(getTipContent, data, today);
  return (
    <TimelineContainer
      bounds={props.bounds}
      content={content}
      data={{ date: data.date, value: 10 }}
      hasBorder
      height={20}
      placement={isGmpInsightsMilestone(data) ? 'right' : 'left'}
      width={8 + width + 8}
      x={props.x}
      y={props.y}
    />
  );
}

const getTipContent = (data: InsightsMilestone, today: Date) => {
  const name = truncateLabel(data.name, NAME_TRUNCATION, true);
  const nameWidth = getTextWidth(name, 12);
  const daysTo = getDaysTo(data.date, today);
  const icon = getMilestoneIcon(data);
  let width = 0;
  let content = null;
  // Only future milestones should have countdowns
  if (isGmpInsightsMilestone(data) && daysTo > 0) {
    const daysToText = `${daysTo} days to`;
    const daysToWidth = getTextWidth(daysToText, 12);
    width = daysToWidth + 5 + ICON_SIZE + 5 + nameWidth;
    content = (
      <g>
        <text className="type-body3">{daysToText}</text>
        <g transform={`translate(${daysToWidth + 6}, ${-8})`}>{icon}</g>
        <text className="type-body3" x={daysToWidth + 5 + ICON_SIZE} y="0">
          {name}
        </text>
      </g>
    );
  } else {
    width = ICON_SIZE + 5 + nameWidth;
    content = (
      <g>
        <g transform={`translate(${0}, ${-8})`}>{icon}</g>
        <text className="type-body3" x={ICON_SIZE} y="0">
          {name}
        </text>
      </g>
    );
  }
  return { content, width };
};
