import { scaleBand, scaleLinear } from 'd3-scale';

import SVGWithDimensions from '../../Charts/ChartsD3/SVGWithDimensions';
import { useChartDimensions } from '../../Charts/ChartsD3/useChartDimensions';

type StackValueItem = { name: string; value: number };
export type StackDataItem = { category: string; values: StackValueItem[] };

type Props = {
  data: StackDataItem[];
};

// TODO: to make this component more generic:
// - Add title props
// - Don't hard code stacked bar color assignment
// - Add ability to stack more than 2 rectangles.
// - Make data-cy tags more generic.
export default function DesignPhaseStackedBarChart({ data }: Props) {
  const BAR_WIDTH = 16; // Constant bar width
  const BAR_PADDING = 4; // Padding between bars

  const { ref, dimensions } = useChartDimensions({
    height: 100,
    marginTop: 2,
    marginRight: 2,
    marginBottom: 10,
    marginLeft: 2,
  });

  // Calculate the total width needed for all bars
  const totalBarWidth = (BAR_WIDTH + BAR_PADDING) * data.length - BAR_PADDING;
  const totalWidth = Math.max(totalBarWidth, 100); // For fewer bars ensure total width is atleast 100px

  // Calculate dimensions in X axis
  const x = scaleBand<string>()
    .domain(data.map((d) => d.category))
    .range([0, totalBarWidth])
    .paddingInner(BAR_PADDING / BAR_WIDTH);

  // Calculate dimensions in Y axis
  const y = scaleLinear<number, number>()
    .domain([0, Math.max(...data.map((d) => Math.max(...d.values.map((v) => v.value))))])
    .range([dimensions.boundedHeight - dimensions.marginBottom, dimensions.marginTop]);

  // X-axis
  const xAxisYPosition = dimensions.boundedHeight;

  if (data.length === 0) return null;

  return (
    <div>
      <div className="pb-1 pl-1 type-label">{`Design Phase (${data.length})`}</div>
      <div className="w-full overflow-x-auto">
        <SVGWithDimensions
          ref={ref}
          dimensions={{
            ...dimensions,
            width: totalWidth + dimensions.marginLeft + dimensions.marginRight,
          }}
        >
          <g transform={`translate(${dimensions.marginLeft}, 0)`}>
            {data.map((d) => {
              const bottomRectangleHeight = y(0) - y(d.values[0].value);
              const topRectangleHeight = y(0) - y(d.values[1].value);
              const xPosition = x(d.category) ?? 0;

              return (
                <g key={d.category} data-cy={`bar-${d.category.toLowerCase()}`}>
                  <rect
                    className="fill-item-status-rejected"
                    data-cy="bar-rect-alert-projects"
                    height={bottomRectangleHeight}
                    width={BAR_WIDTH}
                    x={xPosition}
                    y={y(d.values[0].value)}
                  />
                  <rect
                    className="fill-chart-axis"
                    data-cy="bar-rect-projects"
                    height={topRectangleHeight}
                    width={BAR_WIDTH}
                    x={xPosition}
                    y={y(d.values[0].value) - topRectangleHeight}
                  />
                </g>
              );
            })}
            {/* X-axis line with arrow */}
            <line
              className="stroke-chart-axis"
              strokeWidth="2"
              x1="0"
              x2={totalWidth - 2}
              y1={xAxisYPosition}
              y2={xAxisYPosition}
            />
            <path
              className="fill-chart-axis stroke-chart-axis"
              d={`M ${totalWidth - 10} ${xAxisYPosition - 6} L ${
                totalWidth - 2
              } ${xAxisYPosition} L ${totalWidth - 10} ${xAxisYPosition + 6}`}
              strokeWidth="2"
            />
          </g>
        </SVGWithDimensions>
      </div>
    </div>
  );
}
