import { FC } from 'react';

import { ITEM_WITH_OPTIONS } from '../../../constants';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { useShouldDisplayCosts } from '../../../utilities/permissions/useShouldDisplayCosts';
import CommentsHistoryPrint from '../../Comments/CommentsHistory/CommentsHistoryPrint';
import { isScheduleSettingsDisabled } from '../../ProjectProperties/ProjectScheduleImpact/ProjectScheduleImpactSettings';
import { PrintPageHeaderWrapper } from '../PrintSharedComponents/PrintPageHeaderWrapper';
import '../PrintStyles/print.scss';

import { PrintItemDetailsBody } from './PrintItemDetailsBody';
import PrintItemDetailsEstimate from './PrintItemDetailsEstimate';
import PrintItemDetailsScheduleImpact from './PrintItemDetailsScheduleImpact';
import { PrintItemDetailsSubheader } from './PrintItemDetailsSubheader';
import { useTriggerOnHeaderHisoricalComplete } from './PrintItemDetailsUtils';
import PrintItemDetailsIntegrations from './PrintItemIntegrations';

type PrintItemDetailsProps = {
  estimate?: Estimate;
  itemLike: ItemDataQueryItem;
  permissions: ReturnType<typeof usePermissions>;
  projectId: UUID;
  triggerOnComplete?: () => void;
  withHistory?: boolean;
};

export const PrintItemDetails: FC<PrintItemDetailsProps> = ({
  estimate,
  itemLike,
  permissions,
  projectId,
  triggerOnComplete,
  withHistory = false,
}) => {
  const isScheduleImpactEnabled = !isScheduleSettingsDisabled();
  const { shouldDisplayCosts } = useShouldDisplayCosts();

  const { setHeaderComplete, setHistoryComplete, setIntegrationsComplete } =
    useTriggerOnHeaderHisoricalComplete(triggerOnComplete, withHistory);

  let milestoneName = '';
  if (itemLike.milestone) {
    milestoneName = itemLike.milestone.name;
  }

  return (
    <div className="print-root">
      <PrintPageHeaderWrapper
        milestoneName={milestoneName}
        projectId={projectId}
        reportName="Item Details"
        triggerOnComplete={() => setHeaderComplete(true)}
      />
      <PrintItemDetailsSubheader item={itemLike} shouldDisplayCosts={shouldDisplayCosts} />
      <PrintItemDetailsBody itemLike={itemLike} permissions={permissions} />
      {estimate && (
        <PrintItemDetailsEstimate estimate={estimate} item={itemLike} permissions={permissions} />
      )}
      {isScheduleImpactEnabled && itemLike.itemType !== ITEM_WITH_OPTIONS && (
        <PrintItemDetailsScheduleImpact item={itemLike} permissions={permissions} />
      )}
      <PrintItemDetailsIntegrations
        item={itemLike}
        permissions={permissions}
        triggerOnComplete={() => setIntegrationsComplete(true)}
      />
      {withHistory && (
        <CommentsHistoryPrint
          itemId={itemLike.id}
          triggerOnComplete={() => setHistoryComplete(true)}
        />
      )}
    </div>
  );
};
