import { useState } from 'react';

import { getTodayUtcNoon } from '../../../../utilities/dates';
import { InsightsProject } from '../../hooks/useInsightsProjectsQuery';
import { CHART_HEIGHT, PADDING, TOTAL_HEIGHT } from '../utils';

import InsightsCostTrendline from './InsightsCostTrendline';

export default function ProjectCost(props: {
  project: InsightsProject;
  startStr: string;
  endStr: string;
  today?: Date;
}) {
  const today = props.today ?? getTodayUtcNoon();
  const [hoverDate, setHoverDate] = useState<Date | undefined>(undefined);

  const costData = props.project?.costTimeseries || [];

  const totalRange = [props.startStr, props.endStr] as [string, string];

  const chart = (
    <InsightsCostTrendline
      costData={costData}
      height={CHART_HEIGHT}
      hoverDate={hoverDate}
      setHoverDate={setHoverDate}
      today={today}
      totalRange={totalRange}
    />
  );

  return (
    <div
      key={props.project.id}
      className={`h-[${TOTAL_HEIGHT}px] min-w-full bg-background-primary`}
      style={{ paddingTop: PADDING, paddingBottom: PADDING }}
    >
      {chart}
    </div>
  );
}
