import { useCallback, useMemo, useState } from 'react';

import { searchEventTypes } from '../../../../../analytics/analyticsEventProperties';
import {
  ForecastingProjectsFilters,
  ForecastingProjectsSortBy,
  ForecastingProjectsSortKey,
  SearchResultType,
  SortDirection,
} from '../../../../../generated/graphql';
import { getSortManager } from '../../../../ForecastingRoute/ForecastingExplore/projects/hooks/useForecastingProjectsQueryParams';
import { useSavedQuery } from '../../../../ForecastingRoute/hooks/useSavedQuery';
import useProjectsCountsQuery from '../../../../ProjectsList/hooks/useProjectsCountsQuery';
import useCoreSearchPermissions from '../../hooks/useCoreSearchPermissions';
import useSendSearchAnalytics from '../../hooks/useSendSearchAnalytics';
import { SearchToggleValue } from '../../SearchToggle';

type SearchProjectsVariables = {
  filters: ForecastingProjectsFilters;
  search: string;
  sortBy: ForecastingProjectsSortBy;
  toggleValue: SearchToggleValue;
};

const LOCAL_STORAGE_KEY = 'SEARCH_PROJECTS_QUERY';
const ALL_PROJECTS_LABEL = 'All Projects';
const MY_PROJECTS_LABEL = 'My Projects';

export const DEFAULT_QUERY: SearchProjectsVariables = {
  filters: {
    companies: [],
    estimateCostRange: {},
    locations: [],
    milestoneDateRange: {},
    milestoneDesignPhases: [],
    projectTypes: [],
    statuses: [],
    types: [],
    programIDs: [],
  },
  search: '',
  sortBy: {
    sortDirection: SortDirection.SORT_DESCENDING,
    sortKey: ForecastingProjectsSortKey.UPDATED_AT,
  },
  toggleValue: SearchToggleValue.ALL,
};

export default () => {
  const { hasAllProjectsAccess } = useCoreSearchPermissions();
  const sendSearchAnalytics = useSendSearchAnalytics();

  const [savedQuery, setSavedQuery] = useSavedQuery<SearchProjectsVariables>(
    LOCAL_STORAGE_KEY,
    DEFAULT_QUERY
  );

  const { data: projectsCountData } = useProjectsCountsQuery();
  const projectsCounts = projectsCountData?.projectsCounts ?? {
    allProjectsCount: 0,
    myProjectsCount: 0,
  };

  const [filters, setFilters] = useState<ForecastingProjectsFilters>(savedQuery.filters);
  const [toggleValue, setToggleValue] = useState(
    hasAllProjectsAccess ? savedQuery.toggleValue : SearchToggleValue.MY
  );
  const [search, setSearch] = useState(savedQuery.search);
  const [sortBy, setSortBy] = useState<ForecastingProjectsSortBy>(savedQuery.sortBy);
  const [unitID, setUnitID] = useState<UUID>();
  const analyticEventTypeName = 'Projects';

  const onChangeFilters = useCallback(
    (
      filterName: keyof ForecastingProjectsFilters,
      filterValue: ForecastingProjectsFilters[keyof ForecastingProjectsFilters],
      isSendAnalytics = true
    ) => {
      setFilters((prevState) => ({
        ...prevState,
        [filterName]: filterValue,
      }));
      setSavedQuery({
        filters: { [filterName]: filterValue },
      });
      if (isSendAnalytics) {
        sendSearchAnalytics(searchEventTypes.SEARCH_FILTERS, {
          filterSelected: filterValue,
          filterType: filterName,
          type: analyticEventTypeName,
          filterSection: 'projectsFilter',
        });
      }
    },
    [sendSearchAnalytics, setSavedQuery]
  );
  const onResetFilters = useCallback(() => {
    setFilters(DEFAULT_QUERY.filters);
    setSavedQuery({
      filters: DEFAULT_QUERY.filters,
    });
  }, [setSavedQuery]);

  const onChangeSearch = useCallback(
    (search: string) => {
      setSearch(search);
      setSavedQuery({ search });
      sendSearchAnalytics(searchEventTypes.SEARCH_TEXT_ENTERED, {
        search,
        type: analyticEventTypeName,
        location: 'Home',
      });
    },
    [sendSearchAnalytics, setSavedQuery]
  );
  const onChangeSortBy = useCallback(
    (sortBy: ForecastingProjectsSortBy) => {
      setSortBy(sortBy);
      setSavedQuery({ sortBy });
      sendSearchAnalytics(searchEventTypes.SEARCH_SORT, {
        sortDirection: sortBy.sortDirection,
        sortKey: sortBy.sortKey,
        type: analyticEventTypeName,
      });
    },
    [sendSearchAnalytics, setSavedQuery]
  );
  const onChangeToggleValue = useCallback(
    (toggleValue: SearchToggleValue) => {
      setToggleValue(toggleValue);
      setSavedQuery({ toggleValue });
      sendSearchAnalytics(searchEventTypes.SEARCH_TOGGLE, {
        toggleValue,
        type: analyticEventTypeName,
      });
    },
    [sendSearchAnalytics, setSavedQuery]
  );

  const sortManager = useMemo(
    () => getSortManager(sortBy, onChangeSortBy),
    [sortBy, onChangeSortBy]
  );
  const toggleCounts = useMemo(
    () => ({
      all: projectsCounts.allProjectsCount,
      my: projectsCounts.myProjectsCount,
    }),
    [projectsCounts.allProjectsCount, projectsCounts.myProjectsCount]
  );
  const toggleParams = useMemo(() => {
    if (!hasAllProjectsAccess) return undefined;
    return {
      counts: toggleCounts,
      labels: { all: ALL_PROJECTS_LABEL, my: MY_PROJECTS_LABEL },
      onChange: onChangeToggleValue,
      value: toggleValue,
    };
  }, [hasAllProjectsAccess, onChangeToggleValue, toggleCounts, toggleValue]);

  return useMemo(
    () => ({
      onChangeFilters,
      onChangeSearch,
      onChangeUnitID: setUnitID,
      onResetFilters,
      sortManager,
      toggleCounts,
      toggleParams,
      variables: {
        filters,
        searchResultType:
          toggleValue === SearchToggleValue.ALL ? SearchResultType.ALL : SearchResultType.MY,
        search,
        sortBy,
        unitID,
        pagination: {
          offset: 0,
          limit: 25,
        },
      },
    }),
    [
      filters,
      onChangeFilters,
      onChangeSearch,
      onResetFilters,
      search,
      sortBy,
      sortManager,
      toggleCounts,
      toggleParams,
      toggleValue,
      unitID,
    ]
  );
};
