import { useMutation } from '@apollo/client';

import { createProjectAnalytics } from '../analytics/analyticsEventProperties';
import { refetchChangeProjectMetadata } from '../api/refetchSets';
import {
  CreateProjectMutation,
  CreateProjectMutationVariables,
  UpdateProjectMutation,
  UpdateProjectMutationVariables,
} from '../generated/graphql';

import { createProjectMutation, updateProjectMutation } from './queries';
import useSendAnalytics from './useSendAnalytics';

const useUpdateProject = () => {
  const [updateProjectFunc] = useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(
    updateProjectMutation
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const submitFunc = (project: ProjectInput, onSuccess?: any, onFailure?: any) =>
    updateProjectFunc({
      variables: {
        ...project,
        projectID: project.id,
        activeMilestoneID: project.activeMilestoneID ? project.activeMilestoneID : null,
      },
      refetchQueries: refetchChangeProjectMetadata(project.id),
    })
      .then((result) => {
        const updateProject = result?.data?.updateProject;
        if (onSuccess) onSuccess(updateProject);
      })
      .catch((e) => {
        if (onFailure) onFailure(e);
      });

  return submitFunc;
};

export const useCreateProject = () => {
  const [createProjectFunc] = useMutation<CreateProjectMutation, CreateProjectMutationVariables>(
    createProjectMutation
  );

  const sendAnalytics = useSendAnalytics();

  const submitFunc = (
    {
      lat,
      location,
      lon,
      milestone,
      milestoneDate,
      milestoneDesignPhaseID,
      name,
      code,
      typeID,
      statusID,
      currency,
      templateID,
      projectDeliveryTypeID,
      locationDetails,
      orgNodeIDs,
    }: CreateProjectMutationVariables,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    onSuccess?: (result: any) => void,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    onFailure?: (result: any) => void
  ) =>
    createProjectFunc({
      variables: {
        lat,
        location,
        lon,
        milestone,
        milestoneDate,
        milestoneDesignPhaseID,
        name,
        code,
        typeID,
        statusID,
        currency,
        templateID,
        projectDeliveryTypeID,
        locationDetails,
        orgNodeIDs,
      },
    })
      .then((result) => {
        const createProject = result?.data?.createProject;
        if (createProject) {
          const { id } = createProject;
          onSuccess?.(createProject);
          sendAnalytics(
            createProjectAnalytics(
              name || '',
              id,
              currency || 'USD',
              milestoneDesignPhaseID,
              projectDeliveryTypeID || ''
            )
          );
        }
      })
      // navigate to new project
      .catch((e) => {
        onFailure?.(e);
      });

  return submitFunc;
};

export default useUpdateProject;
