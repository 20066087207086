import { FC } from 'react';
import { isUUID } from 'validator';

import { CircularProgress } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';

import { ImageDimension } from '../../../generated/graphql';
import theme from '../../../theme/komodo-mui-theme';
import useThumbnailQuery from '../../assets/hooks/useThumbnailQuery';

export const getDimensionInt = (dimension: ImageDimension) =>
  parseInt(dimension.toString().slice(1), 10);

export const getThumbnailStyle = (size: number, padding = 0, disabled = false) => ({
  width: size,
  height: size,
  padding,
  backgroundColor: disabled ? theme.palette.backgroundGrey : 'unset',
});

type ThumbnailProps = {
  dimension: ImageDimension;
  disabled?: boolean;
  onLoad?: () => void;
  rounded?: boolean;
  padding?: number;
  size?: number;
  thumbnail: string | null | undefined;
  showError?: boolean;
};

export const getThumbnailURL = (
  isThumbnailIdValid: boolean,
  thumbnail: string | null | undefined,
  assetThumbnailURL: string | null | undefined
) => {
  // Case 1: we get the thumbnailURL from the ThumbnailQuery data
  if (assetThumbnailURL) return assetThumbnailURL;
  // Case 2: our provided thumbnail is a static URL
  if (!isThumbnailIdValid && thumbnail) return thumbnail; // thumbnail is a URL
  return '';
};

const Thumbnail: FC<ThumbnailProps> = ({
  dimension,
  disabled,
  onLoad,
  padding = 8,
  rounded,
  size,
  thumbnail,
  showError = false,
}) => {
  const { data, loading } = useThumbnailQuery(thumbnail, dimension);

  // Styling
  const displaySize = (size || getDimensionInt(dimension)) + 2 * padding;
  const style = getThumbnailStyle(displaySize, padding, disabled);
  const thumbnailClassName = rounded ? 'rounded-[6px] flex fit-cover m-0' : 'flex fit-cover m-0';

  // Logic
  const isThumbnailIdValid = thumbnail ? isUUID(thumbnail) : false;
  const thumbnailURL = getThumbnailURL(isThumbnailIdValid, thumbnail, data?.asset?.thumbnailURL);

  if (thumbnailURL)
    return (
      <div
        style={{
          width: displaySize,
          height: displaySize,
          backgroundColor: disabled ? theme.palette.backgroundGrey : 'unset',
          position: 'relative',
        }}
      >
        <img
          alt="thumb"
          className={thumbnailClassName}
          onLoad={onLoad}
          src={thumbnailURL}
          style={style}
        />
        {showError && (
          <ErrorOutline
            color="error"
            style={{
              position: 'absolute',
              top: 5,
              left: 5,
              backgroundColor: 'white',
              borderRadius: '50%',
            }}
          />
        )}
      </div>
    );

  // this ensures we call onLoad when image can't be rendered
  if (onLoad && !isThumbnailIdValid) onLoad();

  return (
    <div className={thumbnailClassName} style={style}>
      {loading && <CircularProgress size={displaySize} />}
    </div>
  );
};

export default Thumbnail;
