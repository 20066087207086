import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import {
  INSIGHTS_DEFAULT_MAX_ITEMS_BAR,
  INSIGHTS_DEFAULT_TIME,
  insightsMaxItemsBar,
  insightsTimeDomain,
} from '../../../api/apollo/reactiveVars';
import { ForecastingProjectsSortKey } from '../../../generated/graphql';
import { RouteKeys } from '../../../routes/paths';
import { getTodayUtcNoon } from '../../../utilities/dates';
import { generateSharedPath } from '../../../utilities/routes/links';
import { formatNumber } from '../../../utilities/string';
import ChartsPieGraph from '../../Charts/ChartsD3/ChartsPieGraph/ChartsPieGraph';
import { SortManager, Table, Tooltip } from '../../scales';
import ZoomTimeline from '../Charts/ZoomTimeline';
import { InsightsProject } from '../hooks/useInsightsProjectsQuery';
import ProjectItems from '../ProjectBabyCharts/ProjectItems';
import PendingPieToolTip from '../ToolTips/PendingPieToolTip';
import ProjectTableTooltip from '../ToolTips/ProjectTableTooltip';
import { InsightsTabId } from '../types';

import ProjectTableEntry from './ProjectTableEntry';
import StickyBottomRow from './StickyBottomRow';
import { getProjectsRange } from './utils';

type Props = {
  counts: { current: number; total: number };
  loading: boolean;
  onFetchMore: () => void;
  projects: InsightsProject[];
  sortManager: SortManager;
};

const ProjectsItems: FC<Props> = (props) => {
  useEffectOnce(() => () => {
    insightsTimeDomain(INSIGHTS_DEFAULT_TIME);
    insightsMaxItemsBar(INSIGHTS_DEFAULT_MAX_ITEMS_BAR);
  });
  const navigate = useNavigate();
  const [startDate, endDate] = getProjectsRange(getTodayUtcNoon());
  const [startStr, setStartStr] = useState(startDate.toISOString());
  const [endStr, setEndStr] = useState(endDate.toISOString());
  const setStartEnd = ([start, end]: string[]) => {
    setStartStr(start);
    setEndStr(end);
  };
  const onZoom = (domain: string[]) => {
    setStartEnd(domain);
  };

  const stickyFooterEntry = StickyBottomRow({
    columnsCount: 5,
    component: <ZoomTimeline onZoom={onZoom} projects={props.projects} zoom={[startStr, endStr]} />,
  });

  return (
    <>
      <Table
        columnWidths={[
          'minmax(250px, 3fr)',
          'minmax(125px, 0.5fr)',
          'minmax(125px, 0.5fr)',
          'minmax(125px, 0.5fr)',
          'minmax(600px, 6fr)',
        ]}
        entries={props.projects.map((project) => {
          const key = project.id;
          const to = {
            pathname: generateSharedPath(RouteKeys.INSIGHTS_PROJECT, { projectId: project.id }),
            hash: `#${InsightsTabId.Items}`,
          };
          const totalPendingItems =
            project.pendingItemsDueInFuture +
            project.pendingItemsMissingDueDates +
            project.pendingItemsPastDue;
          const pieChartData = [
            {
              name: 'Due in future',
              share: project.pendingItemsDueInFuture / totalPendingItems,
            },
            {
              name: 'Past Due',
              share: project.pendingItemsPastDue / totalPendingItems,
            },
            {
              name: 'Missing Due Dates',
              share: project.pendingItemsMissingDueDates / totalPendingItems,
            },
          ];
          return [
            {
              key,
              component: (
                <Tooltip
                  content={
                    <div>
                      <ProjectTableTooltip project={project} tabType={InsightsTabId.Items} />
                    </div>
                  }
                >
                  <div>
                    <ProjectTableEntry hasAlert={Math.random() < 0.5} project={project} to={to} />
                  </div>
                </Tooltip>
              ),
            },
            {
              key,
              centered: true,
              component: (
                <Tooltip
                  content={
                    <div>
                      <PendingPieToolTip
                        pendingItemsDueInFuture={project.pendingItemsDueInFuture}
                        pendingItemsMissingDueDates={project.pendingItemsMissingDueDates}
                        pendingItemsPastDue={project.pendingItemsPastDue}
                        totalPendingItems={totalPendingItems}
                      />
                    </div>
                  }
                >
                  <div>
                    <ChartsPieGraph
                      centerLabel={{
                        label: '',
                        sublabel: String(totalPendingItems),
                      }}
                      chartSize={{
                        diameter: 60,
                        insideDiameter: 35,
                      }}
                      colors={[
                        'var(--colors-item-status-pending)',
                        'var(--colors-entities-item-pastdue)',
                        'var(--colors-item-status-pending-tint)',
                        'var(--colors-item-status-not-applicable)',
                      ]}
                      data={pieChartData}
                    />
                  </div>
                </Tooltip>
              ),
            },
            {
              key,
              centered: true,
              component: <div>{project.pastDueItems}</div>,
            },
            {
              key,
              centered: true,
              component: <div>{project.dueNext14DaysItems}</div>,
            },
            {
              key,
              component: (
                <ProjectItems
                  endStr={endStr}
                  onClick={() => navigate(to)}
                  onZoom={onZoom}
                  project={project}
                  startStr={startStr}
                />
              ),
            },
          ];
        })}
        headerContent={[
          {
            component: <div className="h-9" />,
            copy: `Projects (${formatNumber(props.counts.current)} of ${formatNumber(
              props.counts.total
            )})`,
            key: 'projects',
            headerSortKey: ForecastingProjectsSortKey.NAME,
          },
          {
            copy: 'Pending',
            key: 'Pending',
          },
          {
            copy: 'Past due',
            key: 'Past',
          },
          { copy: 'Due next 14d', key: 'Due' },
          { copy: '', key: 'timeline' },
        ]}
        loading={props.loading}
        onNeedMoreData={props.onFetchMore}
        sortManager={props.sortManager}
        stickyFooterEntry={stickyFooterEntry}
      />
    </>
  );
};

export default ProjectsItems;
