import { useMemo } from 'react';

import { NULL_ID } from '../../../../constants';
import {
  ForecastingProjectsFilters,
  ProjectType,
  SearchResultType,
} from '../../../../generated/graphql';
import { useCompanyUserQuery } from '../../../../hooks/useCompanyUserQuery';
import { getSubtree, makeTree } from '../../../../utilities/utilities';
import useOrganizationsQuery from '../../../CompanyTab/CompanyTabOrganizations/hooks/useOrganizationsQuery';
import { FilterOptions } from '../../../frame/AdvancedFiltersSidebar/ProjectsFilters';
import useSearchProjectsFilterOptionsQuery from '../../../ProjectsList/hooks/useSearchProjectsFilterOptionsQuery';

export const useFilterOptions = (
  includeCompanyProjects: boolean,
  filters: ForecastingProjectsFilters
): FilterOptions => {
  const { data: filterOptionsData } = useSearchProjectsFilterOptionsQuery(
    includeCompanyProjects ? SearchResultType.ALL : SearchResultType.MY
  );

  const { data: companyUserData } = useCompanyUserQuery();
  const companyID = companyUserData?.companyUser?.company?.id;
  const organizationsQueryResult = useOrganizationsQuery(companyID);

  return useMemo(() => {
    const options = filterOptionsData?.searchProjectsFilterOptions;
    if (!options)
      return {
        companies: [],
        locations: [],
        milestoneDesignPhases: [],
        organizationNodes: [],
        projectLeads: [],
        projectTypes: [],
        statuses: [],
        types: [],
      };

    const orgs = organizationsQueryResult.data?.organizations ?? [];
    const orgTrees = orgs.flatMap((org) =>
      makeTree(org.nodes.map((node) => ({ ...node, label: node.name })))
    );
    const subtree = getSubtree(
      orgTrees,
      options.organizationNodes.map((node) => node.id)
    );

    const filterOptions = {
      companies: getFilterOptions(filters.companies, options.companies),
      locations: getFilterOptions(filters.locations, options.locations),
      milestoneDesignPhases: getFilterOptions(
        filters.milestoneDesignPhases,
        options.milestoneDesignPhases
      ),
      organizationNodes: subtree,
      projectLeads: [
        { id: NULL_ID, label: 'Unassigned' },
        ...options.projectLeads.map(({ id, name }) => ({ id, label: name })),
      ],
      projectTypes: getProjectTypesFilterOptions(options.projectTypes),
      statuses: getFilterOptions(filters.statuses, options.statuses),
      types: getProjectTypesFilterOptions(options.projectTypes),
    };
    return filterOptions;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [
    filterOptionsData?.searchProjectsFilterOptions,
    organizationsQueryResult,
    filters.companies,
    filters.locations,
    filters.milestoneDesignPhases,
    filters.statuses,
    filters.types,
  ]);
};

function getProjectTypesFilterOptions(filterOptions: ProjectType[]) {
  // Create a list of new project type filter options
  return filterOptions.map((type) => {
    return {
      ...type,
      label: type.name,
    };
  });
}

function getFilterOptions(filterSelections: string[], filterOptions: string[]) {
  // Create a list of new filter options
  const filters = filterOptions.map((option) => {
    return { id: option, label: option || 'None' };
  });
  //  Run through selected filters and if there is a pre-selected filter then display it so user can unselect it.
  filterSelections.forEach((selection) => {
    if (!filterOptions.includes(selection)) {
      filters.splice(0, 0, { id: selection, label: selection });
    }
  });
  return filters;
}
