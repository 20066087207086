import { createRef, useEffect, useLayoutEffect, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

import { TimelineEvent } from '../../../../analytics/analyticsEventProperties';
import { TimelineGroups } from '../../../../api/gqlEnums';
import {
  Timeline,
  TimelineData,
  TimelineExpandedMap,
  TimelineItemData,
} from '../../../shared-widgets/TimelineChart/timeline/timeline.types';
import { ITEMS_HEIGHT } from '../../../shared-widgets/TimelineChart/timeline/utils';
import {
  getDueDateHeight,
  initDueDateTimeline,
} from '../../../shared-widgets/TimelineChart/TimelineChartUtils';
import XAxis from '../../Charts/XAxis';

type TimelineChartProps = {
  projectID: UUID;
  activities?: TimelineActivity[];
  data?: TimelineData[];
  items?: TimelineItemData[];
  isMiniChart?: boolean;
  expandedMap?: TimelineExpandedMap;
  isPrint?: boolean;
  height?: number;
  maxItemsBar: number;
  onAnalytics?: (event: TimelineEvent) => void;
  onExpand?: (id: UUID) => void;
  onZoom?: (domain: string[]) => void;
  totalRange: [string, string];
  today?: string;
  withoutDueDateCount?: number;
  zoomIn?: TimelineData;
  zoomLineCompressed?: boolean;
};

const TimelineDueDateChart = (props: TimelineChartProps) => {
  const {
    projectID,
    activities = [],
    data: dataOuter = [],
    height,
    items = [],
    isMiniChart,
    expandedMap = {},
    isPrint,
    maxItemsBar,
    onAnalytics = () => {},
    onExpand: onExpandOuter = () => {},
    onZoom: onZoomOuter = () => {},
    today,
    totalRange,
    withoutDueDateCount = 0,
    zoomIn,
    zoomLineCompressed = true,
  } = props;
  const settings = {
    ...{ collapse: [TimelineGroups.TIMELINE], expand: [TimelineGroups.ITEMS] },
    projectID,
  };
  const endDate = totalRange[1];
  const startDate = totalRange[0];

  const containerRef = createRef<HTMLDivElement>();
  const [width, setWidth] = useState<number | undefined>(0);

  useLayoutEffect(() => {
    const handleResize = () => {
      const newWidth = containerRef.current?.offsetWidth;
      if (!newWidth) return;
      if (width === newWidth) return;
      setWidth(newWidth);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [containerRef, width]);

  const timelineRef = createRef<HTMLDivElement>();

  const [data, setData] = useState<TimelineData[]>(dataOuter);

  const [timelineChart, setTimelineChart] = useState<Timeline<TimelineData>>();

  useDeepCompareEffect(() => {
    setData(dataOuter);
  }, [dataOuter]);

  const onExpand = ({ id }: TimelineData) => onExpandOuter(id);

  const onZoom = (domain: Date[]) => {
    const newMin = domain[0].toISOString();
    const newMax = domain[1].toISOString();
    onZoomOuter([newMin, newMax]);
  };
  useEffect(() => {
    if (!timelineRef || !timelineRef.current) return;
    if (!width) return;

    const chartProps = {
      width,
      withoutDueDateCount,
      height: getDueDateHeight(height ?? ITEMS_HEIGHT),
      data,
      items,
      today,
      expandedMap,
      zoomLineCompressed,
      onExpand,
      onZoom,
      onAnalytics,
      isPrint,
      settings,
      range: totalRange,
      maxItemsBar,
    };
    setTimelineChart(initDueDateTimeline(timelineRef.current, chartProps, activities));
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [timelineRef?.current, width, data, startDate, endDate, maxItemsBar]);

  useEffect(() => {
    if (zoomIn) timelineChart?.onZoomIn(zoomIn);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [zoomIn]);

  // useEffectOnce(() => () => unload());

  if (startDate === '0' || endDate === '0') return null;

  return (
    <div ref={containerRef} className="relative block w-full">
      <div ref={timelineRef} className="overflow-hidden" id="timeline-chart" />
      {!isMiniChart && <XAxis totalRange={totalRange} />}
    </div>
  );
};

export default TimelineDueDateChart;
