import { useContext } from 'react';

import {
  PermissionGroup,
  PermissionLevel,
  PermissionResource,
} from '../../../../generated/graphql';
import { ProjectTermStore } from '../../../ProjectDisplaySettings/TerminologyProvider';

import { getCostPermissionDisplayDataSorted } from './permissions';
import PermissionsTableEdit from './PermissionsTableEdit';
import PermissionsTableView from './PermissionsTableView';

type Props = {
  canViewCosts: boolean;
  editable: boolean;
  group: PermissionGroup;
  hasTrade: boolean;
  onChange: (id: UUID, level: PermissionLevel, permissionResource?: string) => void;
};

const CostPermissionsTable = (props: Props) => {
  const t = useContext(ProjectTermStore);
  const computePermissionResource = (resource: PermissionResource, level: PermissionLevel) => {
    const p = props.group.permissions.find((permission) => permission.resource === resource);
    if (p) {
      return p.level === level;
    }
    return false;
  };

  const noInItemView = computePermissionResource(
    PermissionResource.ITEM_IN_TRADE_LINES,
    PermissionLevel.NONE
  );
  const noMilestoneView = computePermissionResource(
    PermissionResource.MILESTONE_LINES,
    PermissionLevel.NONE
  );
  const noOutItemView = computePermissionResource(
    PermissionResource.ITEM_OUT_OF_TRADE_LINES,
    PermissionLevel.NONE
  );
  const shouldDisableMarkups =
    noInItemView && noMilestoneView && (noOutItemView || !props.hasTrade);

  const costDisplayData = getCostPermissionDisplayDataSorted(t, props.hasTrade);

  return props.editable ? (
    <PermissionsTableEdit
      canViewCosts={props.canViewCosts}
      group={props.group}
      onChange={props.onChange}
      permissionsDisplayData={costDisplayData}
      shouldDisableMarkups={shouldDisableMarkups}
    />
  ) : (
    <PermissionsTableView
      canViewCosts={props.canViewCosts}
      group={props.group}
      permissionsDisplayData={costDisplayData}
      shouldDisableMarkups={shouldDisableMarkups}
    />
  );
};

export default CostPermissionsTable;
