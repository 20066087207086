import { Event } from '@material-ui/icons';

import { DesignPhaseType } from '../../../generated/graphql';
import MilestoneDesignPhaseSelect from '../../dragon-scales/MilestoneDesignPhaseSelect/MilestoneDesignPhaseSelect';
import { Callout, DateInput, Switch, TextInput } from '../../scales';

export type ActivityInputs = {
  milestone: {
    included: boolean;
    name: string;
    designPhaseID: UUID;
    date: string;
  };
  mobilization: {
    included: boolean;
    name: string;
    date: string;
  };
  turnover: {
    included: boolean;
    name: string;
    date: string;
  };
};

export type KeyType =
  | keyof ActivityInputs['milestone']
  | keyof ActivityInputs['mobilization']
  | keyof ActivityInputs['turnover'];

type SetActivityInput = (
  activityType: keyof ActivityInputs,
  key: KeyType,
  value: string | boolean
) => void;

type ActivityStatus = {
  isLoading: boolean;
  isSuggested: boolean;
};

type Statuses = {
  milestone: ActivityStatus;
  mobilization: ActivityStatus;
  turnover: ActivityStatus;
};

type Props = {
  activityInputs: ActivityInputs;
  designPhaseTypes: DesignPhaseType[];
  statuses: Statuses;
  setActivityInput: SetActivityInput;
};

export function KeyDatesNudgeContent(props: Props) {
  return (
    <div className="flex flex-col gap-4">
      <Callout fullWidth>
        <div className="flex items-center gap-2 type-body3">
          <Event />
          Adding a future milestone like GMP, target Mobilization and Turnover dates will help your
          team track progress toward delivery.
        </div>
      </Callout>
      {props.statuses.milestone.isSuggested && (
        <ActivityInput
          {...props.activityInputs.milestone}
          designPhaseTypes={props.designPhaseTypes}
          setActivityInput={props.setActivityInput}
          type="milestone"
        />
      )}
      {props.statuses.mobilization.isSuggested && (
        <ActivityInput
          {...props.activityInputs.mobilization}
          designPhaseTypes={props.designPhaseTypes}
          setActivityInput={props.setActivityInput}
          type="mobilization"
        />
      )}
      {props.statuses.turnover.isSuggested && (
        <ActivityInput
          {...props.activityInputs.turnover}
          designPhaseTypes={props.designPhaseTypes}
          setActivityInput={props.setActivityInput}
          type="turnover"
        />
      )}
    </div>
  );
}

type ActivityInputProps = {
  type: keyof ActivityInputs;
  included: boolean;
  name: string;
  date: string;
  designPhaseID?: string;
  designPhaseTypes: DesignPhaseType[];
  setActivityInput: SetActivityInput;
};

const getInputsLabel = (type: keyof ActivityInputs) => {
  switch (type) {
    case 'mobilization':
      return 'Add an Event for your target Mobilization date';
    case 'turnover':
      return 'Add an Event for your target Turnover date';
    default:
      return 'Add a Milestone for your target GMP date';
  }
};

function ActivityInput(props: ActivityInputProps) {
  const isMilestone = props.type === 'milestone';
  return (
    <div className="flex flex-col gap-1">
      <div>
        <Switch
          checked={props.included}
          label={getInputsLabel(props.type)}
          onChange={(value) => props.setActivityInput(props.type, 'included', value)}
        />
      </div>
      {isMilestone && (
        <TextInput
          disabled={!props.included}
          label="Milestone name"
          onChange={(value) => props.setActivityInput(props.type, 'name', value)}
          value={props.name}
        />
      )}
      <div className="flex gap-2">
        <div className="flex-basis-0 w-0 flex-grow">
          {isMilestone ? (
            <MilestoneDesignPhaseSelect
              entries={props.designPhaseTypes}
              isDisabled={!props.included}
              label="Design Phase"
              onChange={(designPhase) =>
                props.setActivityInput(props.type, 'designPhaseID', designPhase.id)
              }
              value={props.designPhaseID}
            />
          ) : (
            <TextInput
              disabled={!props.included}
              label="Event name"
              onChange={(value) => props.setActivityInput(props.type, 'name', value)}
              value={props.name}
            />
          )}
        </div>
        <div className="flex-basis-0 w-0 flex-grow">
          <DateInput
            isClearable
            isDisabled={!props.included}
            label="Date"
            onChange={(value: string | null) =>
              props.setActivityInput(props.type, 'date', value || '')
            }
            value={props.date}
          />
        </div>
      </div>
    </div>
  );
}
