import { gql } from '../../../api/graphqlFragments';

export const timelineQuery = gql`
  query Timeline($input: TimelineInput!) {
    timeline(input: $input) {
      activities {
        id
        name
        type
        startDate
        endDate
        children
        itemCount
        milestone {
          id
          name
          description
          itemsCount
        }
      }
    }
  }
`;

export const timelineActivityOptionsQuery = gql`
  query TimelineActivityOptions($input: TimelineInput!) {
    timeline(input: $input) {
      activities {
        id
        name
        type
        itemCount
        milestone {
          id
          name
          description
          itemsCount
        }
      }
    }
  }
`;

export const createTimelineActivityMutation = gql`
  mutation createTimelineActivity($projectID: UUID!, $input: TimelineActivityInput!) {
    createTimelineActivity(projectID: $projectID, input: $input) {
      id
      type
      name
      startDate
      endDate
    }
  }
`;

export const updateTimelineActivityMutation = gql`
  mutation updateTimelineActivity(
    $projectID: UUID!
    $activityID: UUID!
    $input: TimelineActivityInput!
  ) {
    updateTimelineActivity(projectID: $projectID, activityID: $activityID, input: $input) {
      id
      type
      name
      startDate
      endDate
    }
  }
`;

export const deleteTimelineActivityMutation = gql`
  mutation deleteTimelineActivity($projectID: UUID!, $activityID: UUID!) {
    deleteTimelineActivity(projectID: $projectID, activityID: $activityID)
  }
`;

const itemsTimelineItemFields = gql`
  fragment itemsTimelineItemFields on ItemsListItem {
    dueDate
    id
    name
    status
    createdAt
  }
`;

export const itemsTimelineQuery = gql`
  query itemsTimeline(
    $milestoneID: UUID
    $activityID: UUID
    $projectID: UUID!
    $showOptions: Boolean!
    $costMode: CostMode!
    $loadItemCosts: Boolean
    $viewFilter: ViewFilter!
    $visibilityView: VisibilityView!
    $integrations: [IntegrationFilterType!]!
  ) {
    itemsList(
      parameters: {
        milestoneID: $milestoneID
        activityID: $activityID
        projectID: $projectID
        showOptions: $showOptions
        costMode: $costMode
        loadItemCosts: $loadItemCosts
        viewFilter: $viewFilter
        visibilityView: $visibilityView
        integrations: $integrations
      }
    ) {
      items {
        ...itemsTimelineItemFields
      }
    }
  }
  ${itemsTimelineItemFields}
`;
