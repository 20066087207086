import { useState } from 'react';

import { ToastType } from '../../../../api/gqlEnums';
import { SourceSystem, UserSourcesQuery } from '../../../../generated/graphql';
import { setToast } from '../../../../hooks/useToastParametersLocalQuery';
import { useProjectID } from '../../../../utilities/routes/params';
import { useRemoveUserSource } from '../../../DocumentMagic/hooks';
import { Button } from '../../../scales';

import { useGetSourceLinkedProjectQuery } from './hooks/useGetSourceLinkedProjectQuery';
import IntegrationsEditAccountModal from './IntegrationsEditAccountModal';
import IntegrationsEmptyState from './IntegrationsEmptyState';
import IntegrationsLinkAccountsModal from './IntegrationsLinkAccountsModal';
import IntegrationsSourceContent from './IntegrationsSourceContent';
import { ItemInfo, UserSource } from './IntegrationsUtils';

type Props = {
  canCreateChangeEvent: boolean;
  canDeleteItemIntegration: boolean;
  isPrint?: boolean;
  isSidebar?: boolean;
  itemInfo: ItemInfo;
  userSources: UserSourcesQuery['userSources'];
};

export default function IntegrationsDataWrapper(props: Props) {
  const projectID = useProjectID();
  const [linkAccountsDialogOpen, setLinkAccountsDialogOpen] = useState(false);
  const [currentUserSource, setCurrentUserSource] = useState<UserSource | null>();
  const [initialEditAccountSourceSystem, setInitialEditAccountSourceSystem] =
    useState<SourceSystem | null>();

  const { data: linkedProjectQueryData, refetch: refetchLinkedProjectQuery } =
    useGetSourceLinkedProjectQuery(
      projectID ?? '',
      currentUserSource?.sourceSystem ?? initialEditAccountSourceSystem,
      currentUserSource?.id,
      currentUserSource?.email ?? ''
    );
  const linkedProject = linkedProjectQueryData?.sourceLinkedProject ?? undefined;

  const [removeUserSource] = useRemoveUserSource();

  const loggedInAutodeskSource = props.userSources?.sources.find(
    (s) => s.sourceSystem === SourceSystem.AUTODESK
  );
  const loggedInProcoreSource = props.userSources?.sources.find(
    (s) => s.sourceSystem === SourceSystem.PROCORE
  );

  const initialEditAccount = props.userSources.sources.find(
    (s) => s.sourceSystem === initialEditAccountSourceSystem
  );

  const userSource = currentUserSource ?? initialEditAccount;

  const hasAnyLinkedAccount = !!loggedInProcoreSource || !!loggedInAutodeskSource;

  let manageAccountsHint = '';
  if (!!loggedInProcoreSource && !!loggedInAutodeskSource)
    manageAccountsHint = "You've linked 2 accounts.";
  if (!!loggedInProcoreSource && !loggedInAutodeskSource)
    manageAccountsHint = "You've linked 1 account. You can also link an Autodesk account.";
  if (!loggedInProcoreSource && !!loggedInAutodeskSource)
    manageAccountsHint = "You've linked 1 account. You can also link a Procore account.";

  const onSignOut = (userSource: UserSource) => {
    removeUserSource(userSource.id);
    setToast(
      {
        message: 'Successfully signed out of account',
      },
      ToastType.SERVER_ERROR
    );
  };

  const onEditAccount = setCurrentUserSource;

  return (
    <>
      {hasAnyLinkedAccount ? (
        <div
          className={`flex flex-col bg-background-primary p-4 ${
            props.isPrint ? 'gap-4' : 'gap-8 '
          }`}
        >
          {!props.isPrint && (
            <div className="flex items-center justify-between">
              <div>{manageAccountsHint}</div>
              <Button
                label="Manage accounts"
                onClick={() => setLinkAccountsDialogOpen(true)}
                type="secondary"
              />
            </div>
          )}
          {loggedInAutodeskSource && (
            <IntegrationsSourceContent
              canCreateChangeEvent={props.canCreateChangeEvent}
              canDeleteItemIntegration={props.canDeleteItemIntegration}
              isPrint={props.isPrint}
              itemInfo={props.itemInfo}
              onEditAccount={onEditAccount}
              userSource={loggedInAutodeskSource}
            />
          )}
          {loggedInProcoreSource && (
            <IntegrationsSourceContent
              canCreateChangeEvent={props.canCreateChangeEvent}
              canDeleteItemIntegration={props.canDeleteItemIntegration}
              isPrint={props.isPrint}
              itemInfo={props.itemInfo}
              onEditAccount={onEditAccount}
              userSource={loggedInProcoreSource}
            />
          )}
        </div>
      ) : (
        <IntegrationsEmptyState
          isPrint={props.isPrint}
          isSidebar={props.isSidebar}
          onClickAddAccount={() => setLinkAccountsDialogOpen(true)}
        />
      )}
      {userSource ? (
        <IntegrationsEditAccountModal
          linkedProject={linkedProject}
          onClose={() => {
            setCurrentUserSource(null);
            setInitialEditAccountSourceSystem(null);
          }}
          onSignOut={onSignOut}
          refetchLinkedProjectQuery={refetchLinkedProjectQuery}
          userSource={userSource}
        />
      ) : (
        <IntegrationsLinkAccountsModal
          isOpen={linkAccountsDialogOpen}
          onClose={() => {
            setLinkAccountsDialogOpen(false);
            setInitialEditAccountSourceSystem(null);
          }}
          onEditAccount={onEditAccount}
          onSignOut={onSignOut}
          setInitialEditAccountSourceSystem={setInitialEditAccountSourceSystem}
          userSources={props.userSources}
        />
      )}
    </>
  );
}
