import { useState } from 'react';

import { TextInput } from '../../scales';

type Props = {
  editable: boolean;
  isPrivate: boolean;
  itemID: string;
  itemType: string;
  label?: string;
  onChange: (id: string, number: string, itemType: string) => void;
  value?: string;
};

export default function ItemNumberTextInput(props: Props) {
  const [number, setNumber] = useState<string | undefined>(props.value);
  const disabled = !props.editable || props.isPrivate;

  return (
    <TextInput
      aria-label="item-number-field"
      data-cy="item-number-text-input"
      disabled={disabled}
      label={props.label}
      onBlur={() => {
        if (number) props.onChange(props.itemID, number, props.itemType);
      }}
      onChange={(e) => setNumber(e)}
      onKeyDown={(e: { key: string }) => {
        if (e.key === 'Enter' && number) props.onChange(props.itemID, number, props.itemType);
      }}
      startAdornment={<div className="cursor-default select-none text-type-muted">#</div>}
      value={number}
    />
  );
}
