import { FC } from 'react';

import { Tooltip } from '../../../scales';

import { CenterLabel, PieChartData, PieChartSize } from './ChartsPieGraphUtils';
import ChartsPieLabelTooltip from './ChartsPieLabelTooltip';

type Props = {
  centerLabel?: CenterLabel;
  chartSize: PieChartSize;
  colors: string[];
  data: PieChartData[];
  displayLegendTooltip?: boolean;
  isCurrency?: boolean;
  labelStyle?: string;
  title?: string;
};

const ChartsPieGraphLabels: FC<Props> = ({
  centerLabel,
  chartSize,
  colors,
  data,
  displayLegendTooltip,
  isCurrency,
  labelStyle,
  title,
}) => {
  const radius = chartSize.diameter / 2;

  // add a margin so the text doesn't reach the inside radius of the chart
  const margin = 0.2;
  const textSize = chartSize.insideDiameter ? chartSize.insideDiameter * (1 - margin) : radius / 2;
  const textLocation = radius - textSize / 2;

  return (
    <foreignObject height={textSize} width={textSize} x={textLocation} y={textLocation}>
      <Tooltip
        key={centerLabel?.label}
        content={
          <ChartsPieLabelTooltip
            colors={colors}
            isCurrency={isCurrency}
            pieData={data}
            title={title}
          />
        }
        isDisabled={!displayLegendTooltip}
        placement="bottom"
      >
        <div className="flex h-full flex-col">
          {centerLabel ? (
            <div
              className={`${
                centerLabel?.sublabel ? 'mt-auto' : 'm-auto'
              } overflow-hidden overflow-ellipsis whitespace-nowrap text-center ${
                labelStyle ?? 'type-heading3'
              }`}
            >
              {centerLabel.label}
            </div>
          ) : undefined}
          {centerLabel?.sublabel ? (
            <div className="mb-auto line-clamp-2 text-center type-label">
              {centerLabel.sublabel}
            </div>
          ) : undefined}
        </div>
      </Tooltip>
    </foreignObject>
  );
};

export default ChartsPieGraphLabels;
