import { FC, useState } from 'react';

import { INTEGRATIONS } from '../../../constants';
import useItemIntegrationObjectsCountQuery from '../../../hooks/useItemIntegrationObjectsCountQuery';
import { constantCountLabel } from '../../../utilities/string';
import { useUserSourcesQuery } from '../../DocumentMagic/hooks';

import IntegrationsDataWrapper from './Integrations/IntegrationsDataWrapper';
import IntegrationsHeader from './Integrations/IntegrationsHeader';
import { ItemInfo } from './Integrations/IntegrationsUtils';
import ItemDetailsCollapse from './ItemDetailsCollapse';

type IntegrationsCollapseProps = {
  canCreateChangeEvent: boolean;
  canDeleteItemIntegration: boolean;
  itemInfo: ItemInfo;
};

const IntegrationsCollapse: FC<IntegrationsCollapseProps> = ({
  canCreateChangeEvent,
  canDeleteItemIntegration,
  itemInfo,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const userSources = useUserSourcesQuery().data?.userSources;

  const countData = useItemIntegrationObjectsCountQuery({ itemID: itemInfo.id });
  const count = countData?.data?.itemIntegrationObjectsCount ?? 0;

  const headerContent = (
    <IntegrationsHeader name={constantCountLabel(INTEGRATIONS, count)} userSources={userSources} />
  );

  return (
    <ItemDetailsCollapse
      canExpand
      headerContent={headerContent}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
    >
      {userSources && (
        <IntegrationsDataWrapper
          canCreateChangeEvent={canCreateChangeEvent}
          canDeleteItemIntegration={canDeleteItemIntegration}
          itemInfo={itemInfo}
          userSources={userSources}
        />
      )}
    </ItemDetailsCollapse>
  );
};

export default IntegrationsCollapse;
