import { useParams } from 'react-router-dom';

import InsightsProject from './InsightsProject';

export default function InsightsProjectRoute() {
  const { projectId } = useParams();

  if (!projectId) {
    throw new Error('Failed to get projectId param');
  }

  // TODO: return InsightsProjectDetails
  return <InsightsProject project={{} as InsightsProjects} />;
}
